import React, { FC, useEffect, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  withStyles,
  useTheme,
} from '@material-ui/core'

import {
  FirstStep,
  FirstStepActive,
  SecondStep,
  SecondStepActive,
  ThirdStepDisabled,
  ForthStepDisabled,
  FifthStepDisabled,
  SixStepDisabled,
} from 'assets'
import { IRenderStepLabels } from './PropertyDetailsStepper.interface'
import { PropertyDescriptionCard } from '../PropertyDescriptionCard'
import { OlMap } from '../OlMap/OlMap'
import { useStyle } from './PropertyDetailsStepper.style'

export const PropertyDetailsStepper: FC<any> = ({ markerCoords, details }) => {
  const classes = useStyle()

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 122,
      zIndex: 0,
    },
    active: {
      '& $line': {
        backgroundColor: '#CA2D78',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      position: 'relative',
      zIndex: 0,
    },
  })(StepConnector)

  const [activeStep, setActiveStep] = React.useState(0)

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <PropertyDescriptionCard details={details} />
      case 1:
        return <OlMap coords={markerCoords} />
      case 2:
        return 'This is the bit I really care about!'
      default:
        return 'Unknown step'
    }
  }
  const steps = [
    {
      subLabel: 'تفاصيل',
      mainLabel: 'العقار',
      icon: FirstStep,
      activeIcon: FirstStepActive,
    },
    {
      subLabel: 'الموقع على',
      mainLabel: 'الخريطة',
      icon: SecondStep,
      activeIcon: SecondStepActive,
    },
    {
      subLabel: 'تفاصيل',
      mainLabel: 'المخطط',
      icon: ThirdStepDisabled,
      activeIcon: '',
    },
    {
      subLabel: 'جولة داخلية',
      mainLabel: '3D',
      icon: ForthStepDisabled,
      activeIcon: '',
    },
    {
      subLabel: 'متوسطات',
      mainLabel: 'الأسعار',
      icon: FifthStepDisabled,
      activeIcon: '',
    },
    {
      subLabel: 'معلومات',
      mainLabel: 'محلية',
      icon: SixStepDisabled,
      activeIcon: '',
    },
  ]

  const handleStepClick = (index: number) => {
    if (index > 1) {
      return
    }
    setActiveStep(index)
  }
  const renderIcon = (index: number | string) => {
    const { icon, activeIcon } = steps[index]

    return activeStep === index ? activeIcon : icon
  }
  const renderLabel = ({ index, mainLabel, subLabel }: IRenderStepLabels) => {
    const labelColor = index === activeStep ? 'secondary' : 'initial'
    return (
      <div>
        <Typography
          color={labelColor}
          className={
            index === activeStep ? classes.label : classes.disabledlabel
          }
        >
          {subLabel}{' '}
        </Typography>
        <Typography color={labelColor} align="center">
          {mainLabel}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        className={classes.stepperContainer}
        style={{ width: '100%' }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map(({ mainLabel, subLabel }, index) => (
          <Step key={index} onClick={() => handleStepClick(index)}>
            <StepLabel
              className={classes.iconContainer}
              StepIconComponent={renderIcon(index)}
            >
              {renderLabel({ index, mainLabel, subLabel })}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={classes.instructions}>{getStepContent(activeStep)}</div>
    </div>
  )
}
