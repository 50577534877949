import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20rem',
  },
  hideLabelOnMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  title: {
    fontSize: '2.2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '24px 0',
    [theme.breakpoints.down('xl')]: {
      padding: '15px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
    },
  },
  pos: {
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  imgWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },
}))
