import React from 'react'
import { Modal, Typography, Button } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from '@flint/locales'

import { useStyles } from './VerifyLaterStyles'

export interface ConfirmationModalProps {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: () => void
  title?: string
  subTitle?: string
}

export const VerifyLaterModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  title = 'هل انت متأكد من إجراء التحقق لاحقا ؟',
  subTitle = 'يمكنك إضافة إعلانات لكن لن يتم نشرها لحين التحقق من هوية المعلن',
}: ConfirmationModalProps): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="verify-later"
      aria-describedby="verify-later"
      disableAutoFocus
      disableEnforceFocus
    >
      <div className={classes.paper}>
        <div>
          <Typography variant="h1" className={classes.titlePosition}>
            {title}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" className={classes.titlePosition}>
            {subTitle}
          </Typography>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button onClick={handleClose} className={classes.laterButton}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              handleConfirm()
              handleClose()
            }}
            className={classes.confirmationButton}
          >
            {t('Confirm')}
          </Button>
        </div>
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </div>
    </Modal>
  )
}
