import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import { InputAdornment, useTheme, useMediaQuery } from '@material-ui/core'
import { WidgetProps, utils } from '@rjsf/core'
import TextField, {
  StandardTextFieldProps as TextFieldProps,
} from '@material-ui/core/TextField'
import { hasValue, numberOnly } from '@flint/utils'
import JSONPath from 'jsonpath'
import { useStyles } from './TextWidgetWithoutLabelStyles'

// @doc: https://github.com/dchester/jsonpath

const { getDisplayLabel } = utils

/**
 * ui:blueStyle: (boolean) styles the input with blue background and dashed borders.
 * ui:greenStyle: (boolean) styles the input with green background and normal borders.
 * ui:grayStyle: (boolean) styles the input with gray background and normal borders.
 * ui:calculation: (string) dynamically set the input value based on the provided string.
 * ui:hint: (string) show a helper text below the field.
 * ui:disabled: (boolean) disabled the field.
 *
 */

export type TextWidgetProps = WidgetProps & TextFieldProps

export const TextWidgetWithoutLabel = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  ...textFieldProps
}: TextWidgetProps) => {
  const classes = useStyles()
  const [calculatedValueTimeout, setCalculatedValueTimeout] = useState<any>()
  const { allFormData } = formContext
  const [_value, setValue] = useState(value)

  const _inputType =
    (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`

  const isNumberType = ['integer', 'number'].includes(_inputType)

  const inputType = isNumberType ? 'text' : _inputType

  const getFinalValue = (value: any) => {
    if (isNumberType) {
      return numberOnly(value)
    }

    return value
  }
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(getFinalValue(value === '' ? options.emptyValue : value))
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, getFinalValue(value))
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, getFinalValue(value))

  const displayLabel = getDisplayLabel(
    schema,
    uiSchema
    /* TODO: , rootSchema */
  )
  const isGreyStyle = !!uiSchema['ui:greyStyle']
  const isBlueStyle = !!uiSchema['ui:blueStyle']
  const isGreenStyle = !!uiSchema['ui:greenStyle']
  const isCalculated = !!uiSchema['ui:calculation']
  const isDisabled = disabled

  useEffect(() => {
    const setFieldValueBasedOnCalculatedStr = () => {
      const evalStr = uiSchema['ui:calculation']
      if (evalStr) {
        const macthes = evalStr.match(/\{.+?\}/g) || []
        const finalStr = macthes.reduce((str, key) => {
          const JSONPathValue =
            JSONPath.query(allFormData, key.replace(/\{|\}/g, ''))[0] || 0
          return str.replace(
            `${key}`,
            numberOnly(
              [Infinity, NaN].includes(JSONPathValue) ? 0 : JSONPathValue
            )
          )
        }, evalStr)

        // eslint-disable-next-line
        let finalValue = Number(eval(finalStr).toFixed(2))
        finalValue = [Infinity, NaN].includes(finalValue)
          ? 0
          : Number(finalValue.toFixed(2))

        onChange(finalValue)
      }
    }

    clearTimeout(calculatedValueTimeout)
    setCalculatedValueTimeout(setTimeout(setFieldValueBasedOnCalculatedStr, 50))
  }, [allFormData, uiSchema])

  // Format numbers like money 26302.52 >> 26,302.52
  useEffect(() => {
    if (isNumberType) {
      const __value = String(value)
      const formatter = new Intl.NumberFormat('en-US')

      const startsWithNegative = __value.startsWith('-')
      const endsWithDot = __value.endsWith('.')

      const containsDot = __value.slice(0, -1).includes('.')

      const valueToBeFormatted =
        containsDot && endsWithDot ? __value.slice(0, -1) : __value

      const _formated = formatter.format(valueToBeFormatted as any)

      const finalValue =
        !!value && _formated
          ? `${startsWithNegative ? '-' : ''}${
              _formated !== 'NaN' ? _formated : ''
            }${endsWithDot && !containsDot ? '.' : ''}`
          : ''

      setValue(finalValue)
    } else {
      setValue(value)
    }
  }, [value])

  return (
    <TextField
      id={id}
      className={clsx({
        [classes.isGreyRoot]: isGreyStyle,
        [classes.isBlueRoot]: isBlueStyle,
        [classes.isGreenRoot]: isGreenStyle,
      })}
      InputLabelProps={{
        shrink: hasValue(_value),
      }}
      InputProps={{
        className: clsx(classes.input, {
          [classes.isGreyInput]: isGreyStyle,
          [classes.isBlueInput]: isBlueStyle,
          [classes.isGreenInput]: isGreenStyle,
          [classes.disabledInput]: isDisabled,
        }),
        endAdornment: uiSchema['ui:suffix'] && (
          <InputAdornment
            position="start"
            className={clsx(classes.inputAdornment, {
              [classes.isGreyInputAdornment]: isGreyStyle,
              [classes.isBlueInputAdornment]: isBlueStyle,
              [classes.isGreenInputAdornment]: isGreenStyle,
            })}
          >
            {uiSchema['ui:suffix']}
          </InputAdornment>
        ),
      }}
      variant="outlined"
      placeholder={placeholder}
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly || uiSchema['ui:disabled'] || isCalculated}
      type={inputType}
      value={_value ?? ''}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      helperText={
        uiSchema['ui:hint'] && (
          <span className={classes.helperTextContent}>
            {uiSchema['ui:hint']}
          </span>
        )
      }
      label={
        isSmallScreen ? uiSchema['ui:label'] || label || schema.title : false
      }
      FormHelperTextProps={{
        className: classes.helperTextContainer,
      }}
      {...(textFieldProps as TextFieldProps)}
    />
  )
}
