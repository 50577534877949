import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid #DFDFDF;',
    borderRadius: '10px',
    padding: theme.spacing(2),
  },
  mapElement: { width: '100%', height: '465px' },
}))

export const markerIcon = {
  symbol: {
    symbolType: 'image',
    /* eslint-disable */
    src: require('assets/images/markerIcon.png'),
    size: [35, 40],
    color: 'white',
    rotateWithView: false,
    offset: [0, 9],
  },
}
