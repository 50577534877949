import React from 'react'
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import StepConnector from '@material-ui/core/StepConnector'
import clsx from 'clsx'
import { StepIconProps } from '@material-ui/core/StepIcon'
import { Advertise, AqaarShape, TextMoalen } from 'assets'
import { IRenderStepLabels } from 'components/PropertyDetailsStepper/PropertyDetailsStepper.interface'

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.38)',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: '#fff',
  },
}))

export function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <TextMoalen />,
    2: <Advertise />,
    3: <AqaarShape />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    width: '3%',
    margin: '0.5rem 0.5rem 0 0.5rem',
    borderRadius: '1rem',
  },
})(StepConnector)

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  active: {
    color: theme.palette.secondary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
}))

export function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.circle} />
    </div>
  )
}
