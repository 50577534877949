import React from 'react'
import { Grid, Box, Card, CardHeader } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export const CardSkeleton = () => {
  return (
    <Box
      sx={{
        minHeight: 290,
        minWidth: 240,
        marginRight: 0.5,
        mt: 5,
        border: '1px solid #DFDFDF',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      {' '}
      <Skeleton variant="rectangular" height={200} />
      <div style={{ paddingInlineStart: '10px' }}>
        <Skeleton width="95%" />
        <Skeleton width="90%" />
        <Skeleton width="65%" />
        <Skeleton width="55%" />
        <Skeleton animation="wave" height={60} width="90%" />
      </div>
      <Card elevation={0} sx={{ maxWidth: 345 }} />
    </Box>
  )
}
