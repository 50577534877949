export const confirmButtonProps = {
  style: {
    width: '50%',
  },
  children: 'إضافة الإعلان',
}

export const resetButtonProps = {
  style: {
    width: '50%',
    color: 'black',
    backgroundColor: '#F5F5F5',
  },
  children: 'إلغاء',
}
