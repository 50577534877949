import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
  Drawer,
  Button,
} from '@material-ui/core'
import { DropdownSelect } from 'components/DropdownSelect'
import { RootState } from 'store/reducer'
import { setAdvertiser } from 'store/advertiser'
import { SelectMenu } from 'components/SelectMenu'
import { CloseIcon } from 'assets'
import { useStyle } from './MyAddsBar.style'

export const MyAddsBar: FC<any> = ({
  adsCount = 0,
  setDisplayMethod,
  setDisplayOrder,
  setDisplayStatus,
  setPropertyType,
  applyMobileFilter,
}) => {
  const classes = useStyle()
  const dispatcher = useDispatch()
  const { advertiser } = useSelector((state: RootState) => state)
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { t } = useTranslation()
  const [selectedProperty, setSelectedType] = useState('')
  const [itemSelected, setItemSelected] = useState(false)
  const [selectedDisplayMethod, setSelectedDisplayMethod] =
    useState('map and list')
  const [selectedDisplayOrder, setSelectedDisplayOrder] =
    useState('newest first')
  const [selectedDisplayStatus, setSelectedDisplayStatus] = useState('all')
  const handleClose = () => {
    setItemSelected(!itemSelected)
  }

  const handleItemClick = (value) => {
    setSelectedDisplayMethod(value)
    setDisplayMethod(value)
    handleClose()
  }

  const handleDisplayStatusItemClick = (status) => {
    setSelectedDisplayStatus(status)
    setDisplayStatus(status)
    handleClose()
  }
  const handleOrderItemClick = (value) => {
    setSelectedDisplayOrder(value)
    switch (value) {
      case 'newest first':
        setDisplayOrder('desc')
        break

      default:
        setDisplayOrder('asc')
        break
    }

    handleClose()
  }
  const handleMobileFilterClose = () => {
    dispatcher(setAdvertiser('myAdsMobileFilter', false))
  }
  const handleDisplayTypeClick = (type) => {
    setSelectedType(type)
    setPropertyType(type)
  }
  const handleFilterClick = () => {
    handleMobileFilterClose()
    applyMobileFilter()
  }
  return (
    <div>
      {isMobileScreen ? (
        <Drawer
          className={classes.drawerRoot}
          anchor="bottom"
          open={advertiser.myAdsMobileFilter}
          onClose={handleMobileFilterClose}
        >
          <Grid className={classes.drawerContainer}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Typography className={classes.drawerTitle} align="center">
                  {t('search filter')}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  onClick={handleMobileFilterClose}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            {/* <Grid item className={classes.displayStatusContainer}>
              <SelectMenu
                title={t(selectedDisplayStatus)}
                subheader=""
                itemSelected={itemSelected}
              >
                {' '}
                <List>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleDisplayStatusItemClick('all')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('all')}{' '}
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleDisplayStatusItemClick('INSPECTION')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('INSPECTION')}{' '}
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleDisplayStatusItemClick('REJECTED')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('REJECTED')}{' '}
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleDisplayStatusItemClick('DEACTIVATED')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('DEACTIVATED')}{' '}
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleDisplayStatusItemClick('PUBLISHED')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('PUBLISHED')}{' '}
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleDisplayStatusItemClick('EXPIRED')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('EXPIRED')}{' '}
                    </ListItemText>
                  </ListItem>
                </List>
              </SelectMenu>
            </Grid> */}
            <Grid item>
              <SelectMenu
                title={t(selectedDisplayOrder)}
                subheader=""
                itemSelected={itemSelected}
              >
                <List>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleOrderItemClick('newest first')}
                  >
                    <ListItemText
                      className={classes.selectOption}
                      disableTypography
                    >
                      {t('newest first')}
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => handleOrderItemClick('oldest first')}
                  >
                    <ListItemText
                      className={`${classes.selectOption} ${classes.selectOption}`}
                      disableTypography
                    >
                      {t('oldest first')}
                    </ListItemText>
                  </ListItem>
                </List>
              </SelectMenu>
            </Grid>
            <Grid
              item
              container
              className={classes.mobileToggleButtonsContainer}
              justifyContent="space-between"
              alignItems="center"
            >
              <div
                className={
                  selectedProperty === 'sale'
                    ? classes.mobileToggleButtonActive
                    : ''
                }
                onClick={() => {
                  handleDisplayTypeClick('sale')
                }}
              >
                {t('for sale')}
              </div>
              <div
                className={
                  selectedProperty === 'rent'
                    ? classes.mobileToggleButtonActive
                    : ''
                }
                onClick={() => {
                  handleDisplayTypeClick('rent')
                }}
              >
                {t('for rent')}
              </div>
              <div
                className={
                  selectedProperty === 'investment'
                    ? classes.mobileToggleButtonActive
                    : ''
                }
                onClick={() => {
                  handleDisplayTypeClick('investment')
                }}
              >
                {t('for investment')}
              </div>
            </Grid>
          </Grid>
          <Grid>
            <Button
              className={classes.mobileButton}
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleFilterClick}
              fullWidth
            >
              <span>{t('apply')}</span>
            </Button>
          </Grid>
        </Drawer>
      ) : (
        <Grid container alignItems="center" className={classes.bar}>
          <Grid item md={7} container alignItems="center">
            <Typography className={classes.title}>{t('my ads')} </Typography>
            <Typography className={classes.addsCount}>
              {`${t('you have')} “ ${adsCount} “ ${t('advertises')}`}
            </Typography>
          </Grid>
          <Grid
            className={classes.filtersContainer}
            item
            container
            alignItems="center"
            md={5}
            xs={12}
          >
            <Grid item md={4} xs={5} container alignItems="center">
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('display_status')}
                  subheader={t(selectedDisplayStatus)}
                  itemSelected={itemSelected}
                >
                  <List>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleDisplayStatusItemClick('all')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('all')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleDisplayStatusItemClick('INSPECTION')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('INSPECTION')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleDisplayStatusItemClick('REJECTED')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('REJECTED')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() =>
                        handleDisplayStatusItemClick('DEACTIVATED')
                      }
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('DEACTIVATED')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleDisplayStatusItemClick('PUBLISHED')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('PUBLISHED')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleDisplayStatusItemClick('EXPIRED')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('EXPIRED')}{' '}
                      </ListItemText>
                    </ListItem>
                  </List>
                </DropdownSelect>
              </Grid>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid item md={4} xs={5} container alignItems="center">
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('display_method')}
                  subheader={t(selectedDisplayMethod)}
                  itemSelected={itemSelected}
                >
                  <List>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleItemClick('map only')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('map only')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleItemClick('list only')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('list only')}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={`${classes.listItem} hide-at-mobile`}
                      onClick={() => handleItemClick('map and list')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('map and list')}
                      </ListItemText>
                    </ListItem>
                  </List>
                </DropdownSelect>
              </Grid>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid
              item
              md={4}
              xs={5}
              container
              alignItems="center"
              className={classes.dropDownSearchBar}
            >
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('advertise_time')}
                  subheader={t(selectedDisplayOrder)}
                  itemSelected={itemSelected}
                >
                  <List>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleOrderItemClick('newest first')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('newest first')}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handleOrderItemClick('oldest first')}
                    >
                      <ListItemText
                        className={`${classes.selectOption} ${classes.selectOption}`}
                        disableTypography
                      >
                        {t('oldest first')}
                      </ListItemText>
                    </ListItem>
                  </List>
                </DropdownSelect>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
