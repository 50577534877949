import React, { FC } from 'react'
import { Grid } from '@material-ui/core'

import {
  SearchResultsMap,
  SearchResultsCardsList,
  CardSkeleton,
} from 'components'

import { useStyle } from './CardsListMap.style'

export const CardsListMap: FC<any> = ({
  coords = [0, 0],
  advertises = [],
  advertisesLoader,
}) => {
  const classes = useStyle()

  const getLoaderSkelatons = () => {
    return (
      <Grid item container>
        {Array.from(new Array(20)).map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <CardSkeleton />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item md={5} className={classes.cardsContainer}>
        {advertisesLoader ? (
          getLoaderSkelatons()
        ) : (
          <SearchResultsCardsList viewRatio={6} advertises={advertises} />
        )}
      </Grid>

      <Grid item md={7}>
        <SearchResultsMap
          advertises={advertises}
          advertisesLoader={advertisesLoader}
        />
      </Grid>
    </Grid>
  )
}
