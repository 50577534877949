import { FieldProps } from '@rjsf/core'
import { useStyle } from './GrayBackgroundFieldStyles'

export const GrayBackgroundField = (props: FieldProps) => {
  const { registry } = props
  const { ObjectField }: any = registry.fields
  const classes = useStyle()

  return (
    <div className={classes.root}>
      <ObjectField {...props} />
    </div>
  )
}
