import { FC, useEffect, useState } from 'react'
import { Container, useTheme, useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { fetchAdvertises } from 'store/advertiser'
import {
  MyAddsBar,
  SearchResultsMap,
  CardsListMap,
  SearchResultsCardsList,
  MCardsDrawerMap,
} from 'components'

import { useStyle } from './MyAdds.style'

export const MyAdds: FC<any> = () => {
  const classes = useStyle()
  const dispatcher = useDispatch()
  const { advertiser } = useSelector((state: RootState) => state)
  const { advertises, advertisesLoader } = advertiser
  const [displayMethod, setDisplayMethod] = useState('map and list')
  const [filters, setFilters] = useState({
    pageInfo: {
      limit: 20,
      offset: 0,
      orderBy: [{ field: 'date_added', order: 'desc' }],
    },
    filters: {
      zoomLevel: 10,
      lookups: [],
    },
  })
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  useEffect(() => {
    dispatcher(fetchAdvertises(filters))
  }, [])

  const setDisplayOrder = (order: string) => {
    filters.pageInfo.orderBy[0].order = order
    filters.pageInfo.offset = 0
    setFilters({ ...filters })
    if (isMobileScreen) return
    dispatcher(fetchAdvertises(filters, true))
  }

  const setDisplayStatus = (status: string) => {
    const index = filters.filters.lookups.findIndex(
      ({ field }) => field === 'status'
    )
    if (index === -1) {
      filters.filters.lookups.push({
        field: 'status',
        clause: 'exact',
        value: status,
      })
    } else if (status === 'all') {
      filters.filters.lookups.splice(index, 1)
    } else {
      filters.filters.lookups[index].value = status
    }

    filters.pageInfo.offset = 0
    setFilters({ ...filters })
    if (isMobileScreen) return
    dispatcher(fetchAdvertises(filters, true))
  }
  const setPropertyType = (type: string) => {
    const index = filters.filters.lookups.findIndex(
      ({ field }) => field === 'ad_type'
    )
    if (index === -1) {
      filters.filters.lookups.push({
        field: 'ad_type',
        clause: 'exact',
        value: type,
      })
    } else {
      filters.filters.lookups[index].value = type
    }

    filters.pageInfo.offset = 0
    setFilters({ ...filters })
    if (isMobileScreen) return
    dispatcher(fetchAdvertises(filters, true))
  }
  const applyMobileFilter = () => {
    dispatcher(fetchAdvertises(filters, true))
  }
  const adsCount = advertises.Count

  // re-fetch on scroll
  const validateScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.scrollingElement
    if (scrollTop + clientHeight >= scrollHeight) {
      setLoadMore(true)
      setTimeout(() => {
        setLoadMore(false)
      }, 2000)
    }
  }
  useEffect(() => {
    document.addEventListener('scroll', validateScroll)
    return () => document.removeEventListener('scroll', validateScroll)
  }, [])

  useEffect(() => {
    if (!loadMore || advertises?.advertises?.length === 0) return
    const { offset, limit } = filters.pageInfo
    filters.pageInfo.offset = Number(limit) + Number(offset)
    setFilters({ ...filters })
    dispatcher(fetchAdvertises(filters, false))
    // dispatcher(fetchPublishedAdvertises(searchParams))
  }, [loadMore])

  const displayContent = () => {
    switch (displayMethod) {
      case 'map only':
        return (
          <SearchResultsMap
            advertises={advertises.advertises}
            advertisesLoader={advertisesLoader}
          />
        )

      case 'list only':
        return (
          <SearchResultsCardsList
            viewRatio={3}
            advertises={advertises.advertises}
            advertisesLoader={advertisesLoader}
          />
        )
      case 'map and list':
        return (
          <CardsListMap
            advertises={advertises.advertises}
            advertisesLoader={advertisesLoader}
          />
        )
      default:
        break
    }
  }
  return (
    <Container className={classes.root}>
      <MyAddsBar
        adsCount={adsCount}
        setDisplayOrder={setDisplayOrder}
        setDisplayMethod={setDisplayMethod}
        setDisplayStatus={setDisplayStatus}
        setPropertyType={setPropertyType}
        applyMobileFilter={applyMobileFilter}
      />

      {isMobileScreen ? (
        <MCardsDrawerMap
          advertises={advertises.advertises}
          advertisesLoader={advertisesLoader}
          adsCount={adsCount}
        />
      ) : (
        displayContent()
      )}
    </Container>
  )
}
