import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  infoIconWrapper: {
    transform: 'scale(1.7)',
  },
  anchoreTag: {
    textDecoration: 'underline',
    display: 'inline-block',
    margin: '0 0.2rem',
    color: 'inherit',
  },
}))
