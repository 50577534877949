import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  stepperContainer: {
    paddingInline: 0,
    paddingBlockEnd: 0,

    overflow: 'hidden',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      height: '.5rem',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      backgroundColor: '#ccc',
      outline: '1px solid slategrey',
    },
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  stepContainer: {
    cursor: 'pointer',
  },
  iconContainer: {
    position: 'relative',
    zIndex: 999,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column-reverse !important' as 'column-reverse',
  },
  label: {
    fontSize: '1.4rem',
    fontWeight: 400,

    '& + p': {
      fontSize: '1.8rem',
      fontWeight: 700,
      marginBottom: 20,
    },
  },
  disabledlabel: {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: '#707070',
    '& + p': {
      fontSize: '1.8rem',
      fontWeight: 700,
      color: '#000000 !important',
      marginBottom: 20,
    },
  },
}))
