import { FC, useState, useEffect } from 'react'
import qs from 'qs'
import { LoadingProgress } from '@flint/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core'
import { fetchPublishedAdvertises, setAdvertiser } from 'store/advertiser'
import { RootState } from 'store/reducer'
import { CloseIcon, SearchIcon } from 'assets'
import {
  AutoComplete,
  DropdownSelect,
  PriceSlider,
  SelectMenu,
} from 'components'

import { useStyle } from './SearchResultsBar.style'

export const SearchResultsBar: FC<any> = ({ adsCount, setDisplayMethod }) => {
  const realEstateTypes = ['apartment', 'land']
  const displayTypes = ['sale', 'rent', 'investment']
  const classes = useStyle()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { t } = useTranslation()
  const { advertiser } = useSelector((state: RootState) => state)
  const { publishedAdvertises, advertisesLoader } = advertiser
  const dispatcher = useDispatch()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [searchParams, setSearchParams] = useState<any>({})
  const [priceRange, setPriceRange] = useState<number[]>([100, 90_000_000])
  const [itemSelected, setItemSelected] = useState(false)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [mobileOrderBy, setMobileOrderBy] = useState('order by')
  const [selectedDisplayMethod, setSelectedDisplayMethod] =
    useState('map and list')
  const [selectedDisplayOrder, setSelectedDisplayOrder] =
    useState('newest first')
  const handleClose = () => {
    setItemSelected(!itemSelected)
  }
  useEffect(() => {
    const {
      '?validatedSearchParams': pageInfo,
      validatedSearchParams: filters,
    }: any = qs.parse(window.location.search)

    const finalFilters = {
      pageInfo: { ...pageInfo.pageInfo },
      ...filters,
    }
    setSearchParams(finalFilters)
    dispatcher(fetchPublishedAdvertises(finalFilters, true))
  }, [window.location.search])

  useEffect(() => {
    if (!loadMore || publishedAdvertises?.advertises?.length === 0) return
    const { offset, limit } = searchParams.pageInfo
    searchParams.pageInfo.offset = Number(limit) + Number(offset)
    setSearchParams({ ...searchParams })

    dispatcher(fetchPublishedAdvertises(searchParams, false))
  }, [loadMore])
  useEffect(() => {
    document.addEventListener('scroll', validateScroll)
    return () => document.removeEventListener('scroll', validateScroll)
  }, [])
  const validateScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.scrollingElement
    if (scrollTop + clientHeight >= scrollHeight) {
      setLoadMore(true)
      setTimeout(() => {
        setLoadMore(false)
      }, 2000)
    }
  }

  const handlePropertyTypeClick = (value: string) => {
    searchParams.filters.lookups[0].value = value

    handleClose()
  }
  const handleDisplayTypeClick = (value: string) => {
    searchParams.filters.lookups[1].value = value
    handleClose()
  }
  const handlePriceChange = (event: any, newValue: number | number[]) => {
    setPriceRange(newValue as number[])
  }

  const handleSearchClick = () => {
    if (isMobileScreen) {
      handleMobileFilterClose()
      handleMobileSearchClose()
    }
    setButtonLoading(true)
    searchParams.pageInfo.offset = 0
    searchParams.filters.lookups[2].value = String(priceRange[0])
    searchParams.filters.lookups[3].value = String(priceRange[1])

    dispatcher(fetchPublishedAdvertises(searchParams, true))
    setButtonLoading(false)
  }

  const handleOrderItemClick = (value) => {
    if (isMobileScreen) setMobileOrderBy(value)
    setSelectedDisplayOrder(value)
    switch (value) {
      case 'newest first':
        setDisplayOrder('desc')
        break

      default:
        setDisplayOrder('asc')
        break
    }

    handleClose()
  }
  const setDisplayOrder = (order: string) => {
    searchParams.pageInfo.orderBy[0].order = order
    searchParams.pageInfo.offset = 0
  }
  const handleItemClick = (value) => {
    setSelectedDisplayMethod(value)
    setDisplayMethod(value)
    handleClose()
  }
  const handleMobileFilterClose = () => {
    dispatcher(setAdvertiser('searchResultsMobileFilter', false))
  }
  const handleMobileSearchClose = () => {
    dispatcher(setAdvertiser('searchResultsMobileSearch', false))
  }
  const changeLocationHandler = (location: any) => {
    if (!location) {
      // reset bbox filter
      setSearchParams({
        ...searchParams,
        filters: {
          ...searchParams.filters,
          bbox: undefined,
        },
      })
      return
    }

    const { bounds } = location
    const bottomRightLonLat = [bounds[0][1], bounds[0][0]]
    const topLefLonLat = [bounds[1][1], bounds[1][0]]
    const bbox = JSON.stringify([...bottomRightLonLat, ...topLefLonLat])
    setSearchParams({
      ...searchParams,
      filters: {
        ...searchParams.filters,
        bbox: { bbox },
      },
    })
  }

  return (
    <div>
      {isMobileScreen ? (
        <>
          <Drawer
            className={classes.drawerRoot}
            anchor="bottom"
            open={advertiser.searchResultsMobileFilter}
            onClose={handleMobileFilterClose}
          >
            <Grid className={classes.drawerContainer}>
              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography className={classes.drawerTitle} align="center">
                    {t('search filter')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleMobileFilterClose}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <SelectMenu
                  title={t(mobileOrderBy)}
                  subheader=""
                  itemSelected={itemSelected}
                >
                  {' '}
                  <List>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleOrderItemClick('newest first')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('newest first')}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handleOrderItemClick('oldest first')}
                    >
                      <ListItemText
                        className={`${classes.selectOption} ${classes.selectOption}`}
                        disableTypography
                      >
                        {t('oldest first')}
                      </ListItemText>
                    </ListItem>
                  </List>
                </SelectMenu>
              </Grid>
              <Grid
                item
                className={classes.inputWrapper}
                container
                alignItems="center"
              >
                <input
                  type="number"
                  placeholder={t('price from')}
                  value={priceRange[0]}
                  onChange={(event: any) => {
                    setPriceRange([event.target.value, priceRange[1]])
                  }}
                  min={0}
                />
                <span>ر.س</span>
              </Grid>
              <Grid
                className={classes.inputWrapper}
                container
                alignItems="center"
              >
                <input
                  type="number"
                  placeholder={t('price to')}
                  value={priceRange[1]}
                  onChange={(event: any) => {
                    setPriceRange([priceRange[0], event.target.value])
                  }}
                  min={0}
                />
                <span>ر.س</span>
              </Grid>
              <Grid
                item
                container
                className={classes.mobileToggleButtonsContainer}
                justifyContent="space-between"
                alignItems="center"
              >
                <div
                  className={
                    searchParams.filters.lookups[1].value === 'sale'
                      ? classes.mobileToggleButtonActive
                      : ''
                  }
                  onClick={() => {
                    handleDisplayTypeClick('sale')
                  }}
                >
                  {t('for sale')}
                </div>
                <div
                  className={
                    searchParams.filters.lookups[1].value === 'rent'
                      ? classes.mobileToggleButtonActive
                      : ''
                  }
                  onClick={() => {
                    handleDisplayTypeClick('rent')
                  }}
                >
                  {t('for rent')}
                </div>
                <div
                  className={
                    searchParams.filters.lookups[1].value === 'investment'
                      ? classes.mobileToggleButtonActive
                      : ''
                  }
                  onClick={() => {
                    handleDisplayTypeClick('investment')
                  }}
                >
                  {t('for investment')}
                </div>
              </Grid>
            </Grid>
            <Grid>
              <Button
                className={classes.mobileButton}
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSearchClick}
                fullWidth
              >
                {buttonLoading ? (
                  <CircularProgress size={30} thickness={5} color="secondary" />
                ) : (
                  <span>{t('apply')}</span>
                )}
              </Button>
            </Grid>
          </Drawer>
          <Drawer
            className={classes.drawerRoot}
            anchor="bottom"
            open={advertiser.searchResultsMobileSearch}
            onClose={handleMobileSearchClose}
          >
            <Grid
              container
              direction="column"
              className={classes.drawerContainer}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.MobileLabel}>
                    {' '}
                    {t('search for property')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleMobileSearchClose}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item>
                <SelectMenu
                  title={t(searchParams.filters.lookups[0].value)}
                  subheader=""
                  itemSelected={itemSelected}
                >
                  {' '}
                  <List className={classes.listContainer}>
                    {realEstateTypes.map((type, index) => (
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => handlePropertyTypeClick(type)}
                        key={index}
                      >
                        <ListItemText
                          disableTypography
                          className={classes.title}
                        >
                          {t(type)}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </SelectMenu>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.middelSectionContainer}
              >
                <Grid item xs={5} className={classes.searchDrawerdivider} />
                <Grid xs={2} item>
                  <Typography align="center">{t('in')}</Typography>
                </Grid>

                <Grid xs={5} item className={classes.searchDrawerdivider} />
              </Grid>

              <Grid item className={classes.mobileSearchInput}>
                <AutoComplete changeLocationHandler={changeLocationHandler} />
              </Grid>

              <Grid
                item
                container
                className={classes.mobileToggleButtonsContainer}
                justifyContent="space-between"
                alignItems="center"
              >
                <div
                  className={
                    searchParams.filters.lookups[1].value === 'sale'
                      ? classes.mobileToggleButtonActive
                      : ''
                  }
                  onClick={() => {
                    handleDisplayTypeClick('sale')
                  }}
                >
                  {t('for sale')}
                </div>
                <div
                  className={
                    searchParams.filters.lookups[1].value === 'rent'
                      ? classes.mobileToggleButtonActive
                      : ''
                  }
                  onClick={() => {
                    handleDisplayTypeClick('rent')
                  }}
                >
                  {t('for rent')}
                </div>
                <div
                  className={
                    searchParams.filters.lookups[1].value === 'investment'
                      ? classes.mobileToggleButtonActive
                      : ''
                  }
                  onClick={() => {
                    handleDisplayTypeClick('investment')
                  }}
                >
                  {t('for investment')}
                </div>
              </Grid>
            </Grid>
            <Grid>
              <Button
                className={classes.mobileButton}
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSearchClick}
                fullWidth
              >
                {buttonLoading ? (
                  <CircularProgress size={30} thickness={5} color="secondary" />
                ) : (
                  <>
                    <SearchIcon className={classes.mobileIcon} />
                    <span>{t('search')}</span>
                  </>
                )}
              </Button>
            </Grid>
          </Drawer>
        </>
      ) : (
        <Grid container alignItems="center" className={classes.bar}>
          <Grid
            item
            md={3}
            container
            alignItems="center"
            className={classes.districtName}
          >
            <Typography> </Typography>
          </Grid>
          <Grid
            className={classes.filtersContainer}
            item
            container
            alignItems="center"
            md={9}
            xs={12}
          >
            <Grid item md={2} xs={5} container alignItems="center">
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('property type')}
                  subheader={t(searchParams?.filters?.lookups[0]?.value)}
                  itemSelected={itemSelected}
                >
                  <List style={{ position: 'relative', zIndex: 100000000000 }}>
                    {realEstateTypes.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        className={classes.listItem}
                        onClick={() => handlePropertyTypeClick(item)}
                        style={{ position: 'relative', zIndex: 100000000000 }}
                      >
                        <ListItemText
                          className={classes.selectOption}
                          disableTypography
                          style={{ position: 'relative', zIndex: 100000000000 }}
                        >
                          {t(item)}{' '}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </DropdownSelect>
              </Grid>

              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid item md={2} xs={5} container alignItems="center">
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('display type')}
                  subheader={t(searchParams?.filters?.lookups[1]?.value)}
                  itemSelected={itemSelected}
                >
                  <List>
                    {displayTypes.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        className={classes.listItem}
                        onClick={() => handleDisplayTypeClick(item)}
                      >
                        <ListItemText
                          className={classes.selectOption}
                          disableTypography
                        >
                          {t(item)}{' '}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </DropdownSelect>
              </Grid>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid item md={3} xs={5} container alignItems="center">
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('price')}
                  subheader={`${priceRange[0]?.toLocaleString()} - ${priceRange[1]?.toLocaleString()}`}
                  itemSelected={itemSelected}
                >
                  <List>
                    <ListItem className={classes.listItem}>
                      <PriceSlider
                        priceRange={priceRange}
                        handlePriceChange={handlePriceChange}
                      />
                    </ListItem>
                  </List>
                </DropdownSelect>
              </Grid>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid item md={2} xs={5} container alignItems="center">
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('display_method')}
                  subheader={t(selectedDisplayMethod)}
                  itemSelected={itemSelected}
                >
                  <List>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleItemClick('map only')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('map only')}{' '}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleItemClick('list only')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('list only')}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={`${classes.listItem} hide-at-mobile`}
                      onClick={() => handleItemClick('map and list')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('map and list')}
                      </ListItemText>
                    </ListItem>
                  </List>
                </DropdownSelect>
              </Grid>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid
              item
              md={2}
              xs={5}
              container
              alignItems="center"
              className={classes.dropDownSearchBar}
            >
              <Grid item className={classes.dropDownSearchBar}>
                <DropdownSelect
                  title={t('order by')}
                  subheader={t(selectedDisplayOrder)}
                  itemSelected={itemSelected}
                >
                  <List>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => handleOrderItemClick('newest first')}
                    >
                      <ListItemText
                        className={classes.selectOption}
                        disableTypography
                      >
                        {t('newest first')}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handleOrderItemClick('oldest first')}
                    >
                      <ListItemText
                        className={`${classes.selectOption} ${classes.selectOption}`}
                        disableTypography
                      >
                        {t('oldest first')}
                      </ListItemText>
                    </ListItem>
                  </List>
                </DropdownSelect>
              </Grid>
            </Grid>

            <Grid item md={1} xs={11} container alignItems="center">
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSearchClick}
              >
                {buttonLoading ? (
                  <CircularProgress size={30} thickness={5} color="secondary" />
                ) : (
                  <span>{t('search')}</span>
                )}
              </Button>
            </Grid>
          </Grid>
          <Typography className={classes.addsCount} gutterBottom>
            {`${t('have found')} ${adsCount} ${t('result')}`}
          </Typography>
          <LoadingProgress loading={loadMore} />
        </Grid>
      )}
    </div>
  )
}
