import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: '#fff',
    padding: '10px 12px !important',
  },
  typographyFontsizes: {
    fontSize: '1.4rem',
  },
  textFieldBorderRadius: {
    '& .MuiInputBase-root': {
      borderRadius: `${theme.spacing(1.5)}px !important`,
      height: theme.spacing(6.7),
    },
  },
}))
