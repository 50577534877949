import { CountryDetailsView } from '../Components/CountryDetailsUI'
import Countries from '../CountryCodes.json'
import { Country } from './types'

export interface GetCountryDetailsProps {
  key: keyof Country
  value: string
}

export const getCountryDetails = ({ key, value }: GetCountryDetailsProps) => {
  const country = Countries.find((c) => c[key] === value)
  if (country?.code) {
    return country as Country
  }
}

export const getCountriersDetails = ({
  handleSelectCountryCode,
}: {
  handleSelectCountryCode: (dial_code: string) => void
}) => {
  return Countries.map((c) => (
    <CountryDetailsView
      handleSelectCountryCode={handleSelectCountryCode}
      country={c}
      key={c.name}
    />
  ))
}
