import React from 'react'
import { FieldProps } from '@rjsf/core'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './ContactInfoFieldStyles'

export const ContactInfoField = (props: FieldProps): React.ReactElement => {
  const {
    title,
    schema,
    readonly,
    disabled,
    formData,
    registry,
    uiSchema,
    onChange,
  } = props
  const ObjectField = registry.fields.ObjectField
  const classes = useStyles()
  return (
    <>
      <Typography
        variant="h1"
        style={{ padding: 0 }}
        className={classes.sectionTitle}
      >
        {schema.title || title}
      </Typography>
      <Typography variant="body1" style={{ fontSize: '1.4rem' }}>
        {schema.description || uiSchema['ui:description']}
      </Typography>
      <Grid
        container
        spacing={2}
        className={classes.fieldsContainer}
        style={uiSchema['ui:rootStyle']}
      >
        <Grid item className={classes.fieldItem} xs={uiSchema['ui:size'] || 12}>
          <ObjectField
            {...props}
            schema={schema}
            readonly={readonly}
            disabled={disabled}
            formData={formData}
            uiSchema={uiSchema}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  )
}
