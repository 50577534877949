import React from 'react'
import { WidgetProps } from '@rjsf/core'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'
import { Link } from 'react-router-dom'

import { useStyles } from './TermsAndConditionsStyles'

export const TermsAndConditionsWidget = ({
  schema,
  uiSchema,
  id,
  value,
  disabled,
  readonly,
  label,
  autofocus,
  setFormData,
  onBlur,
  onFocus,
  onChange,
  required,
  ...props
}: WidgetProps): React.ReactElement => {
  const classes = useStyles()
  const _onChange = (_: any, checked: boolean) => onChange(checked)

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.titleWrapper}>
        <h4 className={clsx(classes.headerTitle)}>{label}</h4>
        <Link
          className={classes.linkStyles}
          to={uiSchema['ui:href']}
          target="_blank"
        >
          {' '}
          {uiSchema['ui:linkTitle']}{' '}
        </Link>
      </div>
      <Checkbox
        className={classes.checkboxAdjustments}
        size="medium"
        required={required}
        value={value}
        onChange={_onChange}
      />
    </div>
  )
}
