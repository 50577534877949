import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  myads: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000000',
    paddingTop: theme.spacing(4),
  },
  centerImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBlockEnd: '50px',
  },
}))
