import React, { useRef, useState, useEffect, FC } from 'react'
import { Grid } from '@material-ui/core'

import View from 'ol/View'
import Map from 'ol/Map'
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
  RegularShape,
} from 'ol/style'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import { transform, transformExtent, toLonLat } from 'ol/proj'
import GeoJSON from 'ol/format/GeoJSON'
import LayerGroup from 'ol/layer/Group'
import MousePosition from 'ol/control/MousePosition'
import { createStringXY } from 'ol/coordinate'
import Overlay from 'ol/Overlay'
import clsx from 'clsx'

import qs from 'qs'
import { reproject } from 'reproject'
import { ImageCard, MapSkeleton } from 'components'
import { useStyle } from './SearchResultsMap.style'
import 'ol/ol.css'
import './SearchResults.style.scss'

const styleFunction = function (feature) {
  const olGeometryColor = feature.get('olGeometryColor') || 'sale'
  const price = feature.get('price')
  const colors = {
    rent: '#CA2D78',
    sale: '#662D91',
    selected: '#000000',
  }
  const fillColor = colors[olGeometryColor]
  const textFill = new Fill({ color: '#fff' })
  const backgroundFill = new Fill({ color: fillColor })
  const backgroundStroke = new Stroke({ color: 'white', width: 1 })

  const image = new CircleStyle({
    radius: 7,
    fill: new Fill({ color: fillColor }),
    stroke: new Stroke({ color: '#fff', width: 1 }),
  })
  const text = new Text({
    text: `${price} ر.س`,
    fill: textFill,
    textAlign: 'center',
    justify: 'center',
    offsetY: -25,
    font: '6px',
    backgroundFill,
    backgroundStroke,
    padding: [2, 5, 2, 5],
  })
  const triangleStyle = new Style({
    image: new RegularShape({
      fill: backgroundFill,
      points: 3,
      radius: 3,
      rotation: Math.PI,
      angle: 0,
      displacement: [0, -10],
    }),
  })
  const textImageStyle = new Style({
    image,
    text,
  })

  // return stacked style
  return [textImageStyle, triangleStyle]
}

const clusterStyleFunction = function (feature) {
  const olGeometryColor = feature.get('olGeometryColor') || 'sale'
  const olClusterSize = feature.get('olClusterSize') || ''
  const colors = {
    rent: (opacity = 1) => `rgba(202, 45, 120, ${opacity})`,
    sale: (opacity = 1) => `rgba(102, 45, 145, ${opacity})`,
    selected: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  }
  const fillColor = colors[olGeometryColor]
  const textFill = new Fill({ color: '#fff' })
  const textStroke = new Stroke({ color: 'white', width: 0.3 })

  const image = new CircleStyle({
    radius: 15,
    fill: new Fill({ color: fillColor(0.6) }),
    // stroke: new Stroke({ color: '#fff', width: 1 }),
  })
  const text = new Text({
    text: `${olClusterSize}`,
    fill: textFill,
    stroke: textStroke,
    textAlign: 'center',
    justify: 'center',
    font: '11px',
    padding: [2, 2, 2, 2],
  })
  const textImageStyle = new Style({ image, text })

  const outerCircle2 = new Style({
    image: new CircleStyle({
      radius: 20,
      fill: new Fill({ color: fillColor(0.4) }),
    }),
  })
  const outerCircle3 = new Style({
    image: new CircleStyle({
      radius: 25,
      fill: new Fill({ color: fillColor(0.1) }),
    }),
  })

  // return stacked style
  return [textImageStyle, outerCircle2, outerCircle3]
}

const createGeoJSONLayer = ({ geojsonObject, name }) => {
  const geojson = new GeoJSON({ geometryName: 'geom' }).readFeatures(
    geojsonObject,
    { featureProjection: 'EPSG:4326' }
  )
  const vectorSource = new VectorSource({ features: geojson, wrapX: false })
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
    visible: true,
  })
  vectorLayer.set('name', name)
  return vectorLayer
}

const createOlMap = ({
  mapElementRef,
  mousePositionRef = undefined,
  popUpRef,
  overlayRef,
}) => {
  const osmLayer = new TileLayer({ source: new OSM() })
  osmLayer.set('name', 'osm')

  const baseMapsGroup = new LayerGroup({ layers: [osmLayer] })
  baseMapsGroup.set('name', 'baseMaps')

  const mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(12),
    projection: 'EPSG:4326',
    // comment the following two lines to have the mouse position
    // be placed within the map.
    className: 'custom-mouse-position',
    target: mousePositionRef.current,
  })

  const identifyPopUpOverlay = new Overlay({
    element: popUpRef.current,
    id: 'popup',
    autoPan: {
      animation: {
        duration: 250,
      },
    },
  })
  overlayRef.current = identifyPopUpOverlay

  return new Map({
    target: mapElementRef.current,
    layers: [baseMapsGroup],
    view: new View({
      constrainResolution: true,
      projection: 'EPSG:3857',
    }),
    controls: [mousePositionControl],
    overlays: [identifyPopUpOverlay],
  })
}

const onMapPointerMove = (olMap) => {
  let selected = null
  olMap.on('pointermove', function (e) {
    if (selected !== null) {
      selected.set('olGeometryColor', selected.get('oldOlGeometryColor'))
      selected.setStyle(styleFunction(selected))
      selected = null
    }

    olMap.forEachFeatureAtPixel(e.pixel, function (f) {
      selected = f
      selected.set('oldOlGeometryColor', f.get('olGeometryColor'))
      f.set('olGeometryColor', 'selected')
      f.setStyle(styleFunction(f))
      return true
    })
  })
}

export const SearchResultsMap: FC<any> = ({
  advertises = [],
  advertisesLoader,
}) => {
  const classes = useStyle()

  const { validatedSearchParams: filters }: any = qs.parse(
    window.location.search
  )
  const bboxFilter = filters?.filters?.bbox?.bbox

  const bbox = bboxFilter && JSON.parse(bboxFilter)

  // ol map object and map dom element refs
  const [olMapObj, setOlMapObj] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState(undefined)
  const olObjRef = useRef(olMapObj)
  const mapElementRef = useRef(null)
  // popup dom element and ol object refs
  const popUpRef = useRef(null)
  const overlayRef = useRef(null)
  // mouse position dom element ref
  const mousePositionRef = useRef(null)

  const updateMapLayers = () => {
    const { olMap, recordsLayer } = olObjRef.current
    const validGeomAds = advertises.filter((ad) => ad.geometry)
    const geojsonArray = validGeomAds.map((ad) => {
      const properties = {
        ...ad?.data,
        olGeometryColor: ad?.data?.ad_type || 'sale', // inject olGeometryColor key
      }
      return {
        type: 'Feature',
        geometry: reproject(ad.geometry, 'EPSG:4326', 'EPSG:3857'),
        properties,
      }
    })
    const geojsonObject = {
      type: 'FeatureCollection',
      features: geojsonArray,
    }

    const newRecordsLayer = createGeoJSONLayer({
      geojsonObject,
      name: 'recordsLayer',
    })

    // remove the old records layer
    olMap.removeLayer(recordsLayer)

    // add the new one to the map object
    olMap.addLayer(newRecordsLayer)

    // onClick map listener
    olMap.on('singleclick', function (evt) {
      const features = olMap.getFeaturesAtPixel(evt.pixel)
      const feature = features.length ? features[0] : undefined
      if (!feature) {
        overlayRef.current.setPosition(undefined)
        return
      }
      // @ts-ignore
      setSelectedRecord(feature?.values_)
      overlayRef.current.setPosition(evt.coordinate)
    })

    // fit to extent
    olMap
      .getView()
      .fit(
        transformExtent(
          bbox || [39.828787, 22.60163, 51.122732, 28.442412],
          'EPSG:4326',
          'EPSG:3857'
        )
      )

    // update the ref
    olObjRef.current = {
      olMap,
      recordsLayer: newRecordsLayer,
    }
  }

  // create map for the first time load
  useEffect(() => {
    // create map
    const olMap = createOlMap({
      mapElementRef,
      mousePositionRef,
      popUpRef,
      overlayRef,
    })

    // select feature
    onMapPointerMove(olMap)

    // set mapElement Ref to the map object ol.Map, useful with map events like on map click
    olObjRef.current = { olMap }
    setOlMapObj(olObjRef)
  }, [advertisesLoader])

  // update layers on new fetch
  useEffect(() => {
    // if loading, do nothing
    if (advertisesLoader) return
    setSelectedRecord(null)
    updateMapLayers()
  }, [advertisesLoader, advertises])

  return (
    <Grid className={classes.root}>
      {advertisesLoader ? (
        <MapSkeleton />
      ) : (
        <>
          <div ref={mapElementRef} className={classes.mapElementRef} />
          {/* <div ref={mousePositionRef} className="custom-mouse-position" /> */}
          <div
            id="popup"
            ref={popUpRef}
            className={clsx(classes.olPopup, {
              [classes.olPopupCloser]: !selectedRecord,
            })}
          >
            <div id="popup-content" className="popup-content">
              {selectedRecord && (
                <ImageCard advertise={{ ...selectedRecord }} />
              )}
            </div>
          </div>
        </>
      )}
    </Grid>
  )
}
