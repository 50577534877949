import { useSelector } from 'react-redux'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from '@flint/locales'
import { RootState } from 'store/reducer'
import { ExpandMenu } from 'components'

import { useStyle } from './Header.style'

export const RenderAdvertiserTabs = () => {
  const { advertiser } = useSelector((state: RootState) => state)
  const isUserAdvertiser = () =>
    advertiser.advertiser && Object.keys(advertiser.advertiser).length > 0
  const navigate = useNavigate()
  const { t } = useTranslation()
  const classes = useStyle()

  const handleClickMyAds = () => {
    navigate('/my-adds')
  }

  const renderTabs = () => {
    if (isUserAdvertiser()) {
      return (
        <ExpandMenu title={t('advertiser account')} subheader="">
          <List>
            <ListItem button onClick={handleClickMyAds}>
              <ListItemText disableTypography className={classes.title}>
                {t('my ads')} ( {advertiser.advertises.Count} )
              </ListItemText>
            </ListItem>
          </List>
        </ExpandMenu>
      )
    } else {
      return (
        <Link to="/broker">
          <Typography className={classes.title}>
            {t('join as advertiser')}
          </Typography>
        </Link>
      )
    }
  }
  return <>{renderTabs()}</>
}
