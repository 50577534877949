import React, { useContext } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from '@flint/locales'

import { CardComponent } from 'components/CardComponent/CardComponent'
import JoinAsAnOrganization from '../../../../assets/images/joinAsAPerson.png'

import { useStyles } from './secondCardStyles'

export interface SeconCardProps {
  isWithoutButton?: boolean
}

export const SecondCard = ({
  isWithoutButton = false,
}: SeconCardProps): React.ReactElement => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleJoinAsPublisher = () => {
    navigate('/join-as-broker')
  }

  return (
    <Card
      className={
        isWithoutButton
          ? `${classes.hideBorders} ${classes.root}`
          : classes.root
      }
    >
      <CardContent className={classes.cardContentWrapper}>
        <CardComponent
          image={JoinAsAnOrganization}
          title={t('Join as an individual advertiser')}
          label={t(
            'Adding the advertisement as an independent advertiser without being restricted to an establishment'
          )}
          imgAlt="join as an organization"
        />
        <Button
          size="small"
          variant="contained"
          fullWidth
          className={
            isWithoutButton ? classes.hideComponent : classes.ButtonContainer
          }
          onClick={() => handleJoinAsPublisher()}
        >
          {t('Register as an individual')}
        </Button>
      </CardContent>
    </Card>
  )
}
