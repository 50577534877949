import React, { useEffect } from 'react'

import { WidgetProps } from '@rjsf/core'
import { TextField, Chip, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { isObject } from '@flint/utils'
import { useStyles } from './SelectWidget.styles'

export interface ISelectWidgetOption {
  label: string
  value: string
}

const getDisplayedLabel = (option: ISelectWidgetOption): string =>
  // @ts-ignore
  option?.label ??
  option?.value ??
  (isObject(option) ? JSON.stringify(option) : option)

const getSelectedValue = (option: ISelectWidgetOption): string =>
  // @ts-ignore
  option?.value ?? option

const getDisplayedValue = (value: any, isMultiple: boolean) =>
  typeof value === 'undefined'
    ? isMultiple
      ? []
      : ''
    : isMultiple
    ? (value as ISelectWidgetOption[])?.map((option) =>
        getSelectedValue(option)
      ) ?? []
    : getSelectedValue(value)

export const SelectWidget = ({
  id,
  value,
  label,
  schema,
  required,
  disabled,
  readonly,
  multiple,
  autofocus,
  onChange,
  rawErrors,
  placeholder,
  oneBorder = false,
  noLabel = false,
  options: { enumOptions },
  uiSchema: uiSchemaProp,
}: WidgetProps) => {
  const classes = useStyles()
  const uiSchema = uiSchemaProp || {}
  const isMultiple = multiple || uiSchema['ui:multiple'] || false

  const _onChange = (_: any, value: any) => {
    return onChange(getActualValue(value))
  }

  const displayedValue = getDisplayedValue(value, isMultiple)

  const _menuOptions = (enumOptions ?? []) as ISelectWidgetOption[]
  const menuOptions = isMultiple
    ? _menuOptions.filter((option) => {
        return !displayedValue.includes(option?.value)
      })
    : _menuOptions

  const getActualValue = (
    label: ISelectWidgetOption | ISelectWidgetOption[]
  ) => {
    if (!isMultiple) {
      // @ts-ignore
      return label?.value ? label.value : label
    } else if (Array.isArray(label)) {
      return label.map((v) => {
        const index = (enumOptions as ISelectWidgetOption[])?.findIndex((e) =>
          typeof v === 'string' ? e.label === v : e.value === v.value
        )
        if (index >= 0) {
          return enumOptions[index].value
        }
        return v
      })
    }
  }

  const getDisplayed = (value: string | string[]): string | string[] => {
    if (typeof value === 'string') {
      const index = (enumOptions as ISelectWidgetOption[])?.findIndex(
        (e) => e.value === value
      )
      if (index >= 0) {
        return enumOptions[index].label
      }
      return value
    } else if (Array.isArray(value)) {
      return value.map((v) => {
        const index = (enumOptions as ISelectWidgetOption[])?.findIndex(
          (e) => e.value === v
        )
        if (index >= 0) {
          return enumOptions[index].label
        }
        return v
      })
    }
    return value
  }

  return (
    <Autocomplete
      id={id}
      fullWidth
      size="small"
      value={getDisplayed(value)}
      ChipProps={{
        Style: {
          fontSize: '1.4rem !important',
        },
      }}
      placeholder={placeholder}
      disabled={disabled || readonly}
      options={menuOptions}
      renderOption={(option: ISelectWidgetOption) => {
        return (
          <Typography
            className={classes.typographyFontsizes}
            key={option.value}
          >
            {option.label}
          </Typography>
        )
      }}
      getOptionLabel={(option: ISelectWidgetOption): string =>
        // getDisplayedLabel(option)
        (option.label ?? option ?? '') as string
      }
      onChange={_onChange}
      multiple={isMultiple}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              style={{ fontSize: '1.4rem' }}
              {...getTagProps({ index })}
            />
          )
        })
      }
      noOptionsText="لا يوجد اختيارات لمحدد البحث"
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textFieldBorderRadius}
          style={{ fontSize: '2rem !important' }}
          variant={oneBorder ? 'standard' : 'outlined'}
          required={required}
          autoFocus={autofocus}
          // value={displayedValue}
          label={
            !noLabel
              ? !oneBorder
                ? uiSchema['ui:label'] || label || schema.title
                : ''
              : ''
          }
          error={rawErrors?.length > 0}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            className: clsx(
              (params?.inputProps as any)?.className,
              classes.input
            ),
          }}
        />
      )}
    />
  )
}
