import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  customSelectFieldWrapper: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: theme.spacing(1.5),
  },
  headerTitle: {
    fontWeight: 'bold',
    margin: 0,
    color: theme.palette.common.black,
  },
}))
