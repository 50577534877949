import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: 'auto',
    maxHeight: 250,
    overflow: 'scroll',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    padding: 5,
    margin: 0,
    listStyle: 'none',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 120,
      zIndex: 10000,
    },
    '& li': {
      color: '#000000',
      height: 50,
      borderBottom: '1px solid #DFDFDF',
      textAlign: 'right',
      width: '100%',
      paddingRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:last-of-type': {
        borderBottom: 'none',
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
  },

  input: {
    width: '100%',
    height: 50,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 10,

    outline: 'none',
    border: 'none',
    padding: '0 10px',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',

    '&::placeholder': {
      fontSize: '1.4rem',
      color: '#000000',
    },
  },
  noBottomRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  BottomRadius: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}))
