import { makeStyles, Theme } from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '26% 72%',
    margin: '1rem 1rem',
    gridGap: '3rem',
    minHeight: 'calc(100vh - 80px - 60px)',
  },
  jsonFromSchemaWrapper: {
    display: 'grid',
    gridTemplateColumns: '100%',
    justifyContent: 'start',
    height: 'fit-content',
  },
  mianLightBackground: {
    backgroundColor: 'red',
  },
  handlePadding: {},
  licenseContainer: {
    backgroundColor: '#FAF4FF',
    borderRadius: 10,
    height: 'fit-content',
    '& .title': {
      padding: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 800,
      fontFamily: 'Almarai',
    },
    '& .sub-title': {
      color: '#16151A',
      padding: theme.spacing(2),
      fontFamily: 'Almarai',
      fontSize: 14,
      fontWeight: 700,
    },
    '& > label': {
      padding: theme.spacing(2),
      color: '#16151A',
      display: 'block',
      fontFamily: 'Almarai',
      fontSize: 14,
      fontWeight: 700,
    },
    '& .submitButton': {
      paddingInline: theme.spacing(2),
    },
    '& input': {
      padding: theme.spacing(2),
      marginInline: theme.spacing(2),

      borderRadius: 10,
      border: '1px solid #DFDFDF',
      backgroundColor: '#FFF',
      width: '100%',
      '&::placeholder': {
        color: '#16151A',
        fontFamily: 'Almarai',
        fontSize: 13,
        fontWeight: 400,
      },
    },
    '& button': {
      color: theme.palette.common.white,
      fontSize: 14,
      cursor: 'pointer',
      borderRadius: '0.7rem',
      paddingBlock: theme.spacing(1),
      marginBlock: theme.spacing(3),
      fontWeight: 700,
    },
  },

  verifyButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  successResponse: {
    backgroundColor: '#69CA84',
    '&:hover': {
      backgroundColor: '#69CA84',
      opacity: 0.9,
    },
  },
  errorResponse: {
    backgroundColor: '#d20303',
    '&:hover': {
      backgroundColor: '#d20303',
      opacity: 0.9,
    },
  },
  radioButtonContainer: {
    paddingInline: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    marginBlockEnd: theme.spacing(2),
    '& svg': {
      width: '20px',
      height: '21px',
    },
    '& label': {
      backgroundColor: 'white',
      padding: theme.spacing(1),

      borderRadius: 10,
      border: '1px solid #ccc',
      margin: 0,
      flexBasis: '49%',
      fontFamily: 'Almarai',
      fontSize: 12,
      fontWeight: 700,
    },
    '& label:not( :last-child)': {
      [theme.breakpoints.down('xs')]: {
        marginInlineEnd: 0,
        marginBlockEnd: theme.spacing(2),
      },
    },
    '& .MuiTypography-body1': {
      fontSize: 12,
      fontWeight: 700,
    },
    '& label:has(.Mui-checked)': {
      border: '1px solid #662D91',
      color: theme.palette.primary.main,
    },
  },
  advertiserDataContainer: {
    paddingInline: theme.spacing(2),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& p': {
      color: '#16151A',

      fontFamily: 'Almarai',
      fontSize: 14,
      fontWeight: 700,
    },
    '& > *': {
      flexBasis: '49%',
    },
    '& > *:not( :last-child)': {
      [theme.breakpoints.down('xs')]: {
        marginInlineEnd: 0,
        marginBlockEnd: theme.spacing(2),
      },
    },
    '& .info': {
      marginBlockStart: 15,
      backgroundColor: '#F0F0F0',
      borderRadius: 10,
      padding: theme.spacing(2.5),
      color: '#515151',

      fontFamily: 'Almarai',
      fontSize: 13,

      fontWeight: 700,
    },
  },
  footerContainer: {
    paddingInline: theme.spacing(2),
    marginBlock: theme.spacing(3),
    '& p': {
      marginInlineStart: theme.spacing(1),
      color: '#16151A',

      fontFamily: 'Almarai',
      fontSize: 13,

      fontWeight: 400,
    },
    '& svg': {
      flexShrink: 0,
    },
    '& a,& a:active,& a:visited,& a:link': {
      color: 'currentColor',
    },
  },
}))
