import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FAF4FF',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.25),
    '& .MuiInputLabel-animated': {
      margin: theme.spacing(0, 1.5),
      zIndex: '10',
    },
    '& .MuiInputBase-root': {
      background: 'white',
      border: '1px solid #ccc',
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.75, 1.5),
    },
    '& .MuiInput-underline:after': {
      borderWidth: theme.spacing(0.025),
    },
    '& .MuiInput-underline:before': {
      border: 0,
      display: 'none',
    },
  },
  realEstateAuth: {
    float: 'right',
    margin: theme.spacing(1),
  },
}))
