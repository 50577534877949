import React, { FC } from 'react'
import { useTranslation } from '@flint/locales'
import { DescriptionCard } from './DescriptionCard'
import { useStyle } from './PropertyDescriptionCard.style'

export const PropertyDescriptionCard: FC<any> = ({ details }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const getCategoryFromDetails = (
    keys: Array<any>,
    isBorders = false,
    path = details
  ) => {
    if (isBorders) {
      return keys.map(({ key, values }) => ({
        key,
        value: values.reduce((acc, curr) => {
          acc[curr.split('_').splice(-2).join('_')] = path[curr]
          return acc
        }, {}),
      }))
    } else {
      return keys.map((key: string) => ({
        key,
        value: details[key],
      }))
    }
  }
  const documentType = getCategoryFromDetails([
    'ownership_doc_type',
    'deed_number',
    'mortgaged',

    'deed_area',
    'restricted',
  ])

  const deedBordersAndLengths = getCategoryFromDetails(
    [
      {
        key: 'north_border',
        values: [
          'deed_north_border_length',
          'deed_north_border_type',
          'deed_north_border_is_winding',
          'deed_north_border_description',
        ],
      },
      {
        key: 'east_border',
        values: [
          'deed_east_border_length',
          'deed_east_border_type',
          'deed_east_border_is_winding',
          'deed_east_border_description',
        ],
      },
      {
        key: 'south_border',
        values: [
          'deed_south_border_length',
          'deed_south_border_type',
          'deed_south_border_is_winding',
          'deed_south_border_description',
        ],
      },
      {
        key: 'west_border',
        values: [
          'deed_west_border_length',
          'deed_west_border_type',
          'deed_west_border_is_winding',
          'deed_west_border_description',
        ],
      },
    ],
    true
  )

  const landLocation = getCategoryFromDetails(['plan_number', 'parcel_number'])

  const nationalAddress = getCategoryFromDetails([
    'building_number',
    'district',
    'city',
    'zip_code',
    'additional_number',
  ])

  const bordersAndLengths = getCategoryFromDetails(
    [
      {
        key: 'north_border',
        values: [
          'north_border_length',
          'north_border_type',
          'north_border_is_winding',
          'north_border_description',
        ],
      },
      {
        key: 'east_border',
        values: [
          'east_border_length',
          'east_border_type',
          'east_border_is_winding',
          'east_border_description',
        ],
      },
      {
        key: 'south_border',
        values: [
          'south_border_length',
          'south_border_type',
          'south_border_is_winding',
          'south_border_description',
        ],
      },
      {
        key: 'west_border',
        values: [
          'west_border_length',
          'west_border_type',
          'west_border_is_winding',
          'west_border_description',
        ],
      },
    ],
    true
  )
  const realestateServices = getCategoryFromDetails([
    'electricity_services',
    'lighting_services',
    'sewage_services',
    'asphalt_services',
    'water_services',
    'telephone_services',
  ])

  const buildingFacts = getCategoryFromDetails([
    'building_number',
    'construction_year',
    'building_type',
    'finishing_type',
    'is_internet_available',
    'are_there_shared_facilities',
    'is_there_tent',
    'is_there_elevator',
    'is_there_gym',
  ])

  const buildingFactsSub = getCategoryFromDetails([
    'is_there_elevator',
    'is_there_gym',
    'is_there_tent',
  ])

  const propertyFacts = getCategoryFromDetails([
    'unit_number',
    'floor_number',
    'unit_area',
    'number_of_rooms',
    'are_there_views',
    'is_unit_more_than_one_floor',
    'is_there_yard',
    'is_there_mezzanine_floor',
    'is_there_basement',
    'is_there_roof',
    'number_of_private_parking',
  ])

  const landSpecs = getCategoryFromDetails([
    'area',
    'is_walled',
    'land_nature',
    'walls_type',
    'ground_level',
    'walls_length',
    'is_electricity_available',
    'is_water_available',
    'annexes_count',
    'is_there_annex',
  ])

  const landSpecsAnnexes = getCategoryFromDetails([
    'use',
    'area',
    'structural_framework',
    'building_condition',
  ])

  const landBuildingSystem = getCategoryFromDetails([
    'main_use',
    'sub_use',
    'system',
    'construction_ratio',
  ])
  const specialSpecs = getCategoryFromDetails([
    'is_rented',
    'is_generating_income',
    'total_income',
  ])
  return (
    <div className={classes.container}>
      <DescriptionCard
        cardTitle="document type"
        details={documentType}
        customStyle={{
          borderBottom: 'none',

          borderRadius: '1rem 1rem 0 0',
        }}
      />
      <DescriptionCard
        cardTitle="deed borders and lengths"
        details={deedBordersAndLengths}
        customStyle={{
          borderRadius: '0 0 1rem 1rem',
        }}
        isBorders
      />
      <DescriptionCard
        cardTitle="location"
        details={landLocation}
        customStyle={{
          marginTop: 20,
          borderBottom: 'none',
          borderRadius: '1rem 1rem 0 0',
        }}
      />

      <DescriptionCard
        cardTitle="national address"
        details={nationalAddress}
        customStyle={{ borderTop: 'none', borderRadius: '0 0 1rem 1rem' }}
      />

      <DescriptionCard
        cardTitle="borders and lengths"
        details={bordersAndLengths}
        customStyle={{ marginBlock: 20 }}
        isBorders
      />
      <DescriptionCard
        cardTitle="real estate services"
        details={realestateServices}
        customStyle={{ marginBottom: 20 }}
      />

      {details.real_estate_type === 'apartment' ? (
        <>
          <DescriptionCard
            cardTitle="building facts"
            details={buildingFacts}
            customStyle={{ borderRadius: '1rem 1rem 0 0' }}
          />
          <DescriptionCard
            cardTitle=""
            details={buildingFactsSub}
            customStyle={{
              borderTop: 'none',
              borderRadius: '0 0 1rem 1rem',
              marginBottom: 20,
            }}
          />
          <DescriptionCard cardTitle="property facts" details={propertyFacts} />
        </>
      ) : (
        <>
          <DescriptionCard
            cardTitle="land specs"
            details={landSpecs}
            customStyle={{
              borderRadius:
                details.annexes?.annexes?.length > 0 ? '1rem 1rem 0 0' : '1rem',
            }}
          />
          {details.annexes?.annexes?.map((el, i) => (
            <DescriptionCard
              details={getCategoryFromDetails(
                ['use', 'area', 'structural_framework', 'building_condition'],
                false,
                details.annexes.annexes[i]
              )}
              customStyle={{
                borderTop: 'none',
                borderRadius:
                  details.annexes.annexes.length - 1 === i
                    ? '0 0 1rem 1rem'
                    : 0,
              }}
            />
          ))}
          <DescriptionCard
            cardTitle="land building System"
            details={landBuildingSystem}
            customStyle={{ marginBlock: 20 }}
          />
        </>
      )}

      <DescriptionCard
        cardTitle="special specs"
        details={specialSpecs}
        customStyle={{ marginBlock: 20 }}
      />
    </div>
  )
}
