import React, { FC } from 'react'
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { DotIcon } from 'assets'

import { useStyle } from './MPropertyDescriptionCard.style'

export const MDescriptionCard: FC<any> = ({
  cardTitle = '',
  details = [],
  customStyle = {},
  isBorders = false,
}) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const headers = ['border', 'length', 'type', 'broken', 'description']
  return (
    <Grid className={classes.root} style={customStyle}>
      <Typography
        className={isBorders ? classes.cardTitleBordered : classes.cardTitle}
      >
        {t(cardTitle)}
      </Typography>
      {isBorders ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                {headers.map((header) => (
                  <TableCell align="left" className={classes.headerCell}>
                    {t(header)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {details.map((row, i) => (
                <TableRow key={i}>
                  {row.map((item, i) => (
                    <TableCell
                      key={i}
                      className={classes.rowCell}
                      align="left"
                      component="th"
                      scope="row"
                    >
                      {t(item)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid container>
          {details.map(({ key, value }) => (
            <>
              <Grid item xs={5} container alignItems="center">
                <Typography className={classes.questionText}>
                  {t(key)}
                </Typography>
              </Grid>
              <Grid container xs={6} alignItems="center" wrap="nowrap">
                <Typography className={classes.answerText} variant="h2">
                  {t(value || 'unavailable')}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

/* <Grid container>
{details.map(({ key, value }) => (
  <Grid item xs={12}>
    <Typography variant="h2">{t(key)}</Typography>
    <Grid container alignItems="center" wrap="nowrap">
      <Grid className={classes.answerIcon}>
        {' '}
        <DotIcon />
      </Grid>

      <Typography className={classes.answerText} variant="h2">
        {t(value || 'unavailable')}
      </Typography>
    </Grid>
  </Grid>
))}
</Grid> */
