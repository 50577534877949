import React from 'react'

import { Typography, Grid, Container, Button } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { ErrorNotFoundImage } from 'assets'
import { useStyle } from './ErrorNotFound.style'

export const ErrorNotFound = () => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Container>
      <div className={classes.centerImage}>
        <img alt="error404" src={ErrorNotFoundImage} />
        <Typography className={classes.notFound}>
          {t('Error_page_not_found')}
        </Typography>
      </div>
    </Container>
  )
}
