import React from 'react'
import clsx from 'clsx'
import { utils } from '@rjsf/core'
import { TextWidget, TextWidgetProps } from '@flint/rjsf'

import { useStyles } from './CustomTextFieldStyles'

export const CustomTextField = (props: TextWidgetProps) => {
  const { uiSchema, label, schema } = props
  const { getDisplayLabel } = utils
  const displayLabel = getDisplayLabel(schema, uiSchema)
  const classes = useStyles()
  return (
    <div
      className={
        uiSchema['ui:grayBackground']
          ? `${clsx(classes.grayBackground)} ${clsx(
              classes.customTextFieldWrapper
            )}`
          : clsx(classes.customTextFieldWrapper)
      }
    >
      <h4 className={clsx(classes.headerTitle)}>
        {displayLabel ? uiSchema['ui:label'] || label || schema.title : false}
      </h4>
      <TextWidget {...props} className={classes.textWidgetBorderRadius} />
    </div>
  )
}
