import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'

import { Typography, Grid, Box } from '@material-ui/core'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Carousel from 'react-material-ui-carousel'
import { useTranslation } from '@flint/locales'
import moment from 'moment'
import { formatPrice } from 'utils/base'
import {
  BedIcon,
  BathIcon,
  StairsIcon,
  SurfaceIcon,
  ImagePlaceholder,
} from 'assets'
import { StatusBar } from 'components/StatusBar'
import { useStyle } from './ImageCard.style'

export const ImageCard: FC<any> = ({ advertise, status, viewRatio }) => {
  const { id } = advertise
  const { t } = useTranslation()

  const apartmentDetails = [
    {
      value: advertise.number_of_rooms,
      icon: <BedIcon />,
    },
    // {
    //   value: advertise.bathrooms_count,
    //   icon: <BathIcon />,
    // },
    {
      value: advertise.floor_number,
      icon: <StairsIcon />,
    },
    {
      value: advertise.unit_area,
      icon: <SurfaceIcon />,
    },
  ]
  const classes = useStyle()

  const [days, setDays] = useState<any>(null)

  const calculateDays = (created_at: any) => {
    const now = moment(new Date())
    const end = moment(created_at)
    const duration = moment.duration(now.diff(end))
    const days = Math.floor(duration.asDays())
    if (days === 0) {
      return 1
    } else return days
  }
  useEffect(() => {
    setDays(calculateDays(advertise.date_added))
  }, [days])

  const handleCardClick = () => {
    window.open(`/advertise-details/${id}`, '_blank')
  }
  return (
    <Grid item xs={viewRatio} className={classes.root}>
      <Box className={classes.card}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.cardHeader}
        >
          {advertise?.photos.length ? (
            <Carousel
              autoPlay={false}
              NextIcon={<NavigateBeforeIcon />}
              PrevIcon={<NavigateNextIcon />}
              navButtonsProps={{
                // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                  padding: '1rem',
                  margin: '0rem 0.5rem 0rem 3rem',
                },
              }}
              indicatorContainerProps={{
                style: {
                  position: 'absolute',
                  zIndex: 4,
                  textAlign: 'center',
                  marginTop: '-3rem', // 5
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: 'rgba(255, 255, 255, 1)', // 2
                },
              }}
              indicatorIconButtonProps={{
                style: {
                  padding: '0px',
                  margin: '0px',
                  color: 'rgba(255, 255, 255, 0.4)',
                  textAlign: 'center',
                },
              }}
            >
              {advertise?.photos?.map(({ url }, index) => (
                <div key={index}>
                  <img
                    alt="test"
                    src={url}
                    className={classes.imageDimansion}
                  />
                  {status && (
                    <StatusBar
                      status={advertise.status}
                      dateAdded={advertise.date_added}
                      adId={advertise.id}
                    />
                  )}
                </div>
              ))}
            </Carousel>
          ) : (
            <div className={classes.imagePosition}>
              <img
                alt="No images added"
                src={ImagePlaceholder}
                className={classes.imageDimansion}
              />

              {status && (
                <StatusBar
                  status={advertise.status}
                  dateAdded={advertise.date_added}
                  adId={advertise.id}
                />
              )}
            </div>
          )}
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.cardFooter}
          onClick={handleCardClick}
        >
          <Grid item container alignItems="center">
            <Grid container md={8} justifyContent="flex-start">
              <Grid
                item
                className={clsx(classes.propertyType, {
                  [classes.saleAd]: advertise.ad_type === 'sale',
                  [classes.rentAd]: advertise.ad_type === 'rent',
                  [classes.investmentAd]: advertise.ad_type === 'investment',
                })}
              >
                {' '}
                <Typography className={classes.adType} align="center" noWrap>
                  {t(advertise?.ad_type)}
                </Typography>
              </Grid>
              <Grid item className={classes.realstateType}>
                {' '}
                <Typography align="center">
                  {t(advertise?.real_estate_type)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container justifyContent="flex-end" md={4}>
              <Typography
                color="secondary"
                className={clsx(classes.date, {
                  [classes.saleAd]: advertise.ad_type === 'sale',
                  [classes.rentAd]: advertise.ad_type === 'rent',
                  [classes.investmentAd]: advertise.ad_type === 'investment',
                })}
              >
                منذ {days} أيام
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            {advertise.ad_type === 'investment' ? (
              <Typography align="center" className={classes.investmentTitle}>
                {t(`${advertise.real_estate_type} for ${advertise.ad_type}`)}
              </Typography>
            ) : (
              <>
                <Typography
                  color={advertise.ad_type === 'sale' ? 'primary' : 'secondary'}
                  align="center"
                  className={classes.priceDetails}
                >
                  {formatPrice(advertise.price || 0)}
                </Typography>
                <Typography> {t(advertise?.price_unit)}</Typography>
              </>
            )}
          </Grid>
          <Grid>
            <Typography className={classes.addressDetails}>
              {advertise.address || t('No address added')}
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.iconsContainer}
          >
            {advertise.real_estate_type === 'apartment' ? (
              <Grid container alignItems="flex-start" item xs={12}>
                {apartmentDetails
                  .filter(({ value }) => value !== null)
                  .map(({ value, icon }, i) => (
                    <Grid
                      key={i}
                      item
                      container
                      xs={4}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid className={classes.cardIcon}>{icon}</Grid>
                      <Grid>
                        <Typography className={classes.iconText} noWrap>
                          {value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Grid container alignItems="flex-start" item xs={12}>
                <Grid item container xs={6} alignItems="center">
                  <Grid className={classes.cardIcon}>
                    <SurfaceIcon />,
                  </Grid>
                  <Grid>
                    <Typography className={classes.iconText}>
                      {advertise.area || t('unavailable')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
