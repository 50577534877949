import moment from 'moment'
import 'moment/locale/ar'

export const formatPrice = (price) => (price ? price.toLocaleString() : null)
export const getAddress = ({ national_address }) => {
  if (!national_address?.district && !national_address?.city) {
    return 'address not exist'
  }

  const { district, city } = national_address

  return district && city ? `${district},${city}` : 'address not exist'
}
export const dateFormat = (date, format = 'LL') => {
  moment.locale('ar')
  return moment(date).format(format)
}
