import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: '24',
    p: 4,
    backgroundColor: theme.palette.common.white,
    padding: '2rem',
    borderRadius: theme.spacing(1),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0.01),
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  confirmationButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(10)}px`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: `${theme.spacing(1.2)}px ${theme.spacing(10)}px`,
    },
  },
  laterButton: {
    backgroundColor: '#9A9A9A',
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(10)}px`,
    '&:hover': {
      backgroundColor: '#9A9A9A',
      color: theme.palette.common.white,
      padding: `${theme.spacing(1.2)}px ${theme.spacing(10)}px`,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: '#CECECE',
    cursor: 'pointer',
  },
  titlePosition: { textAlign: 'center' },
}))
