import React, { useEffect } from 'react'
import {
  FormControl,
  createStyles,
  withStyles,
  Theme,
  InputBase,
  MenuItem,
  Select,
  ClickAwayListener,
  Card,
  CardHeader,
  IconButton,
  Collapse,
  CardContent,
} from '@material-ui/core'
import clsx from 'clsx'

import { useTranslation } from '@flint/locales'
import { ExpandIcon } from 'assets'

import { useStyle } from './SelectMenu.style'

export const SelectMenu = ({ title, subheader, itemSelected, children }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState(false)
  useEffect(() => {
    setExpanded(false)
  }, [itemSelected])
  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        'label + &': {
          marginTop: theme.spacing(3),
        },
      },
      input: {
        borderRadius: 4,
        position: 'relative',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Almarai',
        fontWeight: 700,
        '&:focus': {
          backgroundColor: '#ffffff',
        },
      },
    })
  )(InputBase)
  const handleClickAway = () => {
    setExpanded(false)
  }
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.headerDiv}>
        <Card
          className={clsx(classes.root, {
            [classes.modifyMenuHeight]: expanded,
          })}
          elevation={0}
        >
          <CardHeader
            action={
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandIcon />
              </IconButton>
            }
            className={classes.cardHeader}
            titleTypographyProps={{
              style: {
                color: '#4E4E4E',
                fontFamily: 'Almarai',
                fontWeight: 400,
                fontSize: 14,
                cursor: 'pointer',
                marginBlockEnd: 5,
                marginRight: '15px',
              },
            }}
            subheaderTypographyProps={{
              style: {
                color: '#000000',
                fontFamily: 'Almarai',
                fontWeight: 400,
                fontSize: 14,
                cursor: 'pointer',
              },
            }}
            title={title}
            subheader={subheader}
            onClick={handleExpandClick}
          />

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.collapsContent}>
              {children}
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </ClickAwayListener>
    // <FormControl className={classes.selectContainer}>
    //   <Select
    //     value={selectValue}
    //     onChange={handleChange}
    //     input={<BootstrapInput />}
    //     MenuProps={{
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'left',
    //       },
    //       transformOrigin: {
    //         vertical: 'top',
    //         horizontal: 'left',
    //       },
    //       getContentAnchorEl: null,
    //     }}
    //     IconComponent={SelectIcon}
    //     classes={{
    //       icon: classes.selectIcon,
    //     }}
    //   >
    //     {options.map((option, index) => (
    //       <MenuItem key={index} className={classes.selectOption} value={option}>
    //         {t(option)}
    //       </MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
  )
}
