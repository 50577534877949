import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: 66,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #DFDFDF',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    height: '100%',
    position: 'relative',
    width: '80%',
    zIndex: 8,
  },
  logo: {
    cursor: 'pointer',
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',
    cursor: 'pointer',
  },
  clearDecoration: {
    textDecoration: 'none',
  },
  subTitle: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 400,
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  titleUnverified: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',
    cursor: 'pointer',
    backgroundColor: '#FFFBDC',
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
    '&:hover': {
      backgroundColor: '#FFFBDC',
    },
  },
  profileContainer: {
    display: 'flex',
    paddingBlock: '1rem',
    paddingInline: '1rem 2rem',
    marginInlineStart: '2rem',
    position: 'relative',
    backgroundColor: '#F3F3F3',
  },
  profileIcon: {
    cursor: 'pointer',
    marginTop: 4,
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',

    marginInlineStart: '2rem',
  },
  arrowIcon: {
    margin: 'auto .5rem auto 2.5rem',
    cursor: 'pointer',
  },
  menuContainer: {
    width: '100%',
  },
  selectOption: {
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 16,
    color: '#000000',
    paddingBlock: '.5rem',

    width: '100%',
  },
  logoutOption: {
    borderBottom: 'none',
    color: theme.palette.secondary.main,
  },
  listItem: {
    borderBottom: '1px solid #DFDFDF',
  },
  addAdvertiseButton: {
    textDecoration: 'none',
    marginInlineStart: '2rem',
    paddingInlineStart: '2rem',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    borderInlineStart: '1px solid #DFDFDF',
  },
  userLoggedInDrop: {
    position: 'relative',
    height: '100%',
    marginInline: '1rem ',
  },
  profileTab: { position: 'relative', height: '100%' },
  avatarImage: {
    verticalAlign: 'middle',
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
  },
}))
