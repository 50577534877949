import React from 'react'
import { Grid, Box, Card, CardHeader } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export const MapSkeleton = () => {
  return (
    <Box
      sx={{
        minHeight: 450,
        minWidth: 300,
        marginRight: 0.5,
        mt: 5,

        border: '1px solid #DFDFDF',
        borderRadius: '10px',

        overflow: 'hidden',
      }}
    >
      {' '}
      <Skeleton variant="rectangular" height={750} />
    </Box>
  )
}
