import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: '0.5rem',
  },
  titleLabel: {
    fontWeight: 'bold',
    margin: 0,
    color: theme.palette.common.black,
  },
  widgetContainer: {
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #dfdfdf',
    borderRadius: theme.spacing(1.25),
    backgroundColor: theme.palette.common.white,
  },
  formWrapper: {
    width: '40%',
    minWidth: 120,
    padding: theme.spacing(0, 1),
  },
  title: {
    width: '60%',
    fontSize: 14,
    color: '#16151a',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '16px 12px',
    textOverflow: 'ellipsis',
    borderRight: 'solid 1px #dfdfdf',
  },
}))
