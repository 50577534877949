import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: '318px',
    width: '300px',
    padding: theme.spacing(1),
    transition: 'all ease-in-out .5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  card: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    border: '1px solid #DFDFDF',
    borderRadius: '1rem',
    overflow: 'hidden',
    maxWidth: '300px',
    maxHeight: '318px',
  },
  cardHeader: {
    height: '173px',
    width: '100%',
    borderBottom: 'none',
  },
  cardFooter: {
    padding: '1rem 0.8rem',
    height: '167px',
    position: 'relative',
  },
  priceDetails: {
    marginBlock: theme.spacing(0.25, 0),
    marginInline: theme.spacing(0.5),
    fontFamily: 'Almarai',
    fontWeight: 800,
    fontSize: '2.3rem',
    '& + p': {
      fontFamily: 'Almarai',
      fontWeight: 400,
      fontSize: '1.3rem',
      color: '#000000',
    },
  },

  imageDimansion: { height: '173px', width: '292px' },
  imagePosition: { position: 'relative', height: '173px' },
  propertyType: {
    borderRadius: '5rem',
    padding: theme.spacing(0, 1),
    width: 'fir-content',
    height: '2rem',
  },
  saleAd: {
    backgroundColor: '#F1DFFF',
    color: theme.palette.primary.main,
  },
  rentAd: {
    backgroundColor: '#FFD5E9',
    color: theme.palette.secondary.main,
  },
  investmentAd: {
    backgroundColor: '#DCEAFF',
    color: '#377EE8',
  },
  adType: {
    fontWeight: 700,
    fontSize: '1.2rem',
  },
  date: {
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  realstateType: {
    backgroundColor: '#E9E9E9',
    borderRadius: '5rem',
    padding: theme.spacing(0, 1),
    width: '6rem',
    height: '2rem',
    '& > p': {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    marginLeft: theme.spacing(1),
  },
  investmentTitle: {
    color: '#377EE8',
    fontFamily: 'Almarai',
    fontSize: '1.8rem',
    fontWeight: 800,
  },
  addressDetails: {
    margin: theme.spacing(0, 0.5),
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.2rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  iconsContainer: {
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(0, 3),
  },

  cardIcon: {
    marginInline: theme.spacing(0.5, 1),
  },
  iconText: {
    fontFamily: 'Almarai',
    fontSize: '1.7rem',
    fontWeight: 700,
    color: '#000000',
  },
}))
