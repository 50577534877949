import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 80px - 45px)',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
      width: '100% !important',
    },
  },
  secondStepContainer: {
    display: 'grid',
    // gridTemplateRows: '5% 35%',
    justifyContent: 'center',
    padding: '3rem',
    margin: '1rem 0',
    borderRadius: '1rem',
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  reverseColumnsWithMobile: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  title: {
    fontSize: '1.9rem',
    fontWeight: 'bold',
  },

  staticInfoWrapper: {
    backgroundColor: theme.palette.primary.light,
    padding: '2.3rem',
    borderRadius: '10px',
    color: theme.palette.common.black,
  },

  imageContainer: {
    display: 'grid',
    justifyContent: 'center',
    marginBottom: '2rem',
  },

  imgWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },
  linkStyles: {
    textDecoration: 'underline',
    color: theme.palette.common.black,
  },
  stepWrapper: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hideDividerInSmallerScreen: {
    margin: '2rem 0',
    height: '89%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  regaMargin: { marginTop: '1.5rem' },
}))
