import jsCookies from 'js-cookie'

export const userData = () => {
  const data = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_DOMAIN}:${process.env.REACT_APP_AUTH_WEB_CLIENT_ID}`
    )
  )
  return data?.profile ? data.profile : null
}
export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      sessionStorage.removeItem(
        `oidc.user:${process.env.REACT_APP_AUTH_DOMAIN}:${process.env.REACT_APP_AUTH_WEB_CLIENT_ID}`
      )
      jsCookies.remove('access_token')
      resolve('user logged out')
    }, 5000)
  })
}
