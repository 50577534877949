import { objectDeepMerge, isObject } from '@flint/utils'

export const replaceSharedUISchema = (sharedUISchema: object, uiSchema: any) =>
  Object.entries(uiSchema).reduce((uiSchema: any, [key, schema]: any) => {
    let modifiedSchema = schema
    if (isObject(schema)) {
      modifiedSchema = objectDeepMerge(
        sharedUISchema[schema['ui:sharedSchema']] ?? {},
        replaceSharedUISchema(sharedUISchema, schema) ?? {}
      )

      delete modifiedSchema['ui:sharedSchema']
    }

    return {
      ...uiSchema,
      [key]: modifiedSchema,
    }
  }, {})
