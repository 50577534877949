import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: '24',
    p: 4,
    backgroundColor: theme.palette.common.white,
    padding: '2rem',
    borderRadius: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: '#CECECE',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    margin: theme.spacing(3),
  },
  companyDataWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(3)}px auto`,
    alignItems: 'center',
  },
  comapnyLogoImg: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  companyNameWrapper: {
    width: theme.spacing(32),
  },
  companyName: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '31px',
  },
  contactDetailsWrapper: {
    margin: theme.spacing(3),
  },
  companyPhoneNumber: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '36px',
    textAlign: 'center',
    letterSpacing: 3.2,
  },
  companyEmail: {
    fontSize: 18,
    fontWeight: 400,

    textAlign: 'center',
    letterSpacing: 1.8,
  },
  submitButton: {
    borderRadius: theme.spacing(1),
    padding: 10,
    '& a': {
      fontWeight: 700,
      fontSize: 18,
      color: '#fff',
      textDecoration: 'none',
      width: '100%',
    },
  },
  drawerRoot: {
    '& .MuiPaper-root': {
      borderRadius: '10px 10px 0 0',
      paddingInline: 5,
    },
  },
  drawerCompanyName: {
    fontWeight: 700,
    fontSize: 15,
    paddingBlock: 25,
  },
  drawerPhoneNumber: {
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: 5,
    paddingBlockStart: theme.spacing(2.5),
    '& + p': {
      fontWeight: 400,
      fontSize: 18,
      letterSpacing: 1.8,
      paddingBlock: theme.spacing(0, 2.5),
    },
  },
  actionsButtonContainer: {
    '& > .MuiGrid-root': {
      padding: 10,
      cursor: 'pointer',
      marginInline: 10,
      backgroundColor: theme.palette.secondary.main,
      fontWeight: 700,
      borderRadius: 50,
      boxShadow: '0px 8px 14px 0px rgba(0, 0, 0, 0.3)',
      marginBlockEnd: 30,
      textDecorationLine: 'none',
      '& p , a': {
        fontSize: 14,
        color: '#FFF',
        marginLeft: 12,
        backgroundColor: 'transparent',
        textDecorationLine: 'none',
      },
      '& svg': {
        backgroundColor: 'transparent',
      },
    },
    '& :first-child': {
      backgroundColor: '#25D366',
    },
    '& :last-child': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))
