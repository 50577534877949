import React from 'react'
import clsx from 'clsx'
import { WidgetProps } from '@rjsf/core'
import { SelectWidget } from '../SelectWidget'

import { useStyles } from './CustomSelectStyles'

export const CustomSelectWidget = (props: WidgetProps) => {
  const classes = useStyles()
  const { uiSchema, label, schema } = props
  return (
    <div className={clsx(classes.customSelectFieldWrapper)}>
      <h4 className={clsx(classes.headerTitle)}>
        {uiSchema['ui:label'] || label || schema.title}
      </h4>
      <SelectWidget noLabel {...props} label="" />
    </div>
  )
}
