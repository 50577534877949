import { gql } from '@apollo/client'

// mutation for creating a new advertiser

export const CREATE_ADVERTISER = gql`
  mutation createAdvertiser(
    $advertiserCommunication: AdvertiserCommunicationInput
    $advertiserData: AdvertiserInput!
    $validateAdvertiser: Boolean!
  ) {
    createAdvertiser(
      advertiserCommunication: $advertiserCommunication
      advertiserData: $advertiserData
      validateAdvertiser: $validateAdvertiser
    ) {
      advertiser {
        email
        idNumber
        idType
        phone
      }
    }
  }
`
export const CREATE_ADVERTISE = gql`
  mutation CreateAdvertise($adId: Int!, $advertiseData: CreateAdvertiseInput!) {
    createAdvertise(adId: $adId, advertiseData: $advertiseData) {
      advertise {
        id
        data {
          data
        }
      }
    }
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadAdvertiseImages($attachment: Upload!) {
    uploadAdvertiseImages(attachment: $attachment) {
      url
    }
  }
`
export const UPDATE_AVATAR = gql`
  mutation updateAvatar($attachment: Upload!) {
    updateAvatar(attachment: $attachment) {
      url
    }
  }
`
export const UPDATE_ADVERTISER = gql`
  mutation updateAdvertiser(
    $advertiserCommunication: AdvertiserCommunicationInput
  ) {
    updateAdvertiser(advertiserCommunication: $advertiserCommunication) {
      advertiser {
        email
        id
        idNumber
        idType
        phone
      }
    }
  }
`
export const UPDATE_ADVERTISE = gql`
  mutation updateAdvertise($advertiseData: UpdateAdvertiseInput!) {
    updateAdvertise(advertiseData: $advertiseData) {
      advertise {
        id
        status
      }
    }
  }
`
export const Validate_Advertise = gql`
  mutation validateAdvertise($adNumber: String!) {
    validateAdvertise(adNumber: $adNumber) {
      validationResult {
        adId
        error
        isValid
      }
    }
  }
`
