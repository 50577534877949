import React, { FC, useState } from 'react'
import qs from 'qs'
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Drawer,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { AutoComplete, SelectMenu } from 'components'

import { SearchIcon } from 'assets'
import { useStyle } from './Search.style'

const defaultSearchParams = {
  pageInfo: {
    limit: 20,
    offset: 0,
    orderBy: [{ field: 'date_added', order: 'desc' }],
  },
  filters: {
    bbox: undefined,
    lookups: [
      {
        field: 'real_estate_type',
        clause: 'exact',
        value: 'apartment',
      },
      {
        field: 'ad_type',
        clause: 'exact',
        value: 'sale',
      },
      {
        field: 'price',
        clause: 'gte',
        value: '100',
      },
      {
        field: 'price',
        clause: 'lte',
        value: '90000000',
      },
    ],
  },
}

type SearchProps = {
  onSearch?: Function
  //  loading?: boolean
}

export const Search: FC<SearchProps> = ({ onSearch }: SearchProps) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const [buttonLoading, setButtonLoading] = useState(false)
  const [searchParams, setSearchParams] = useState(defaultSearchParams)
  const realEstateTypes = ['apartment', 'land']
  const [itemSelected, setItemSelected] = useState(false)
  const handleSelectChange = (type: string) => {
    searchParams.filters.lookups[0].value = type
    setSearchParams({ ...searchParams })
    handleClose()
  }
  const handleClose = () => {
    setItemSelected(!itemSelected)
  }
  const handleAdvertiseTypeSelect = (type: string) => {
    searchParams.filters.lookups[1].value = type

    setSearchParams({ ...searchParams })
  }

  const changeLocationHandler = (location: any) => {
    if (!location) {
      // reset bbox filter
      setSearchParams({
        ...searchParams,
        filters: {
          ...searchParams.filters,
          bbox: undefined,
        },
      })
      return
    }

    const { bounds } = location
    const bottomRightLonLat = [bounds[0][1], bounds[0][0]]
    const topLefLonLat = [bounds[1][1], bounds[1][0]]
    const bbox = JSON.stringify([...bottomRightLonLat, ...topLefLonLat])
    setSearchParams({
      ...searchParams,
      filters: {
        ...searchParams.filters,
        bbox: { bbox },
      },
    })
  }

  const handleSearchClick = () => {
    if (buttonLoading) return

    const { pageInfo, filters } = searchParams
    const validatedSearchParams = {
      pageInfo,
      filters: {
        ...filters,
        lookups: filters.lookups.filter(
          ({ value }) => !['', 'property type'].includes(value)
        ),
      },
    }
    setButtonLoading(true)

    navigate(`/search-results?${qs.stringify({ validatedSearchParams })}`)
  }

  return (
    <div className={classes.root}>
      {isMobileScreen ? (
        <Drawer
          className={classes.drawerRoot}
          variant="persistent"
          anchor="bottom"
          open
        >
          <Grid
            container
            direction="column"
            className={classes.drawerContainer}
          >
            <Grid item>
              <Typography className={classes.MobileLabel}>
                {' '}
                {t('search for property')}
              </Typography>
            </Grid>
            <Grid item>
              <SelectMenu
                title={t(searchParams.filters.lookups[0].value)}
                subheader=""
                itemSelected={itemSelected}
              >
                {' '}
                <List className={classes.listContainer}>
                  {realEstateTypes.map((type, index) => (
                    <ListItem
                      className={classes.item}
                      button
                      onClick={() => handleSelectChange(type)}
                      key={index}
                    >
                      <ListItemText disableTypography className={classes.title}>
                        {t(type)}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </SelectMenu>
            </Grid>

            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.middelSectionContainer}
            >
              <Grid item xs={5} className={classes.divider} />
              <Grid xs={2} item>
                <Typography align="center">{t('in')}</Typography>
              </Grid>

              <Grid xs={5} item className={classes.divider} />
            </Grid>

            <Grid item className={classes.mobileSearchInput}>
              <AutoComplete changeLocationHandler={changeLocationHandler} />
            </Grid>

            <Grid
              item
              container
              className={classes.mobileToggleButtonsContainer}
              justifyContent="space-between"
              alignItems="center"
            >
              <div
                className={
                  searchParams.filters.lookups[1].value === 'sale'
                    ? classes.mobileToggleButtonActive
                    : ''
                }
                onClick={() => {
                  handleAdvertiseTypeSelect('sale')
                }}
              >
                {t('for sale')}
              </div>
              <div
                className={
                  searchParams.filters.lookups[1].value === 'rent'
                    ? classes.mobileToggleButtonActive
                    : ''
                }
                onClick={() => {
                  handleAdvertiseTypeSelect('rent')
                }}
              >
                {t('for rent')}
              </div>
              <div
                className={
                  searchParams.filters.lookups[1].value === 'investment'
                    ? classes.mobileToggleButtonActive
                    : ''
                }
                onClick={() => {
                  handleAdvertiseTypeSelect('investment')
                }}
              >
                {t('for investment')}
              </div>
            </Grid>
          </Grid>
          <Grid>
            <Button
              className={classes.mobileButton}
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSearchClick}
              fullWidth
            >
              {buttonLoading ? (
                <CircularProgress size={30} thickness={5} color="secondary" />
              ) : (
                <>
                  <SearchIcon className={classes.mobileIcon} />
                  <span>{t('search')}</span>
                </>
              )}
            </Button>
          </Grid>
        </Drawer>
      ) : (
        <Grid
          container
          className={classes.inputWrapper}
          alignItems="center"
          justifyContent="center"
        >
          <Typography className={classes.label}>
            {t('search for property')}
          </Typography>

          <Grid item md={2} xs={11}>
            <SelectMenu
              title={t(searchParams.filters.lookups[0].value)}
              subheader=""
              itemSelected={itemSelected}
            >
              {' '}
              <List>
                {realEstateTypes.map((type, index) => (
                  <ListItem
                    className={classes.item}
                    button
                    onClick={() => handleSelectChange(type)}
                    key={index}
                  >
                    <ListItemText disableTypography className={classes.title}>
                      {t(type)}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </SelectMenu>
          </Grid>

          <Typography className={classes.label}>{t('in')}</Typography>

          <Grid item md={4} xs={11} className={classes.InputContainer}>
            <AutoComplete changeLocationHandler={changeLocationHandler} />
          </Grid>
          <Grid
            md={3}
            xs={11}
            item
            container
            className={classes.toggleButtonsContainer}
            justifyContent="space-between"
            alignItems="center"
          >
            <div
              className={
                searchParams.filters.lookups[1].value === 'sale'
                  ? classes.toggleButtonActive
                  : ''
              }
              onClick={() => {
                handleAdvertiseTypeSelect('sale')
              }}
            >
              {t('for sale')}
            </div>
            <div
              className={
                searchParams.filters.lookups[1].value === 'rent'
                  ? classes.toggleButtonActive
                  : ''
              }
              onClick={() => {
                handleAdvertiseTypeSelect('rent')
              }}
            >
              {t('for rent')}
            </div>
            <div
              className={
                searchParams.filters.lookups[1].value === 'investment'
                  ? classes.toggleButtonActive
                  : ''
              }
              onClick={() => {
                handleAdvertiseTypeSelect('investment')
              }}
            >
              {t('for investment')}
            </div>
          </Grid>
          <Grid item md={1} sm={3}>
            <Button
              className={classes.button}
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSearchClick}
            >
              {buttonLoading ? (
                <CircularProgress size={30} thickness={5} color="secondary" />
              ) : (
                <>
                  <SearchIcon className={classes.icon} />
                  <span>{t('search')}</span>
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
