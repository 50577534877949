import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '70vh',
    borderRadius: '1rem',
    position: 'relative',
    padding: theme.spacing(0.1),
    [theme.breakpoints.down('xs')]: {
      height: '180px',
      borderRadius: 0,
    },
  },
  activeImage: {
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
  },
  imagePlaceholder: {
    height: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '180px',
      width: '100%',
      minHeight: 0,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },
  },
  thumbnailsContainer: {
    height: '8rem',
    width: '25vw',
    position: 'absolute',
    bottom: '1rem',
    left: '10px',
    cursor: 'pointer',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },

    '& > *': {
      width: '22%',
      borderRadius: '1rem',
      height: '100%',
      marginInlineStart: '8px',
    },
  },
  thumbnail: {
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    objectFit: 'fill',
  },
  carouselRoot: {
    width: '100%',
  },
  imageDimansion: { height: '180px', width: '100%' },
}))
