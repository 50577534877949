import React from 'react'
import {
  Modal,
  Typography,
  Divider,
  Button,
  Drawer,
  useMediaQuery,
  useTheme,
  IconButton,
  Grid,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'

import { TempCompanyLogo, CloseIcon, WhatsAppIcon } from 'assets'

import { useStyles } from './AdvertiserDetailsStyles'

export interface AdvertiserDetails {
  isOpen: boolean
  handleClose: () => void
  avatar: string
  companyName: string
  phoneNumber: string
  email: string
}

export const AdvertiserDetailsModal = ({
  isOpen,
  handleClose,
  avatar,
  companyName,
  phoneNumber,
  email,
}: AdvertiserDetails): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div>
      {isMobileScreen ? (
        <Drawer
          anchor="bottom"
          open={isOpen}
          onClose={handleClose}
          className={classes.drawerRoot}
        >
          <Grid>
            <Grid container justifyContent="space-between">
              <Grid container item xs={2} alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  onClick={handleClose}
                  edge="start"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid>
                <Typography className={classes.title} align="center">
                  {t('contact advertiser')}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid>
              <Typography className={classes.drawerCompanyName} align="center">
                {companyName}
              </Typography>
            </Grid>
            <Divider />
            <Grid>
              <Typography className={classes.drawerPhoneNumber} align="center">
                {phoneNumber}
              </Typography>
              <Typography className={classes.drawerPhoneNumber} align="center">
                {email}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              className={classes.actionsButtonContainer}
            >
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                justifyContent="center"
                component="a"
                href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
              >
                <Typography>{t('whatsapp')}</Typography>
                <WhatsAppIcon />
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                justifyContent="center"
                component="a"
                href={`tel:${phoneNumber}`}
              >
                <Typography component="a">{t('call')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      ) : (
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableAutoFocus
          disableEnforceFocus
        >
          <div className={classes.paper}>
            <Typography className={classes.title}>
              {t('contact advertiser')}
            </Typography>
            <Divider />
            <div className={classes.companyDataWrapper}>
              <div className={classes.companyNameWrapper}>
                <Typography className={classes.companyName} align="center">
                  {companyName ?? ''}
                </Typography>
              </div>
              {/* {avatar && (
                <div>
                  <img
                    src={avatar ?? ''}
                    className={classes.comapnyLogoImg}
                    alt="company-logo"
                  />
                </div>
              )} */}
            </div>
            {phoneNumber && email && <Divider />}

            <div className={classes.contactDetailsWrapper}>
              <Typography className={classes.companyPhoneNumber}>
                {phoneNumber ?? ''}
              </Typography>
              <Typography className={classes.companyEmail}>
                {email ?? ''}
              </Typography>
            </div>
            <Button
              className={classes.submitButton}
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleClose}
            >
              <Typography component="a" href={`tel:${phoneNumber}`}>
                {' '}
                {t('agree')}
              </Typography>{' '}
            </Button>

            <CloseIcon onClick={handleClose} className={classes.closeIcon} />
          </div>
        </Modal>
      )}
    </div>
  )
}
