import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  CodeButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    borderLeft: '1px solid #c4c4c4',
    padding: '2rem',
    paddingLeft: '3rem',
    gap: '1rem',
    '&:hover': {
      borderLeft: '1px solid black',
    },
  },
  biggerArrows: {
    transform: 'scale(1.9)',
  },
}))
