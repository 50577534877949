import React, { FC, useEffect, useState } from 'react'
import { Stepper, Step, StepLabel, Typography } from '@material-ui/core'
import { OlMap } from 'components/OlMap'
import {
  FirstStepMobileActive,
  SecondStepMobile,
  ThirdStepMobileDisabled,
  ForthStepMobileDisabled,
  FifthStepMobileDisabled,
  SixthStepMobileDisabled,
} from 'assets'
import { IRenderStepLabels } from './MPropertyDetailsStepper.interface'

import { useStyle } from './MPropertyDetailsStepper.style'
import { MPropertyDescriptionCard } from '../MPropertyDescriptionCard'

export const MPropertyDetailsStepper: FC<any> = ({ markerCoords, details }) => {
  const classes = useStyle()

  const [activeStep, setActiveStep] = React.useState(0)
  const [stepperWidth, setStepperWidth] = useState(window.innerWidth)

  const updateStepperWidth = () => {
    setStepperWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateStepperWidth)
    return () => window.removeEventListener('resize', updateStepperWidth)
  }, [])

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <MPropertyDescriptionCard details={details} />
      case 1:
        return <OlMap coords={markerCoords} />
      case 2:
        return 'This is the bit I really care about!'
      default:
        return 'Unknown step'
    }
  }
  const steps = [
    {
      label: 'التفاصيل',
      icon: FirstStepMobileActive,
      activeIcon: FirstStepMobileActive,
    },
    {
      label: 'الموقع',
      icon: SecondStepMobile,
      activeIcon: SecondStepMobile,
    },
    {
      label: 'معلومات محلية',
      icon: ThirdStepMobileDisabled,
      activeIcon: '',
    },
    {
      label: 'المخطط',
      icon: ForthStepMobileDisabled,
      activeIcon: '',
    },
    {
      label: 'جولة 3D',
      icon: FifthStepMobileDisabled,
      activeIcon: '',
    },
    {
      label: 'متوسطات الاسعار',
      icon: SixthStepMobileDisabled,
      activeIcon: '',
    },
  ]

  const handleStepClick = (index: number) => {
    if (index > 1) {
      return
    }
    setActiveStep(index)
  }
  const renderIcon = (index: number | string) => {
    const { icon, activeIcon } = steps[index]

    return activeStep === index ? activeIcon : icon
  }
  const renderLabel = ({
    index,

    label,
  }: IRenderStepLabels) => {
    return (
      <div>
        <Typography
          className={index === activeStep ? classes.labelActive : classes.label}
          noWrap
          align="center"
        >
          {label}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        className={classes.stepperContainer}
        style={{ width: `${stepperWidth}px` }}
        activeStep={activeStep}
      >
        {steps.map(({ label }, index) => (
          <Step key={index} onClick={() => handleStepClick(index)}>
            <StepLabel
              className={classes.iconContainer}
              StepIconComponent={renderIcon(index)}
            >
              {renderLabel({ index, label })}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={classes.instructions}>{getStepContent(activeStep)}</div>
    </div>
  )
}
