import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6rem 4rem',
    borderRadius: '1.2rem',
    boxShadow: theme.shadows[3],
  },
  cardContentWrapper: {
    display: 'grid',
    gridTemplateColumns: '90%',
    justifyContent: 'center',
    gridGap: '1.8rem',
    padding: 0,
    alignItems: 'center',
    justifySelf: 'center',
    width: '49rem',
  },
  hideBorders: {
    boxShadow: 'none',
    width: '100%',
    padding: 0,
  },
  hideComponent: {
    display: 'none',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pos: {
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  imgWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },
  cardImg: {
    color: theme.palette.common.black,
  },
  ButtonContainer: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: '2.2rem',
    cursor: 'pointer',
    borderRadius: '0.7rem',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
    fontWeight: 'bold',
  },
  actionsWrapper: {},
}))
