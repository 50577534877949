import { IAdvertiserState } from './advertiser.reducer'

const reducer = 'advertiser'

export const setAdvertiser = (type: keyof IAdvertiserState, payload: any) => ({
  reducer,
  type,
  payload,
})
export const resetAdvertiser = (type: keyof IAdvertiserState) => ({
  reducer,
  type,
  payload: {},
})
