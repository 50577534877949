import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  customTextFieldWrapper: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: theme.spacing(1.5),
  },
  grayBackground: {
    backgroundColor: '#F3F3F3',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.2),
  },
  headerTitle: {
    fontWeight: 400,
    margin: 0,
    color: '#16151A',
  },
  textWidgetBorderRadius: {
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(1.5),
    },
  },
}))
