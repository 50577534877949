import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'

import { Typography, Grid } from '@material-ui/core'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Carousel from 'react-material-ui-carousel'
import { useTranslation } from '@flint/locales'
import moment from 'moment'
import { formatPrice } from 'utils/base'
import {
  MobileBedIcon,
  MobileBathIcon,
  MobileStairsIcon,
  MobileSurfaceIcon,
  ImagePlaceholder,
} from 'assets'
import { useStyle } from './MAdvertiseCard.style'

export const MAdvertiseCard: FC<any> = ({ advertise }) => {
  const { id } = advertise
  const { t } = useTranslation()

  const apartmentDetails = [
    {
      value: advertise.number_of_rooms,
      icon: <MobileBedIcon />,
    },
    // {
    //   value: advertise.bathrooms_count,
    //   icon: <MobileBathIcon />,
    // },
    {
      value: advertise.floor_number,
      icon: <MobileStairsIcon />,
    },
    {
      value: advertise.unit_area,
      icon: <MobileSurfaceIcon />,
    },
  ]
  const classes = useStyle()

  const [days, setDays] = useState<any>(null)

  const calculateDays = (created_at: any) => {
    const now = moment(new Date())
    const end = moment(created_at)
    const duration = moment.duration(now.diff(end))
    const days = Math.floor(duration.asDays())
    if (days === 0) {
      return 1
    } else return days
  }
  useEffect(() => {
    setDays(calculateDays(advertise.date_added))
  }, [days])

  const handleCardClick = () => {
    window.open(`/advertise-details/${id}`, '_blank')
  }
  return (
    <Grid container className={classes.root}>
      <Grid item xs={4}>
        {advertise?.photos.length ? (
          <Carousel
            autoPlay={false}
            NextIcon={<NavigateBeforeIcon />}
            PrevIcon={<NavigateNextIcon />}
            navButtonsProps={{
              style: {
                padding: '1rem',
                margin: '0rem 0.5rem 0rem .5rem',
              },
            }}
            indicatorContainerProps={{
              style: {
                position: 'absolute',
                zIndex: 4,
                textAlign: 'center',
                marginTop: '-3rem', // 5
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: 'rgba(255, 255, 255, 1)', // 2
              },
            }}
            indicatorIconButtonProps={{
              style: {
                padding: '0px',
                margin: '0px',
                color: 'rgba(255, 255, 255, 0.4)',
                textAlign: 'center',
              },
            }}
          >
            {advertise?.photos?.map(({ url }, index) => (
              <div key={index}>
                <img alt="test" src={url} className={classes.imageDimansion} />
              </div>
            ))}
          </Carousel>
        ) : (
          <img
            alt="No images added"
            src={ImagePlaceholder}
            className={classes.imageDimansion}
          />
        )}
      </Grid>
      <Grid className={classes.content} item xs={8} onClick={handleCardClick}>
        <Grid className={classes.cardHeader} container xs={12}>
          <Grid container xs={6} justifyContent="flex-start">
            <Grid
              item
              className={clsx(classes.propertyType, {
                [classes.saleAd]: advertise.ad_type === 'sale',
                [classes.rentAd]: advertise.ad_type === 'rent',
                [classes.investmentAd]: advertise.ad_type === 'investment',
              })}
            >
              {' '}
              <Typography className={classes.adType} align="center" noWrap>
                {t(advertise?.ad_type)}
              </Typography>
            </Grid>
            <Grid item className={classes.realstateType}>
              {' '}
              <Typography align="center">
                {t(advertise?.real_estate_type)}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} item container justifyContent="flex-end">
            <Typography className={classes.date}>منذ {days} أيام</Typography>
          </Grid>
        </Grid>

        <Grid xs={12} item container alignItems="center">
          {advertise.ad_type === 'investment' ? (
            <Typography align="center" className={classes.investmentTitle}>
              {t(`${advertise.real_estate_type} for ${advertise.ad_type}`)}
            </Typography>
          ) : (
            <>
              <Typography
                color={advertise.ad_type === 'sale' ? 'primary' : 'secondary'}
                align="center"
                className={classes.priceDetails}
              >
                {formatPrice(advertise.price || 0)}
              </Typography>
              <Typography> {t(advertise?.price_unit)}</Typography>
            </>
          )}
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.addressDetails}>
            {advertise.address || t('No address added')}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.addressDescription}>
            {advertise.advertisement_text || t('No description added')}
          </Typography>
        </Grid>
        <Grid xs={12} className={classes.iconsContainer} container>
          {advertise.real_estate_type === 'apartment' ? (
            <Grid container item wrap="nowrap">
              {apartmentDetails
                .filter(({ value }) => value !== null)
                .map(({ value, icon }, i) => (
                  <Grid
                    key={i}
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.iconWrapper}
                  >
                    <Grid className={classes.cardIcon}>{icon}</Grid>
                    <Grid>
                      <Typography className={classes.iconText} noWrap>
                        {value}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid container alignItems="flex-start" item>
              <Grid item container xs={6} alignItems="center">
                <Grid className={classes.cardIcon}>
                  <MobileSurfaceIcon />,
                </Grid>
                <Grid>
                  <Typography className={classes.iconText}>
                    {advertise.area || t('unavailable')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
