import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: { overflow: 'hidden', marginBlockStart: 20 },
  cardsContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 230px)',
    padding: theme.spacing(0, 10, 10, 10),
  },
}))
