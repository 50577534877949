import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  selectContainer: {
    backgroundColor: '#ffffff',
    height: 50,
    width: '100%',
    borderRadius: '10px',
    '& > *': {
      height: '100%',
      border: 'none',
    },
  },

  selectOption: {
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 16,
    color: '#000000',
    paddingBlock: '10px',
    border: '1px solid #DFDFDF',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  selectIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginInlineEnd: theme.spacing(1),
  },
  root: {
    width: '100%',

    position: 'absolute',
    border: '1px solid #D6D6D6',
    borderRadius: 10,
  },
  cardHeader: {
    padding: 0,
    height: '50px',
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginRight: '10px',
    marginTop: '6px',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  modifyMenuHeight: {
    //  height: 'auto',
  },
  avatar: {},
  collapsContent: {
    padding: '0 0 !important' as '0',

    borderBlockStart: '1px solid #DFDFDF',
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '50px',
    cursor: 'pointer',
    '&:last-child': {
      marginTop: theme.spacing(1.25),
      fontWeight: 600,
    },
  },
  headerDiv: { position: 'relative', alignSelf: 'center', height: '50px' },
}))
