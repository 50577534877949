import React from 'react'
import { Modal, Typography, Button } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useTranslation } from '@flint/locales'
import { useStyles } from './ConfirmationModalStyles'

export interface ConfirmationModalProps {
  isOpen: boolean
  handleClose: () => void
  handleRedirect: () => void
}

export const ConfirmationModal = ({
  isOpen,
  handleClose,
  handleRedirect,
}: ConfirmationModalProps): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableAutoFocus
      disableEnforceFocus
    >
      <div className={classes.paper}>
        <div className={classes.iconWrapper}>
          <CheckCircleOutlineIcon className={classes.successIcon} />
        </div>
        <Typography variant="h5" className={classes.title}>
          {t("The advertiser's account has been verified and autorized")}
        </Typography>
        <Typography variant="body2" className={classes.subTitle}>
          {t('You can add your ads and share them with others')}
        </Typography>
        <div className={classes.actionsWrapper}>
          <Button
            variant="contained"
            onClick={handleRedirect}
            className={classes.addAnnouncmentButton}
          >
            {t('add advertise')}
          </Button>
          <Button className={classes.escapeButton} onClick={handleClose}>
            {t('Skip')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
