import { getMappedReducers } from '@flint/utils'

import { advertiserReducer } from './advertiser/advertiser.reducer'

export const plainReducers = getMappedReducers({
  prefix: '',
  reducers: {
    advertiser: advertiserReducer,
  },
})
