/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import {
  Tabs,
  Tab,
  Grid,
  Container,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import clsx from 'clsx'
import { useTranslation } from '@flint/locales'
import { useDispatch, useSelector } from 'react-redux'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { RootState } from 'store/reducer'
import { fields } from 'rjsf/fields'
import { widgets } from 'rjsf/widgets'
import { updateAdvertiser } from 'store/advertiser'

import { SquareProfile } from 'assets'
import { useStyles } from './AccountDetails.style'
import {
  firstSchema,
  secondSchema,
  thirdSchema,
  uiSchema,
  uiSchema_mobile,
} from './schemas'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={2}>
          <Typography align="left">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export const AccountDetails = () => {
  const classes = useStyles()
  const { advertiser } = useSelector((state: RootState) => state)
  const user = advertiser?.userDetails
  const advertiserData = advertiser.advertiser
  const [value, setValue] = React.useState(0)
  const [formData, setFormData] = useState(advertiserData)
  const { t } = useTranslation()
  const [editData, setEditData] = useState<any>(true)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()

  const [updatedSchema, setUpdatedSchema] = useState({})

  useEffect(() => {
    const updateMedia = () => {
      if (window.innerWidth > 960) {
        setUpdatedSchema(uiSchema)
      } else {
        setUpdatedSchema(uiSchema_mobile)
      }
    }
    updateMedia()
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  useEffect(() => {
    setFormData(advertiserData)
  }, [advertiserData])

  const handleSubmit = () => {
    const { email, phone } = formData
    const advertiserData = { email, phone }
    const advertiserCommunication = { advertiserCommunication: advertiserData }
    dispatch(updateAdvertiser(advertiserCommunication))
    setEditData(!editData)
  }
  const handleEditData = () => {
    setEditData(!editData)
  }
  const handleCancelButton = () => {
    setEditData(true)
    setFormData(advertiserData)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <div
        className={clsx(classes.title, {
          [classes.titleBack]: isSmallScreen,
        })}
      >
        <p
          className={clsx(classes.title, {
            [classes.titleMobile]: isSmallScreen,
          })}
        >
          {t('account_details')}
        </p>
      </div>

      <Container>
        {!isSmallScreen ? (
          <div className={classes.root}>
            <Grid container direction="column" className={classes.text}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.tabscolumn}
              >
                {user.avatar ? (
                  <img
                    alt="avatar"
                    src={user.avatar}
                    className={classes.squaredAvatar}
                  />
                ) : (
                  <Grid item>
                    <SquareProfile className={classes.squaredAvatar} />
                  </Grid>
                )}

                <Grid item>
                  <Typography
                    className={classes.titletwo}
                  >{`${user.firstName} ${user.lastName}`}</Typography>
                  <Typography>{user.email}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab
                    label={t('account_information')}
                    {...a11yProps(0)}
                    classes={{
                      selected: classes.tabColor,
                      root: classes.tab,
                      wrapper: classes.lableicon,
                    }}
                    icon={<DescriptionOutlinedIcon className={classes.icon} />}
                  />
                </Tabs>
              </Grid>
            </Grid>

            <TabPanel value={value} index={0}>
              <div className={classes.container}>
                <Button
                  href="https://accounts.geotech.run"
                  className={classes.editDataButtonOne}
                >
                  {t('edit_data')}
                </Button>
                <ReactJsonSchemaForm
                  schema={firstSchema as any}
                  formData={user}
                  disabled
                  uiSchema={updatedSchema}
                  setFormData={setFormData}
                  onChange={(e) => {
                    setFormData(e.formData)
                  }}
                  actionButtons={{
                    hiddenButtons: ['submit', 'reset'],
                  }}
                  fields={fields}
                  widgets={widgets}
                />
                <ReactJsonSchemaForm
                  schema={secondSchema as any}
                  formData={advertiserData}
                  uiSchema={updatedSchema}
                  disabled
                  setFormData={setFormData}
                  onChange={(e) => {
                    setFormData(e.formData)
                  }}
                  actionButtons={{
                    hiddenButtons: ['submit', 'reset'],
                  }}
                  fields={fields}
                  widgets={widgets}
                />
                {!editData ? (
                  <div>
                    {' '}
                    <Button
                      onClick={handleSubmit}
                      className={classes.editDataButtonTwo}
                    >
                      حفظ
                    </Button>
                    <Button
                      onClick={handleCancelButton}
                      className={classes.cancelEdit}
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={handleEditData}
                    className={classes.editDataButtonTwo}
                  >
                    {t('edit_data')}
                  </Button>
                )}

                <ReactJsonSchemaForm
                  schema={thirdSchema as any}
                  formData={formData}
                  uiSchema={updatedSchema}
                  disabled={editData}
                  setFormData={setFormData}
                  formContext={setFormData}
                  onChange={(e) => {
                    setFormData(e.formData)
                  }}
                  onSubmit={handleSubmit}
                  actionButtons={{
                    hiddenButtons: ['submit', 'reset'],
                  }}
                  fields={fields}
                  widgets={widgets}
                />
              </div>
            </TabPanel>
          </div>
        ) : (
          <div>
            {' '}
            <div>
              <ReactJsonSchemaForm
                schema={firstSchema as any}
                formData={user}
                disabled
                uiSchema={updatedSchema}
                setFormData={setFormData}
                onChange={(e) => {
                  setFormData(e.formData)
                }}
                actionButtons={{
                  hiddenButtons: ['submit', 'reset'],
                }}
                fields={fields}
                widgets={widgets}
              />
              <ReactJsonSchemaForm
                schema={secondSchema as any}
                formData={advertiserData}
                uiSchema={updatedSchema}
                disabled
                setFormData={setFormData}
                onChange={(e) => {
                  setFormData(e.formData)
                }}
                actionButtons={{
                  hiddenButtons: ['submit', 'reset'],
                }}
                fields={fields}
                widgets={widgets}
              />
              <Button
                href="https://accounts.geotech.run"
                className={classes.editDataButtonOneMobile}
              >
                {t('edit_data')}
              </Button>

              <ReactJsonSchemaForm
                schema={thirdSchema as any}
                formData={formData}
                uiSchema={updatedSchema}
                disabled={editData}
                setFormData={setFormData}
                formContext={setFormData}
                onChange={(e) => {
                  setFormData(e.formData)
                }}
                onSubmit={handleSubmit}
                actionButtons={{
                  hiddenButtons: ['submit', 'reset'],
                }}
                fields={fields}
                widgets={widgets}
              />
              {!editData ? (
                <div className={classes.editButtonsContainer}>
                  {' '}
                  <Button
                    onClick={handleSubmit}
                    className={classes.editDatabuttonSaveMobile}
                  >
                    حفظ
                  </Button>
                  <Button
                    onClick={handleCancelButton}
                    className={classes.cancelEditMobile}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={handleEditData}
                  className={classes.editDataButtonTwoMobile}
                >
                  {t('edit_data')}
                </Button>
              )}
            </div>{' '}
          </div>
        )}
      </Container>
    </>
  )
}
