import React, { useTransition } from 'react'
import qs from 'qs'
import {
  Modal,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Drawer,
} from '@material-ui/core'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from '@flint/locales'
import { Profile, InfoIcon, CloseIcon, LoginOrRegister } from 'assets'

import { useStyles } from './WarningStyles'

export interface WarningModalProps {
  isOpen: boolean
  handleClose: () => void
  warningMessage: string
}
export const WarningModal = ({
  isOpen,
  handleClose,
  warningMessage,
}: WarningModalProps): React.ReactElement => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleLogin = () => {
    navigate(
      `/login?${qs.stringify({
        redirectUrl: `${location.pathname}${location.search}`,
      })}`
    )
  }
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <div>
      {isMobileScreen ? (
        <Drawer
          anchor="bottom"
          open={isOpen}
          onClose={handleClose}
          className={classes.drawerRoot}
        >
          <Grid>
            <Grid container justifyContent="space-between">
              <Grid container item xs={3} alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  onClick={handleClose}
                  edge="start"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid container justifyContent="center">
                <InfoIcon />
              </Grid>
              <Grid container justifyContent="center">
                <Typography className={classes.drawerTitle} align="center">
                  {warningMessage}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="center"
                className={classes.actionButton}
                onClick={handleLogin}
              >
                <LoginOrRegister />
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      ) : (
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="verify-later"
          aria-describedby="verify-later"
          disableAutoFocus
          disableEnforceFocus
        >
          <div className={classes.paper}>
            <div className={classes.warningIconWrapper}>
              <ErrorOutlineIcon className={classes.warningIcon} />
            </div>
            <div>
              <Typography variant="h2" className={classes.warningMsgStyle}>
                {warningMessage}
              </Typography>
            </div>
            <div className={classes.ProfilerWrapper}>
              <div className={classes.profileContainer}>
                <Profile className={classes.profileIcon} />
                <div className={classes.labelsContainer} onClick={handleLogin}>
                  <Typography className={classes.title}>
                    {t('login')}
                  </Typography>
                  <Typography className={classes.title}>
                    {t('or sign up')}{' '}
                  </Typography>
                </div>
              </div>
            </div>

            <CloseIcon onClick={handleClose} className={classes.closeIcon} />
          </div>
        </Modal>
      )}
    </div>
  )
}
