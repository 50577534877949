import React from 'react'
import clsx from 'clsx'
import { utils } from '@rjsf/core'
import { TextWidget, TextWidgetProps } from '@flint/rjsf'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { useStyles } from './CustomTextFieldAccountDetailsStyles'
import { TextWidgetWithoutLabel } from '../TextWidgetWithoutLabel'

export const CustomTextFieldAccountDetails = (props: TextWidgetProps) => {
  const { uiSchema, label, schema } = props
  const { getDisplayLabel } = utils
  const displayLabel = getDisplayLabel(schema, uiSchema)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  return (
    <div
      className={
        uiSchema['ui:grayBackground']
          ? `${clsx(classes.grayBackground)} ${clsx(
              classes.customTextFieldWrapper
            )}`
          : clsx(classes.customTextFieldWrapper)
      }
    >
      {!isSmallScreen ? (
        <>
          <h4 className={clsx(classes.headerTitle)}>
            {displayLabel
              ? uiSchema['ui:label'] || label || schema.title
              : false}
          </h4>
          <TextWidgetWithoutLabel
            {...props}
            className={classes.textWidgetBorderRadius}
          />
        </>
      ) : (
        <TextWidgetWithoutLabel
          {...props}
          className={classes.textWidgetBorderRadius}
        />
      )}
    </div>
  )
}
