import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  makanItemsContainer: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2, 2, 2),
  },
  sectionTitle: {
    color: 'inherit',
    fontSize: '18px',
  },
  fieldsContainer: {
    paddingTop: theme.spacing(0),
  },
  fieldItem: {
    marginBottom: theme.spacing(1.25),
  },
  schemaTitle: { padding: 0 },
}))
