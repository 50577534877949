import { useState, useEffect } from 'react'
import { Theme as MaterialUITheme } from '@rjsf/material-ui'
import { withTheme, FormProps } from '@rjsf/core'
import {
  ActionButtons,
  ActionButtonsProps,
  ArrayFieldTemplate,
  ErrorListTemplate,
  fields,
  FieldTemplate,
  ObjectFieldTemplate,
  widgets,
} from '@flint/rjsf'

import { replaceSharedUISchema } from './helpers'
import { SharedUISchemas } from './SharedUISchemas'

const FormSchema = withTheme(MaterialUITheme)

export interface CustomJsonFormProps extends FormProps<any> {
  setFormData: (data: any) => any | void
  actionButtons?: ActionButtonsProps
}

export const CustomJsonFormSchema = ({
  widgets: customWidgets,
  fields: customFields,
  setFormData,
  formData: _formData,
  uiSchema: uiSchemaWithSharedSchemas,
  schema,
  actionButtons,
  children,
  ...props
}) => {
  const getModifiedFormContext = (formData: any) => ({
    allFormData: formData,
    updateFormData: (data: any) =>
      setFormData({
        ...formData,
        ...data,
      }),
  })
  const [modifiedFormContext, setModifiedFormContext] = useState(
    getModifiedFormContext(_formData)
  )

  const onChangeForm = ({ formData }: any) => {
    setFormData(formData)
    setModifiedFormContext(getModifiedFormContext(formData))
  }

  return (
    <FormSchema
      schema={schema}
      customFormats={{
        'data-url': '',
      }}
      widgets={{ ...widgets, ...customWidgets }}
      fields={{ ...fields, ...customFields }}
      ObjectFieldTemplate={ObjectFieldTemplate}
      ArrayFieldTemplate={ArrayFieldTemplate}
      FieldTemplate={FieldTemplate}
      ErrorList={ErrorListTemplate}
      onChange={onChangeForm}
      uiSchema={replaceSharedUISchema(
        SharedUISchemas,
        uiSchemaWithSharedSchemas
      )}
      formData={_formData}
      {...props}
    >
      {children || <ActionButtons {...actionButtons} formData={_formData} />}
    </FormSchema>
  )
}
