export type InputType =
  | 'idType'
  | 'idNumber'
  | 'email'
  | 'phone'
  | 'organizationNumber'

export type FormValidation = 'email' | 'required'

export interface HandleVerify<T> {
  value: T
  verification: FormValidation
  inputType: InputType
}

export interface FormData {
  advertiserCommunication?: {
    email?: string
    phone?: number
  }
  idNumber?: string
  idType?: string
}

export interface OrgFormData {
  advertiserCommunication?: {
    email?: string
    phone?: number
  }
  organizationNumber?: string
  idType?: string
}

export interface SecondStepErrors {
  idType?: {
    __errors: string[]
  }
  idNumber?: {
    __errors: string[]
  }
  // advertiserNumber?: {
  //   __errors: string[]
  // }
  advertiserCommunication?: {
    email?: {
      __errors: string[]
    }
    phone?: {
      __errors: string[]
    }
  }
}

export const GetSecondStepErrors = (
  errorMsg: string | string[],
  inputType: InputType
) => {
  return {
    [inputType]: {
      __errors: typeof errorMsg === 'string' ? [errorMsg] : errorMsg,
    },
  }
}

export const handleVerify = <T>({
  verification,
  value,
  inputType,
}: HandleVerify<T>) => {
  let errorMessage = '' || {}
  switch (verification) {
    case 'email':
      errorMessage =
        typeof value === 'string' &&
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? ''
          : GetSecondStepErrors('Please enter a valid email', inputType)
      break
    case 'required':
      errorMessage =
        (typeof value === 'string' || Array.isArray(value)) && value.length > 0
          ? ''
          : GetSecondStepErrors('This field is required', inputType)
      break
    default:
      break
  }

  return errorMessage as {
    [K in InputType]: {
      __errors: string[]
    }
  }
}
