import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  customTextFieldWrapper: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: '0.5rem',
  },
  root: {},
  radioGroup: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '1rem',
  },
  headerTitle: {
    fontWeight: 'bold',
    margin: 0,
    color: theme.palette.common.black,
  },
  radioLabel: {
    fontSize: '1.4rem',
  },
  radioItem: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    flexGrow: 1,
    maxWidth: '100%',
    border: 'solid 1px #e0e0e0',
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1.2, 1.25),
    margin: `0 ${theme.spacing(0.5)}px`,
    '&:nth-child(odd)': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:nth-child(even)': {
      marginRight: 0,
    },
    '&.selectedRadioItem': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))
