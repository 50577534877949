import React from 'react'

import PinDropIcon from '@material-ui/icons/PinDrop'
import ApartmentIcon from '@material-ui/icons/Apartment'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import HomeIcon from '@material-ui/icons/Home'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { useStyle } from './CustomRadioComponentStyles'

export type Adornments = 'ارض' | 'عمارة' | 'شقة' | 'فيلا' | 'دور'

export const StyledAdornment = { padding: '8px', color: '#652943' }

export const GetAdornment = (a: Adornments) => {
  const classes = useStyle()
  switch (a) {
    case 'ارض':
      return <PinDropIcon className={classes.iconFont} />

    case 'عمارة':
      return <ApartmentIcon className={classes.iconFont} />

    case 'شقة':
      return <HomeIcon className={classes.iconFont} />

    case 'فيلا':
      return <EmojiTransportationIcon className={classes.iconFont} />

    case 'دور':
      return <LocationCityIcon className={classes.iconFont} />

    default:
      return <AccountBalanceIcon className={classes.iconFont} />
  }
}
