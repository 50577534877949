import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  fieldsContainer: {
    paddingTop: theme.spacing(1.5),
  },
  fieldItem: {
    marginBottom: theme.spacing(1.25),
  },
  makanItemsContainer: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2, 2, 2),
  },
  makanActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1.5),
  },
  makanInquiryService: {
    display: 'flex',
    color: '#005f96',
    fontWeight: 'bold',
    alignItems: 'center',
    fontSize: theme.spacing(1.5),
  },
  makanServiceTitle: {
    marginRight: theme.spacing(2),
  },
  howToGetTheNumber: { padding: '0', textDecoration: 'underline' },
  regaLink: {
    textDecoration: 'none',
    color: '#662D91',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  clickHere: {
    fontSize: 'larger',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleColor: { color: '#662D91' },
}))
export const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
}))
