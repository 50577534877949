import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  bar: {
    width: '100%',
    height: '57px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '30rem',
    },
  },
  filtersContainer: {
    border: '1px solid #D6D6D6',
    borderRadius: '1rem',
    height: '57px',
  },
  listItem: {
    borderBottom: '1px solid #DFDFDF',
  },
  selectOption: {
    fontFamily: 'Almarai',
    fontWeight: 400,
    fontSize: '1.2rem',
    color: '#000000',
    paddingBlock: '5px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  button: {
    width: '100%',
    height: 55,
    paddingBottom: 1,
    alignSelf: 'stretch',
    background: '#662D91 0% 0% no-repeat padding-box',
    borderRadius: '0 1rem 1rem 0',
    fontSize: '1.6rem',
    outline: 'none',
    border: 'none',
    color: 'white',
    fontWeight: 700,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    margin: '0 10px',
  },
  dropDownSearchBar: {
    position: 'relative',
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      height: '5rem',
      borderLeft: 'none',
      marginBottom: '2rem',
    },
  },
  divider: { height: 35 },
  addsCount: {
    color: theme.palette.primary.main,
    fontSize: '1.6rem',
    fontWeight: 800,
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000000',
    marginLeft: theme.spacing(1.5),
  },
  drawerRoot: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: '10px 10px 0 0',
    },
  },
  drawerContainer: {
    paddingInline: theme.spacing(2),
    direction: 'ltr',
  },
  drawerTitle: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    margin: theme.spacing(3, 0),
  },
  mobileToggleButtonsContainer: {
    backgroundColor: '#F1F1F1',
    marginBlock: theme.spacing(4),
    height: '50px',
    borderRadius: 10,
    color: '#676767',
    fontSize: 14,
    '& > *': {
      width: '33%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 0,
      cursor: 'pointer',
      transition: 'all .5s ease-in-out',
      fontFamily: 'Almarai',
      fontWeight: 700,
    },
    '& :first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& :last-child': {
      borderRadius: '0 10px 10px 0',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  mobileToggleButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  mobileButton: {
    height: 62,
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 18,
    cursor: 'pointer',
  },
  displayStatusContainer: {
    marginBlockEnd: theme.spacing(2.5),
  },
}))
