import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    marginBlock: '4rem',
    border: '0.1rem solid #DFDFDF',
    borderRadius: '1rem',
    color: 'black',
    minwidth: '63.8rem',
  },
  title: {
    fontSize: '2.5rem',
    marginLeft: '6.5rem',
    color: 'black',
  },
  tabs: {
    paddingTop: '0.3rem',
    borderTop: '0.1rem solid #DFDFDF',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '16.5rem',
      alignItems: 'center',
    },
  },
  tab: {
    maxWidth: '33.5rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '16.5rem',
      alignItems: 'center',
    },
  },

  text: {
    maxWidth: '33.5rem',
    borderRight: '0.1rem solid #DFDFDF',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '16.5rem',
    },
  },
  tabscolumn: {
    marginTop: '2rem',
    marginBottom: '2.2rem ',
  },
  titletwo: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',
    cursor: 'pointer',
  },
  list: {
    color: 'black',
    fontSize: '1.2rem',
  },

  tabColor: { backgroundColor: 'rgba(102, 45, 145, 1)', color: 'white' },
  editDataButtonOne: {
    position: 'relative',
    right: '0rem',
    left: '67rem',
    top: '4.5rem',
    bottom: '0rem',
    zIndex: 100,
    fontSize: '1.4rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      right: '0rem',
      left: '29rem',
      top: '5.5rem',
      bottom: '0rem',
    },
  },
  editDataButtonTwo: {
    position: 'relative',
    right: '0rem  ',
    left: '68rem',
    top: '4.5rem',
    bottom: '0rem',
    zIndex: 100,
    fontSize: '1.4rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      right: '0rem',
      left: '29rem',
      top: '5.5rem',
      bottom: '0rem',
    },
  },
  squaredAvatar: {
    width: '8rem ',
    height: '8rem',
    verticalAlign: 'middle',
    objectFit: 'fill',
    marginRight: '1.9rem',
  },
  container: {
    width: '80rem',
    margin: '1rem auto',
    [theme.breakpoints.down('sm')]: {
      width: '40rem',
    },
  },
  lableicon: {
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: '1.4rem',
    fontWeight: 800,
    alignItems: 'flex-start',
    marginRight: '15rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem',
    },
  },
  icon: { fontSize: '2.5rem', marginRight: '2rem' },
  cancelEdit: {
    position: 'relative',
    right: '0rem  ',
    left: '68rem',
    top: '4.5rem',
    bottom: '0rem',
    zIndex: 100,
    fontSize: '1.4rem',
    fontWeight: 700,
    color: '#D80000',
    [theme.breakpoints.down('sm')]: {
      right: '0rem',
      left: '29rem',
      top: '5.5rem',
      bottom: '0rem',
    },
  },
  editDataButtonOneMobile: {
    width: '100%',
    height: '5.3rem',
    borderRadius: '1rem',
    background: '#662D91',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 700,
    '&:hover': {
      background: '#662D91',
    },
  },
  editDatabuttonSaveMobile: {
    width: '16.2159rem',
    height: '5.3986rem',
    borderRadius: '1rem',
    background: '#662D91',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 700,
    '&:hover': {
      background: '#662D91',
    },
  },
  cancelEditMobile: {
    width: '16.2159rem',
    height: '5.3986rem',
    borderRadius: '1rem',
    background: '#E1E1E1',
    color: '#626262',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 700,
    '&:hover': {
      background: '#E1E1E1',
    },
  },
  editButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '3.65rem',
  },
  editDataButtonTwoMobile: {
    width: '100%',
    height: '5.3rem',
    borderRadius: '1rem',
    background: '#662D91',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 700,
    marginBottom: '3.65rem',
    '&:hover': {
      background: '#662D91',
    },
  },
  titleMobile: {
    color: '#662D91',
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: 700,
    padding: '0 !important',
    margin: '0 !important',
  },
  titleBack: {
    width: '100%',
    height: '6.3341rem',
    padding: '0 !important',
    margin: '0 !important',
    background: '#FAF4FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
