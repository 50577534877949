import { FC } from 'react'
import { CustomRadioComponent } from './CutomRadioComponent'
import { RadioWidgetWithTitle } from './RadioComponentWithTitle'
import { DaalSelectWidget } from './DaalSelectWidget'
import { CustomTextArea } from './CustomTextArea'
import { CustomTextField } from './CustomTextField'
import { CustomSelectWidget } from './CustomSelectWidget'
import { TermsAndConditionsWidget } from './TermsAndConditionsWidget'
import { SideBySideSelector } from './SideBySideSelector'
import { PhoneInput } from './PhoneInput'
import { SelectWidget } from './SelectWidget'
import { FileWidget } from './FileWidget'
import { CustomYesNoWidget } from './CustomYesNoWidget'
import { CustomCheckboxWidget } from './CustomCheckboxWidget'
import { SavedAdvertiseWidget } from './WidgetSavedAdvertise'
import { CustomTextFieldAccountDetails } from './CustomTextFieldAccountDetails'

export * from './CutomRadioComponent'
export * from './RadioComponentWithTitle'
export * from './DaalSelectWidget'
export * from './CustomTextArea'
export * from './CustomTextField'
export * from './CustomSelectWidget'
export * from './TermsAndConditionsWidget'
export * from './SideBySideSelector'
export * from './PhoneInput'
export * from './SelectWidget'
export * from './FileWidget'
export * from './CustomYesNoWidget'
export * from './CustomCheckboxWidget'
export * from './WidgetSavedAdvertise'
export * from './CustomTextFieldAccountDetails'

export const widgets: Record<string, FC> = {
  CustomRadioComponent,
  RadioWidgetWithTitle,
  DaalSelectWidget,
  CustomTextArea,
  CustomTextField,
  CustomSelectWidget,
  TermsAndConditionsWidget,
  SideBySideSelector,
  PhoneInput,
  SelectWidget,
  FileWidget,
  CustomYesNoWidget,
  CustomCheckboxWidget,
  SavedAdvertiseWidget,
  CustomTextFieldAccountDetails,
}
