import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  container: {
    // border: '1px solid #DFDFDF',
    // borderRadius: '1rem',
    // '& :last-child': { borderBottom: 0 },
  },
  root: {
    padding: theme.spacing(2),
    border: '1px solid #DFDFDF',
    borderRadius: '1rem',
    // marginTop: theme.spacing(2),
  },
  listItem: {
    paddingBlock: theme.spacing(1),
    '&::after': {
      content: '',
      height: '1rem',
      width: '100%',
      backgroundColor: 'red',
      color: 'red',
    },
  },
  answerText: {
    paddingBlock: theme.spacing(0, 1),
    paddingInline: theme.spacing(1),
    fontFamily: 'Almarai',
    fontSize: '1.8rem',
    fontWeight: 400,
  },
  answerIcon: {
    marginTop: theme.spacing(-0.5),
  },

  listIcon: {
    marginInlineEnd: theme.spacing(1),
  },
  borderContainer: { marginBottom: 40 },
}))
