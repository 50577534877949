import React, { FC, useEffect } from 'react'
import { LoadingProgress } from '@flint/core'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

export const Login: FC = () => {
  const { '?redirectUrl': redirectUrl }: any = qs.parse(window.location.search)
  setTimeout(() => {
    localStorage.setItem('redirectUrl', redirectUrl)
  }, 1000)

  return <LoadingProgress loading />
}
