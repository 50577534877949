import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  countryWrapper: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(1)}px 0`,
    display: 'flex',
    gap: theme.spacing(1),
    fontSize: '1.2rem',
    borderleft: '1px solid black',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))
