import React from 'react'
import { RadioGroup, Typography } from '@material-ui/core'
import { WidgetProps } from '@rjsf/core'

import { useStyle } from './CustomRadioComponentStyles'
import { Adornments, GetAdornment } from './CustomRadioOptionsAdornment'

export const CustomRadioComponent = ({
  id,
  schema,
  options,
  value,
  disabled,
  label,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps) => {
  const classes = useStyle()
  const { enumOptions, enumDisabled } = options

  const _onChange = (value: string) => {
    if (disabled) return
    return onChange(schema.type === 'boolean' ? value !== 'false' : value)
  }

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value)
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value)

  const row = options ? options.inline : false
  return (
    <>
      {(label || schema.title) && (
        <h4 className={classes.headerTitle}>{label || schema.title}</h4>
      )}

      <RadioGroup
        value={value}
        row={row as boolean}
        // onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        className={classes.radioGroup}
      >
        {(enumOptions as any).map((option: any, i: number) => {
          const itemDisabled =
            enumDisabled && (enumDisabled as any).indexOf(option.value) !== -1
          return (
            <div
              key={i}
              onClick={() => _onChange(option.value)}
              className={`${classes.radioItem} ${
                option.value === value ? classes.selected : ''
              } ${disabled ? classes.disabledItem : ''}`}
            >
              <div className={classes.option}>
                {GetAdornment(option.label as Adornments)}
              </div>
              <div>
                <Typography variant="body2" className={classes.optionLabel}>
                  {option?.label ?? ''}
                </Typography>
              </div>
            </div>
          )
        })}
      </RadioGroup>
    </>
  )
}
