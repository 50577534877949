import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { CardSkeleton, ImageCard, NoSearchAds } from 'components'
import { useStyle } from './SearchResultsCardsList.style'

export const SearchResultsCardsList: FC<any> = ({
  viewRatio = 4,
  advertises = [],
  advertisesLoader,
}) => {
  const isMyAdsPage = location?.pathname === '/my-adds'
  const classes = useStyle()

  const renderList = () => {
    if (advertisesLoader) {
      return Array.from(new Array(20)).map((item, index) => (
        <Grid
          className={classes.cardRoot}
          key={index}
          item
          xs={12}
          sm={6}
          md={viewRatio}
        >
          <CardSkeleton />
        </Grid>
      ))
    } else if (advertises === null || advertises?.length === 0) {
      return <NoSearchAds />
    } else if (isMyAdsPage) {
      return advertises.map(({ data, id }, i) => (
        <ImageCard
          key={id}
          advertise={{ ...data, id }}
          status
          viewRatio={viewRatio}
        />
      ))
    } else {
      return advertises.map(({ data, id }, i) => (
        <ImageCard key={id} advertise={{ ...data, id }} viewRatio={viewRatio} />
      ))
    }
  }
  return (
    <Grid container className={classes.root}>
      {renderList()}
    </Grid>
  )
}
