import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from '@flint/locales'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'

import { useStyles } from './AddPropertyStepperStyles'
import {
  ColorlibConnector,
  ColorlibStepIcon,
  QontoStepIcon,
} from './StepperConnector'

function getSteps() {
  return [
    {
      label: 'المعلن',
      domId: '#advertiser',
    },
    {
      label: 'الاعلان',
      domId: '#advertisement',
    },
    { label: 'العقار', domId: '#realestate' },
  ]
}

export const StepperComponent = (): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = React.useState(0)
  const [activeSubStep, setActiveSubStep] = React.useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={`hide-at-mobile ${classes.root}`}>
      <Stepper
        className={classes.stepperColor}
        activeStep={activeStep}
        orientation="vertical"
        connector={<ColorlibConnector />}
      >
        {steps.map((data, index) => (
          <Step
            key={index}
            onClick={() => {
              const link = document.createElement('a')
              link.href = data.domId
              link.style.cursor = 'pointer'
              link.click()
              setActiveStep(index)
              if (index !== 2) setActiveSubStep(0)
            }}
            className={classes.stepCursor}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {data.label}
            </StepLabel>{' '}
          </Step>
        ))}
        <Step
          style={{ marginRight: '0.7rem' }}
          active={activeSubStep === 1}
          onClick={() => {
            setActiveStep(2)
            const link = document.createElement('a')
            link.href = '#realestate_land'
            link.style.cursor = 'pointer'
            link.click()
            setActiveSubStep(1)
          }}
        >
          <StepLabel
            className={classes.stepCursor}
            StepIconComponent={QontoStepIcon}
          >
            {t('Real estate facts')}
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  )
}
