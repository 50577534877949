import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  bar: {
    width: '100%',
    height: '57px',
    border: '1px solid #D6D6D6',
    borderRadius: '1rem',
    display: 'flex',

    justifyContent: 'space-between',
    alignItems: 'center',
    marginBlock: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      height: '30rem',
    },
  },
  districtName: {
    backgroundColor: '#F6F6F6',
    height: '100%',
    borderRight: '1px solid #DFDFDF',
    borderBottom: '1px solid #DFDFDF',
    borderRadius: '1rem  0 0 1rem',
    paddingInlineStart: 10,
    '& :first-child': {
      color: theme.palette.secondary.main,
      fontSize: '1.4rem',
      fontWeight: 700,
    },
  },

  filtersContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginTop: '2rem',
    },
  },

  listItem: {
    borderBottom: '1px solid #DFDFDF',
  },
  selectOption: {
    fontFamily: 'Almarai',
    fontWeight: 400,
    fontSize: '1.2rem',
    color: '#000000',
    paddingBlock: '5px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  button: {
    width: '100%',
    height: 55,
    paddingBottom: 1,
    alignSelf: 'stretch',
    background: '#662D91 0% 0% no-repeat padding-box',
    borderRadius: '0 1rem 1rem 0',
    fontSize: '1.6rem',
    outline: 'none',
    border: 'none',
    color: 'white',
    fontWeight: 700,
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    margin: '0 10px',
  },
  dropDownSearchBar: {
    position: 'relative',

    width: '95%',
    [theme.breakpoints.down('sm')]: {
      height: '5rem',
      borderLeft: 'none',
      marginBottom: '2rem',
    },
  },
  divider: { height: 35 },
  searchDrawerdivider: {
    height: '1px',
    backgroundColor: '#CDCDCD',
    width: '100%',
  },
  addsCount: {
    color: '#525252',
    fontSize: '1.2rem',
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
  drawerRoot: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: '10px 10px 0 0',
    },
  },
  drawerTitle: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    margin: theme.spacing(3, 0),
  },
  mobileToggleButtonsContainer: {
    backgroundColor: '#F1F1F1',
    marginBlock: theme.spacing(2.5),
    height: '50px',
    borderRadius: 10,
    color: '#676767',
    fontSize: 14,
    '& > *': {
      width: '33%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 0,
      cursor: 'pointer',
      transition: 'all .5s ease-in-out',
      fontFamily: 'Almarai',
      fontWeight: 700,
    },
    '& :first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& :last-child': {
      borderRadius: '0 10px 10px 0',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  mobileToggleButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  mobileButton: {
    height: 62,

    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight: 700,
    fontSize: 18,
    cursor: 'pointer',
  },
  drawerContainer: {
    paddingInline: theme.spacing(2),
    direction: 'ltr',
  },
  inputWrapper: {
    marginBlock: theme.spacing(2),
    height: 50,
    borderRadius: 10,
    border: '1px solid #C6C6C6',
    padding: theme.spacing(0.5),
    '& input': {
      flexGrow: 1,
      height: '100%',
      background: '#fff',
      fontSize: 14,
      opacity: 1,
      outline: 'none',
      border: 'none',
      padding: '0 10px',
      fontFamily: 'Almarai',

      '&::placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#4E4E4E',
      },
    },
    '& span': {
      color: '#4E4E4E',
      fontSize: 14,
      marginInline: 10,
      fontWeight: 400,
    },
  },
  MobileLabel: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'Almarai',

    marginBlock: theme.spacing(3, 2),
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',
    cursor: 'pointer',
    '&:last-child': {
      //  marginTop: theme.spacing(1.25),
      fontWeight: 600,
    },
  },
  listContainer: { backgroundColor: '#fff', zIndex: 100000 },
  item: {
    border: '1px solid #DFDFDF',
    backgroundColor: '#fff',
    zIndex: 10000,
    '&:first-child': {
      borderTop: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  middelSectionContainer: {
    marginBlock: theme.spacing(3),
    '& p': {
      fontWeight: 700,
      fontSize: 22,
      fontFamily: 'Almarai',
    },
  },
  mobileIcon: {
    margin: theme.spacing(1.75),
  },
  mobileSearchInput: {
    border: '1px solid #C6C6C6',
    borderRadius: '10px',
    position: 'relative',
  },
}))
