import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textFieldRedius: {
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(1.5),
    },
  },
  menuContainer: {
    marginTop: '45px',
    height: '20rem',
  },
  phoneInputsWrapper: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: theme.spacing(1.5),
  },
  headerTitle: {
    fontWeight: 'bold',
    margin: 0,
    color: theme.palette.common.black,
  },
}))
