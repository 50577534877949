import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
  },
  content: {
    // margin: '0 auto',
  },
}))
