import { FocusEvent } from 'react'
import { Checkbox, Typography } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { WidgetProps } from '@rjsf/core'

import { useStyles, StyledCheckbox } from './StyledCustomCheckboxWidget'

/** The `CheckBoxWidget` is a widget for rendering boolean properties.
 *  It is typically used to represent a boolean.
 *
 * @param props - The `WidgetProps` for this component
 */
export const CustomCheckboxWidget = (props: WidgetProps) => {
  const classes = useStyles()
  // const [checked, setChecked] = useState(false)
  const {
    id,
    value,
    disabled,
    readonly,
    label = '',
    autofocus,
    onChange,
    onBlur,
    onFocus,
    required,
  } = props

  // Because an unchecked checkbox will cause html5 validation to fail, only add
  // the "required" attribute if the field value must be "true", due to the
  // "const" or "enum" keywords

  const _onChange = (_: any, checked: boolean) => {
    onChange(checked ? 'yes' : 'no')
  }

  const _onBlur = ({ target: { value } }: FocusEvent<HTMLButtonElement>) =>
    onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLButtonElement>) =>
    onFocus(id, value)

  return (
    <div>
      <FormControlLabel
        className={classes.root}
        control={
          <StyledCheckbox
            id={id}
            name={id}
            checked={
              typeof value === 'undefined' ? false : Boolean(value === 'yes')
            }
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
          />
        }
        label={<Typography className={classes.label}>{label}</Typography>}
      />
    </div>
  )
}
