import React, { FC, useState, useEffect } from 'react'
import { GoogleProvider, OpenStreetMapProvider } from 'leaflet-geosearch'
import { ClickAwayListener } from '@material-ui/core'
import { useTranslation } from '@flint/locales'

import { useStyle } from './AutoComplete.style'

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const googleProvider = new GoogleProvider({
  apiKey: GOOGLE_API_KEY,
  language: 'ar',
  region: 'SA',
  libraries: ['drawing', 'geometry', 'places', 'visualization'],
})
const openstreetmapProvider = new OpenStreetMapProvider({
  params: {
    countrycodes: 'sa',
    'accept-language': 'ar',
  },
})
const provider = openstreetmapProvider

export const AutoComplete: FC<any> = ({ changeLocationHandler }: any) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const [locations, setLocations] = useState([])
  const [searchText, changeSearchText] = useState('')
  const [selectedLocation, changeSelectedLocation] = useState<any>({})
  const [isMenu, hideMenu] = useState(true)

  const toggleBorderRadiusStyle = (locations: any[]) => {
    return `${classes.input} ${
      locations.length > 0 ? classes.noBottomRadius : classes.BottomRadius
    }`
  }

  const changeSearch = (value: string) => {
    changeSearchText((prevValue) => {
      if (prevValue.length > value.length) {
        setLocations([])
        hideMenu(false)
      } else hideMenu(true)
      return value
    })
  }

  const handleClickAway = () => {
    hideMenu(false)
    setLocations([])
  }

  const onLiClick = (location: any) => {
    changeSelectedLocation(location)
    setLocations([])
    changeSearchText('')
    changeLocationHandler(location)
  }

  useEffect(() => {
    // perform search from the locations provider
    const timeoutId = setTimeout(() => {
      const getLocations = async () => {
        const results = await provider.search({ query: searchText })
        setLocations(results)
      }
      if (searchText) {
        changeSelectedLocation({})
        getLocations()
      }
    }, 200)
    return () => clearTimeout(timeoutId)
  }, [searchText])

  return (
    <>
      <input
        type="text"
        className={toggleBorderRadiusStyle(locations)}
        onChange={(e) => {
          // reset location to undefined
          changeLocationHandler(undefined)
          changeSearch(e.target.value)
        }}
        placeholder={t('site')}
        value={selectedLocation.label || searchText}
      />
      {locations.length > 0 && isMenu && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <ul className={classes.root}>
              {locations.map((item: any, index: number) => {
                return (
                  <li key={index} onClick={() => onLiClick(item)}>
                    {item.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </>
  )
}
