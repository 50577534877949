import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { useTranslation } from '@flint/locales'
import { DotIcon } from 'assets'
import { useStyle } from './PropertyDescriptionCard.style'

export const DescriptionCard: FC<any> = ({
  cardTitle = '',
  details = [],
  customStyle = {},
  isBorders = false,
}) => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Grid className={classes.root} style={customStyle}>
      <Typography variant="h1">{t(cardTitle)}</Typography>
      {isBorders ? (
        <Grid container>
          {details.map(({ key, value }) => (
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.borderContainer}
            >
              <Typography variant="h2" gutterBottom>
                {t(key)}
              </Typography>
              {Object.keys(value).map((key) => (
                <Grid container alignItems="center">
                  <Grid className={classes.answerIcon}>
                    <DotIcon />
                  </Grid>
                  <Typography className={classes.answerText} variant="h2">
                    {t(key)} {value[key] || t('unavailable')}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container>
          {details.map(({ key, value }) => (
            <Grid item md={6} sm={6} xs={12}>
              <Typography variant="h2">{t(key)}</Typography>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid className={classes.answerIcon}>
                  {' '}
                  <DotIcon />
                </Grid>

                <Typography className={classes.answerText} variant="h2">
                  {t(value || 'unavailable')}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}
