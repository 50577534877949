import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Container, Divider, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { RootState } from 'store'

import { fields } from 'rjsf/fields'
import { widgets } from 'rjsf/widgets'
import { createAdvertiser } from 'store/advertiser'
import { CardComponent } from 'components/CardComponent/CardComponent'

import {
  SecondStepErrors,
  handleVerify,
  OrgFormData,
} from 'components/BrokerRegisterationsHelpers/seconsStep/SecondStepHelpers'
import schema from 'tempSchema/daal_join_as_org_schema.json'
import uiSchema from 'tempSchema/daal_join_as_org_ui_schema.json'
import {
  confirmButtonProps,
  resetButtonProps,
} from 'components/BrokerRegisterationsHelpers/seconsStep/ActionButtonsProps'
import { useStyles } from 'components/BrokerRegisterationsHelpers/seconsStep/secondStepStyles'
import { ConfirmationModal } from 'components/modals/ConfirmationModal/ConfirmModal'
import { VerifyLaterModal } from 'components/modals/verifyLaterModal/VerifyLaterModal'
import JoinAsOrganization from 'assets/images/joinAsAnOrganization.png'

const staticIdType = 'commercial'

export const JoinAsOrg = (): React.ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [formData, setFormData] = useState<OrgFormData>({})
  const [formErrors, setFormErrors] = useState<SecondStepErrors>({})
  const [isConfirmationButtonOpen, setIsConfirmationButtonOpen] =
    useState(false)
  const [isVerifiedLater, setIsVerifyLater] = useState(false)
  const [isVerifyLaterModalOpen, setIsVerifyLaterModalOpen] = useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const { successMessage } = useSelector((state: RootState) => state.advertiser)

  useEffect(() => {
    if (successMessage.length > 0 && !isVerifiedLater) {
      setIsConfirmationButtonOpen(true)
    } else if (isVerifiedLater) {
      setIsVerifyLater(false)
      handleRedirect()
    }
  }, [successMessage])

  const handleConfirmVerifyLater = () => {
    const formDataChecked = { ...formData }
    setIsVerifyLater(true)
    dispatcher(
      createAdvertiser({
        ...formDataChecked,
        idType: staticIdType,
        validateAdvertiser: false,
      })
    )
  }

  const handleVerifyLater = () => {
    setIsVerifyLaterModalOpen(true)
  }

  const handleSubmit = () => {
    if (formData?.organizationNumber) {
      dispatcher(
        createAdvertiser({
          ...formData,
          idType: staticIdType,
          validateAdvertiser: true,
        })
      )
    } else {
      let extraErrors: SecondStepErrors = {}
      const identityNumberRequired = handleVerify({
        inputType: 'organizationNumber',
        value: formData?.organizationNumber,
        verification: 'required',
      })
      if (identityNumberRequired?.organizationNumber) {
        extraErrors = {
          ...extraErrors,
          ...identityNumberRequired,
        }
      }
    }
  }

  const handleRedirect = () => {
    navigate('/add-advertise')
  }

  return (
    <Container className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant="h1" color="textPrimary">
          {t('Join_as_an_advertiser')}
        </Typography>
      </div>
      <div className={classes.secondStepContainer}>
        <ConfirmationModal
          isOpen={isConfirmationButtonOpen}
          handleRedirect={handleRedirect}
          handleClose={() => {
            setIsConfirmationButtonOpen(false)
          }}
        />
        <VerifyLaterModal
          isOpen={isVerifyLaterModalOpen}
          handleClose={() => {
            setIsVerifyLaterModalOpen(false)
          }}
          handleConfirm={handleConfirmVerifyLater}
        />

        <Grid
          container
          spacing={6}
          alignItems="center"
          justifyContent="center"
          className={classes.reverseColumnsWithMobile}
        >
          <Grid item sm={12} md={6}>
            <Typography className={classes.title}>
              {t('org_details_as_in_real_estate_general_authority')}
            </Typography>
            <ReactJsonSchemaForm
              schema={schema as any}
              uiSchema={uiSchema}
              extraErrors={formErrors}
              formData={formData}
              setFormData={setFormData}
              widgets={widgets}
              fields={fields}
              onChange={(e) => {
                setFormData(e.formData)
                setFormErrors({})
              }}
              onSubmit={handleSubmit}
              actionButtons={{
                resetButtonHide: true,
                confirmButtonHide: false,
                confirmButtonProps,
              }}
              showErrorList={false}
              liveOmit
              omitExtraData
            />
          </Grid>
          <Divider
            orientation="vertical"
            className={classes.hideDividerInSmallerScreen}
          />

          <Grid item sm={12} md className={classes.stepWrapper}>
            <CardComponent
              image={JoinAsOrganization}
              title={t('join as organization')}
              label={t(
                'Adding the advertisement under the name of an establishment that has a commercial register and is allowed to advertise real estate'
              )}
              imgAlt="join as an organization"
              hideLabelOnMobile
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}
