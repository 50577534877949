import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useTranslation } from '@flint/locales'
import { CardsComponent } from 'components/BrokerRegisterationComponents/firstStep/cardsComponent'

import { useStyles } from './BrokerRegisterationStyles'

export const BrokerRegistrationPage = (): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container className={classes.containerStyles}>
      <div className={classes.joinAsAdvertiser}>
        <div>
          <Typography variant="h5" className={classes.title}>
            {t('Join_as_an_advertiser')}
          </Typography>

          <CardsComponent />
        </div>
      </div>
    </Container>
  )
}
