import React, { FC } from 'react'
import {
  Grid,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { LoadingProgress } from '@flint/core'
import { useTranslation } from '@flint/locales'
import { useSelector } from 'react-redux'

import { RootState } from 'store/reducer'
import {
  AppStoreLogo,
  GooglePlayLogo,
  WhatsappButton,
  WhatsappMobileButton,
} from 'assets'

import { useStyle } from './Footer.style'

export const Footer: FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { t } = useTranslation()
  const { advertiser } = useSelector((state: RootState) => state)
  const loader = advertiser.loader
  const navigate = useNavigate()
  return (
    <footer className={classes.root}>
      <LoadingProgress loading={loader} />

      {isMobileScreen ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            {' '}
            <Typography
              className={clsx(classes.title, classes.decorateText)}
              onClick={() => navigate('/privacy-policy')}
            >
              {t('privacy policy')}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            {' '}
            <a
              className={classes.wsLogo}
              href="https://api.whatsapp.com/send?phone=966551507434"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsappMobileButton />
            </a>
          </Grid>
          <Grid item>
            {' '}
            <Typography className={classes.title}>{t('copyrights')}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Container>
          <Grid container alignItems="center" justifyContent="center">
            {/* <Grid item container md={6} sm={12} className={classes.logoContainer}>
            <AppStoreLogo className={classes.AppStorelogo} />
            <GooglePlayLogo className={classes.AppStorelogo} />
          </Grid> */}
            <Grid item>
              {' '}
              <Typography
                className={classes.title}
                onClick={() => navigate('/privacy-policy')}
              >
                {t('privacy policy')}
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <a
                className={classes.wsLogo}
                href="https://api.whatsapp.com/send?phone=966551507434"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsappButton />
              </a>
            </Grid>
            <Grid item>
              {' '}
              <Typography className={classes.title}>
                {t('copyrights')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
    </footer>
  )
}
