import React, { useEffect } from 'react'
import clsx from 'clsx'
import {
  CardHeader,
  Card,
  CardContent,
  Collapse,
  IconButton,
  ClickAwayListener,
} from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { ExpandIcon } from 'assets'
import { useStyle } from './DropdownSelect.style'

export const DropdownSelect = ({
  title,
  subheader,
  children,
  itemSelected,
}) => {
  const classes = useStyle()
  const location = useLocation()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleClickAway = () => {
    setExpanded(false)
  }
  useEffect(() => {
    setExpanded(false)
  }, [location, itemSelected])
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Card
        className={clsx(classes.root, {
          [classes.modifyMenuHeight]: expanded,
        })}
        elevation={0}
      >
        <CardHeader
          className={classes.cardRoot}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandIcon />
            </IconButton>
          }
          titleTypographyProps={{
            style: {
              color: '#6D6D6D',
              fontFamily: 'Almarai',
              fontWeight: 400,
              fontSize: '1.2rem',
              cursor: 'pointer',
              marginBlockEnd: 5,
              lineHeight: '13.39px',
            },
          }}
          subheaderTypographyProps={{
            style: {
              color: '#000000',
              fontFamily: 'Almarai',
              fontWeight: 700,
              fontSize: '1.4rem',
              cursor: 'pointer',
              lineHeight: '15.62px',
              whiteSpace: 'nowrap',
            },
          }}
          title={title}
          subheader={subheader}
          onClick={handleExpandClick}
        />

        <Collapse in={expanded} unmountOnExit>
          <CardContent className={classes.collapsContent}>
            {children}
          </CardContent>
        </Collapse>
      </Card>
    </ClickAwayListener>
  )
}
