import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  stepperContainer: {
    paddingInline: 0,

    scrollbarWidth: 'thin',
    maxHeight: '100px',
    paddingBlock: 6,
    overflowX: 'auto',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.07)',
    '& > *': {
      flexGrow: 1,
    },

    '&::-webkit-scrollbar': {
      height: '.5rem',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      backgroundColor: '#ccc',
      outline: '1px solid slategrey',
    },
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  stepContainer: {
    cursor: 'pointer',
  },
  iconContainer: {
    position: 'relative',
    zIndex: 999,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column !important' as 'column-reverse',
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    color: '#000',
  },
  labelActive: {
    fontSize: 13,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  disabledlabel: {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: '#707070',
    '& + p': {
      fontSize: '1.8rem',
      fontWeight: 700,
      color: '#000000 !important',
      marginBottom: 20,
    },
  },
}))
