import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),

    paddingInline: theme.spacing(1, 2),
    boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.07)',
  },
  cardHeader: {
    borderBottom: '1px solid #DFDFDF;',
  },
  cardBody: {
    paddingBlock: theme.spacing(1, 0),
  },
  propertyType: {
    borderRadius: '5rem',
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(1.5),
  },
  realstateType: {
    backgroundColor: 'rgba(233, 233, 233, 1)',
    borderRadius: '50px',
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(1),
    marginInline: theme.spacing(1, 0),
    '& > p': {
      fontSize: 10,
      fontWeight: 700,
    },
  },
  cardIconContainer: {
    marginBottom: theme.spacing(1),
  },
  cardIcon: {
    marginInlineEnd: theme.spacing(1.5),
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  priceDetails: {
    margin: theme.spacing(0, 1.5, 0, 0),
    fontFamily: 'Almarai',
    fontWeight: 800,
    fontSize: 30,

    '& + p': {
      fontFamily: 'Almarai',
      fontWeight: 700,
      fontSize: 10,
      color: '#595959',
    },
  },
  adType: {
    fontWeight: 700,
    fontSize: 10,
  },
  addressDetails: {
    marginBlock: '1rem',
    marginRight: '1.5rem',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 13,
  },
  advertiseDescription: {
    fontFamily: 'Almarai',
    fontWeight: 400,
    fontSize: 10,
  },

  iconText: {
    fontFamily: 'Almarai',
    fontSize: 8,
    fontWeight: 400,
    '& + p': {
      fontFamily: 'Almarai',
      fontSize: 12,
      fontWeight: 700,
      color: '#000000',
    },
  },
  saleAd: {
    backgroundColor: '#F1DFFF',
    color: theme.palette.primary.main,
  },
  rentAd: {
    backgroundColor: '#FFD5E9',
    color: theme.palette.secondary.main,
  },
  investmentAd: {
    backgroundColor: '#DCEAFF',
    color: '#377EE8',
  },
  investmentTitle: {
    marginBlock: theme.spacing(1.2),
    color: '#377EE8',
    fontFamily: 'Almarai',
    fontSize: 25,
    fontWeight: 800,
  },
  datesContainer: {
    marginBlock: theme.spacing(1),
    borderBlock: '1px solid #EAEAEA',
    '& :first-child': {
      borderRight: '1px solid #EAEAEA',
    },
  },
  date: {
    marginBlock: '5px',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 10,
  },
  advertiserData: {
    color: '#595959',

    fontFamily: 'Almarai',
    fontSize: 12,
    fontWeight: 400,
    '& + p': {
      color: '#000',
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 10,
    },
  },
}))
