import React from 'react'

import { useStyles } from './StyledCountryDetailsUi'
import { CountryDetailsProps } from './CountryDetailsUi.interface'

export const CountryDetailsView = ({
  country,
  handleSelectCountryCode,
}: CountryDetailsProps): React.ReactElement => {
  const classes = useStyles()
  return (
    <div
      className={classes.countryWrapper}
      onClick={() => handleSelectCountryCode(country.dial_code)}
    >
      <span> {country.dial_code} </span>
      <span> {country.name} </span>
    </div>
  )
}
