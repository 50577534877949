import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 'calc(100vh - 66px - 69px)',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cardRoot: {
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '100%',
    // },
    marginTop: 21,
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000000',
    paddingTop: theme.spacing(4),
  },
}))
