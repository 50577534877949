import React, { useState } from 'react'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import InfoIcon from '@material-ui/icons/Info'
import IconButton from '@material-ui/core/IconButton'
import WarningIcon from '@material-ui/icons/Warning'
import CancelIcon from '@material-ui/icons/Cancel'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useDispatch } from 'react-redux'
import { useTranslation } from '@flint/locales'
import { DeactivateAdModal } from 'components/modals/deactivateAdModal/DeactivateAdModal'

import { dateFormat } from 'utils/base'
import { deactivateAd, updateAdvertise } from 'store/advertiser'
import { useStyle } from './StatusBar.style'

export const StatusBar = ({ status, dateAdded, adId }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const dispatcher = useDispatch()

  const date = dateFormat(dateAdded)
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)
  const handleDeactivateAdvertise = () => {
    dispatcher(deactivateAd(adId))
  }

  const handleDeactivateIcon = () => {
    setIsDeactivateModalOpen(true)
  }

  switch (status) {
    case 'INSPECTION':
      return (
        <ImageListItemBar
          title={t(status)}
          actionIcon={
            <IconButton className={classes.warningIconColor}>
              <WarningIcon />
            </IconButton>
          }
          actionPosition="left"
          className={classes.inspectionStatus}
        />
      )
    case 'EXPIRED':
      return (
        <ImageListItemBar
          title={t(status)}
          actionIcon={
            <IconButton className={classes.expiredIconColor}>
              <VisibilityOffIcon />
            </IconButton>
          }
          actionPosition="left"
          className={classes.expiredStatus}
        />
      )
    case 'REJECTED':
      return (
        <ImageListItemBar
          title={t(status)}
          actionIcon={
            <IconButton className={classes.rejectedIconColor}>
              <CancelIcon />
            </IconButton>
          }
          actionPosition="left"
          className={classes.rejectedStatus}
        />
      )
    case 'DEACTIVATED':
      return (
        <ImageListItemBar
          title={t(status)}
          actionIcon={
            <IconButton className={classes.deactivatedIconColor}>
              <InfoIcon />
            </IconButton>
          }
          actionPosition="left"
          className={classes.deactivatedStatus}
        />
      )
    case 'DRAFT':
      return (
        <ImageListItemBar
          title={t(status)}
          actionIcon={
            <IconButton className={classes.draftIconColor}>
              <InfoIcon />
            </IconButton>
          }
          actionPosition="left"
          className={classes.inspectionStatus}
        />
      )
    default:
      return (
        <>
          <DeactivateAdModal
            isOpen={isDeactivateModalOpen}
            handleClose={() => {
              setIsDeactivateModalOpen(false)
            }}
            handleConfirm={handleDeactivateAdvertise}
          />
          <IconButton
            className={classes.eyeIcon}
            disableRipple
            onClick={handleDeactivateIcon}
          >
            <VisibilityOffIcon color="error" />
          </IconButton>
          <ImageListItemBar
            title={t(status) + date}
            actionIcon={
              <IconButton style={{ color: 'rgba(67, 228, 83, 1)' }}>
                <CheckCircleIcon />
              </IconButton>
            }
            actionPosition="left"
            style={{
              top: '71%',
              height: '35px',
              backgroundColor: 'rgba(38, 137, 48, 0.54)',
            }}
          />
        </>
      )
  }
}
function setIsVerifyLaterModalOpen(arg0: boolean) {
  throw new Error('Function not implemented.')
}
