import { makeStyles, Theme } from '@material-ui/core'
import { homeCover, homeMobileCover } from 'assets'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 66px - 52px)',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${homeCover})`,

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 80px)',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'top',
      backgroundImage: `linear-gradient(to right bottom, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0.33) 100%), url(${homeMobileCover})`,
    },
  },
  homeContainer: { marginTop: '145px' },
  logo: {
    marginBottom: theme.spacing(5.5),
    [theme.breakpoints.down('sm')]: {
      height: '6rem',
    },
  },
}))
