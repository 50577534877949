import React, { ReactElement } from 'react'

import { Typography } from '@material-ui/core'
import { useStyles } from './Title.styles'

export interface ITitleProps {
  tag?: string
  id?: string
  title?: string
  styles?: any
  hide?: boolean
  children?: ReactElement
}

export const Title = ({
  id,
  tag,
  hide,
  title,
  styles,
  children,
}: ITitleProps) => {
  const classes = useStyles()
  const variant: any = tag || 'h5'

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(title || children) && !hide && (
        <Typography
          className={classes.root}
          id={id}
          style={{
            ...styles,
          }}
          variant={variant}
        >
          {title ?? children}
        </Typography>
      )}
    </>
  )
}
