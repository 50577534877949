import React from 'react'
import Grid from '@material-ui/core/Grid'

import { FirstCardComponent } from './firstCard/firstStepCard'
import { SecondCard } from './secondCard/secondCard'
import { useStyles } from './CardsStyles'

export const CardsComponent = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      className={classes.cardsWrapper}
    >
      <Grid item xs={12} md={6}>
        <FirstCardComponent />
      </Grid>
      <Grid item xs={12} md={6}>
        <SecondCard />
      </Grid>
    </Grid>
  )
}
