import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'space-evenly',
      height: '13rem',
    },
  },
  inputWrapper: {
    position: 'relative',
    height: '13rem',
    background:
      'transparent linear-gradient(190deg, #662D91 0%, #CA2D78 100%) 0% 0% no-repeat padding-box',
    borderRadius: '1rem',
    width: '100%',
    padding: '0 3.4rem',
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      height: '45rem',
      // flexDirection: 'column',
      padding: theme.spacing(1),
      width: 'calc(100% - 90px)',
      backgroundColor: '#000',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  InputContainer: {
    position: 'relative',
    maxWidth: '322px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  input: {
    width: '100%',
    height: 50,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 10,
    opacity: 1,
    outline: 'none',
    border: 'none',
    padding: '0 1rem',
    fontFamily: 'Almarai',
    fontWeight: 700,
  },
  noBottomRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  BottomRadius: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  button: {
    minWidth: '120px',
    height: '50px',

    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight: 700,
    fontSize: '16px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '160px',
    },
  },
  icon: {
    margin: '0 1rem',
  },
  label: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '2.5rem',
    fontFamily: 'Almarai',
    textAlign: 'center',
    marginInline: theme.spacing(2),

    '&:first-child': {
      marginInline: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('sm')]: {
      marginInline: '10rem',
    },
  },
  selectContainer: {
    backgroundColor: '#ffffff',
    height: 50,
    width: '100%',

    borderRadius: '1rem',
    '& > *': {
      height: '100%',
      border: 'none',
    },
  },
  toggleButtonsContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    maxWidth: '262px',
    height: '50px',
    borderRadius: '1rem',
    padding: '.5rem',
    marginInline: theme.spacing(1.5),
    fontSize: '1.4rem',
    '& > *': {
      width: '33%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '1rem',
      cursor: 'pointer',
      transition: 'all .5s ease-in-out',
      fontFamily: 'Almarai',
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  toggleButtonActive: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  listContainer: { backgroundColor: '#fff', zIndex: 100000 },
  item: {
    border: '1px solid #DFDFDF',
    backgroundColor: '#fff',
    zIndex: 10000,
    '&:first-child': {
      borderTop: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  selectIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginInlineEnd: theme.spacing(1),
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',
    cursor: 'pointer',
    '&:last-child': {
      //  marginTop: theme.spacing(1.25),
      fontWeight: 600,
    },
  },
  drawerRoot: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: '10px 10px 0 0',
    },
  },
  drawerContainer: { paddingInline: 20 },
  MobileLabel: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'Almarai',

    marginBlock: theme.spacing(3, 2),
  },
  middelSectionContainer: {
    marginBlock: theme.spacing(3),
    '& p': {
      fontWeight: 700,
      fontSize: 22,
      fontFamily: 'Almarai',
    },
  },
  divider: { height: '1px', backgroundColor: '#CDCDCD', width: '100%' },
  mobileSearchInput: {
    border: '1px solid #C6C6C6',
    borderRadius: '10px',
    position: 'relative',
  },
  mobileToggleButtonsContainer: {
    backgroundColor: '#F1F1F1',
    marginBlock: theme.spacing(2.5, 3.25),
    height: '50px',
    borderRadius: 10,
    color: '#676767',
    fontSize: 14,
    '& > *': {
      width: '33%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 0,
      cursor: 'pointer',
      transition: 'all .5s ease-in-out',
      fontFamily: 'Almarai',
      fontWeight: 700,
    },
    '& :first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& :last-child': {
      borderRadius: '0 10px 10px 0',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  mobileToggleButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  mobileButton: {
    height: 62,

    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight: 700,
    fontSize: 18,
    cursor: 'pointer',
  },
  mobileIcon: {
    margin: theme.spacing(1.75),
  },
}))
