import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    // display: 'none',
  },
  mobileHeader: { height: '10rem' },
  drawerContainer: {
    width: 250,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  menuButton: {
    marginInline: theme.spacing(1.5),
  },
  logoContainer: {
    textAlign: 'center',
  },
  logo: {
    width: '10rem',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  addAdvertiseButton: {
    textDecoration: 'none',
    marginInlineStart: '2rem',
    paddingInlineStart: '2rem',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.6rem',
    marginBlock: '1rem',
    cursor: 'pointer',
    '&:last-child': {
      //  marginTop: theme.spacing(1.25),
      fontWeight: 600,
    },
  },
  listContainer: {
    flexGrow: 1,
    paddingInlineEnd: '2rem',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  logoutOption: {
    borderBottom: 'none',
    color: theme.palette.secondary.main,
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 16,
    marginBlock: 'auto 2rem',
    marginInlineEnd: '-2rem',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  titleUnverified: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.4rem',
    cursor: 'pointer',
    backgroundColor: '#FFFBDC',
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
    marginBlock: '1rem',
    '&:hover': {
      backgroundColor: '#FFFBDC',
    },
  },
  wsLogo: {
    cursor: 'pointer',
    paddingInlineStart: theme.spacing(1.5),
  },
  decorateText: {
    textDecoration: 'underline',
  },
}))
