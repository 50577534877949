import {
  makeStyles,
  Theme,
  Checkbox,
  CheckboxProps,
  withStyles,
} from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    width: '100%',
    height: '100%',
    justifyContent: 'start',
    gridTemplateColumns: '15% 85%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '10% 85%',
    },
  },
  label: {
    fontSize: '1.6rem',
  },
}))

export const StyledCheckbox = withStyles({
  root: {
    transform: 'scale(1.9)',
    '&$checked': {
      color: '#2C2E2E',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)
