import { ChangeEvent, useState } from 'react'

import { makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useTranslation } from '@flint/locales'
import { WidgetProps } from '@rjsf/core'
import { uploadAdvertiseFiles } from 'store/advertiser'

import { Title } from './Title'

const useStyle = makeStyles((theme) => ({
  root: {},
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f7f7f7',
    marginTop: theme.spacing(3),
    minHeight: 200,
    flexDirection: 'column',
    borderRadius: 10,
    border: 'solid 1px #e0e0e0',
  },
  placeholder: {
    fontSize: 17,
    color: '#aaaaaa',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  input: {
    position: 'absolute',
    left: 0,
    width: '100%',
    top: 0,
    opacity: 0,
    height: '100%',
  },
  hide: {
    display: 'none',
  },
  placeholdetTextStyle: { textAlign: 'center', fontSize: '1.4rem' },
  deleteIconWrapper: { display: 'flex', alignItems: 'center' },
  deleteIcon: { margin: '0 0.2rem', cursor: 'pointer' },
}))

const InputComponent = (props: any) => {
  const { multiple, id, readonly, disabled, options, onChange, className } =
    props

  return (
    <input
      className={className}
      type="file"
      disabled={readonly || disabled}
      id={id}
      multiple={multiple}
      defaultValue=""
      // autoFocus={autofocus}
      onChange={onChange}
      accept={(options as any).accept}
    />
  )
}
interface IFile {
  url: string

  name: string
}
export const FileWidget = (props: WidgetProps) => {
  const { label, uiSchema = {}, schema, onChange } = props
  const [buttonLoading, setButtonLoading] = useState(false)
  const { t } = useTranslation()
  const classes = useStyle()

  const title = uiSchema['ui:title'] ?? schema.title

  const [files, setFiles] = useState<Array<IFile>>([])

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setButtonLoading(true)
    const currentFile = e.target.files[0]
    const doesExists = files.findIndex((i) => i.name === currentFile.name)
    if (currentFile?.name && doesExists === -1) {
      uploadAdvertiseFiles(currentFile)
        .then((res: string) => {
          setFiles((prevState) => [
            ...prevState,
            { url: res, name: currentFile.name as string },
          ])
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setButtonLoading(false)
        })
    }
  }

  const deleteHandler = (index: number) => {
    const currentFiles = [...files]
    currentFiles.splice(index, 1)
    setFiles(currentFiles)
  }
  onChange(files.map(({ url }) => url))
  return (
    <div className={classes.root}>
      <Title title={title} />
      {files.length
        ? files.map((f, index) => (
            <div key={index} className={classes.deleteIconWrapper}>
              <Typography variant="h4">{f.name}</Typography>
              <DeleteForeverIcon
                onClick={() => deleteHandler(index)}
                color="error"
                className={classes.deleteIcon}
              />
            </div>
          ))
        : null}
      <div className={classes.wrapper}>
        {buttonLoading ? (
          <CircularProgress size={30} thickness={5} color="secondary" />
        ) : (
          <AddIcon />
        )}

        <div className={classes.placeholder}>
          {files.length ? (
            <Typography className={classes.placeholdetTextStyle}>
              {uiSchema['ui:placeholder'] || label || t('add more')}
            </Typography>
          ) : (
            <Typography className={classes.placeholdetTextStyle}>
              {uiSchema['ui:placeholder'] || label || t('upload work order')}
            </Typography>
          )}
          <InputComponent
            {...props}
            onChange={_onChange}
            className={classes.input}
          />
        </div>
      </div>
    </div>
  )
}
