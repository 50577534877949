import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
    border: '1px solid #DFDFDF;',
    paddingInline: theme.spacing(2),
  },
  cardHeader: {
    borderBottom: '1px solid #DFDFDF;',
  },
  cardBody: {
    paddingBlock: theme.spacing(1, 1.5),
  },
  propertyType: {
    borderRadius: '5rem',
    padding: theme.spacing(1),
  },
  realstateType: {
    backgroundColor: 'rgba(233, 233, 233, 1)',
    borderRadius: '50px',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1.5),

    '& > p': {
      fontSize: '1.6rem',
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0),
    },
  },
  cardIconContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  cardIcon: {
    marginInlineEnd: theme.spacing(1.5),
  },
  detailsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
  },
  priceDetails: {
    margin: theme.spacing(0, 1.5),
    fontFamily: 'Almarai',
    fontWeight: 800,
    fontSize: '4.4rem',

    '& + p': {
      fontFamily: 'Almarai',
      fontWeight: 700,
      fontSize: '1.9rem',
      color: '#595959',
    },
  },
  adType: {
    fontWeight: 700,
    fontSize: '1.6rem',
  },
  addressDetails: {
    marginTop: '1rem',
    marginRight: '1.5rem',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  date: {
    marginTop: '1.5rem',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  iconText: {
    fontFamily: 'Almarai',
    fontSize: '1.4rem',
    fontWeight: 400,
    '& + p': {
      fontFamily: 'Almarai',
      fontSize: '2.2rem',
      fontWeight: 700,
      color: '#000000',
    },
  },
  saleAd: {
    backgroundColor: '#F1DFFF',
    color: theme.palette.primary.main,
  },
  rentAd: {
    backgroundColor: '#FFD5E9',
    color: theme.palette.secondary.main,
  },
  investmentAd: {
    backgroundColor: '#DCEAFF',
    color: '#377EE8',
  },
  investmentTitle: {
    marginBlock: theme.spacing(1.2),
    color: '#377EE8',
    fontFamily: 'Almarai',
    fontSize: '3rem',
    fontWeight: 800,
  },
}))
