import React, { useRef, useState, useEffect, FC } from 'react'
import { Grid } from '@material-ui/core'

import View from 'ol/View'
import Map from 'ol/Map'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import WebGLPointsLayer from 'ol/layer/WebGLPoints'

import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import { transform } from 'ol/proj'
import { Modify } from 'ol/interaction'
// import GeoJSON from 'ol/format/GeoJSON'
// import WebGLPointsLayer from 'ol/layer/WebGLPoints'
// import Overlay from 'ol/Overlay'
// import { MarkerIcon } from 'assets'

// import { Vector } from 'ol/source'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useStyle, markerIcon } from './OlMap.style'
import 'ol/ol.css'
import './olNativeStyle.scss'

export const OlMap: FC<any> = ({ coords = [0, 0] }) => {
  const classes = useStyle()
  const [map, setMap] = useState(null)
  const [mapZoom, setMapZoom] = useState(8)
  const [mapCenter, setMapCenter] = useState(coords)
  // const [mapMarker, setMapMarker] = useState()

  const mapElement = useRef()
  useEffect(() => {
    // create and add vector source layer
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    })
    const initialMapMarker = new Feature({
      geometry: new Point(transform(coords, 'EPSG:4326', 'EPSG:3857')),
    })
    // initialMapMarker.setStyle(markerIcon)
    const markerData = new VectorSource({
      features: [initialMapMarker],
    })
    const markerLayer = new WebGLPointsLayer({
      source: markerData,
      style: markerIcon,
    })

    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),

        initalFeaturesLayer,
        markerLayer,
      ],
      view: new View({
        projection: 'EPSG:3857',
        zoom: mapZoom,
        center: transform(mapCenter, 'EPSG:4326', 'EPSG:3857'),
        constrainResolution: true,
      }),
      controls: [],
    })
    const animate = () => {
      initialMap.render()
      window.requestAnimationFrame(animate)
    }
    animate()
    // const modify = new Modify({
    //   hitDetection: markerLayer,
    //   source: markerData,
    // })

    // modify.on(['modifystart', 'modifyend'], (evt) => {
    //   //  this.changeMarkerLocation()

    //   mapElement.current.style.cursor =
    //     evt.type === 'modifystart' ? 'grabbing' : 'pointer'
    // })
    // initialMap.addInteraction(modify)
    // save map and vector layer references to state
    // setMapMarker(markerLayer)
    setMap(initialMap)
  }, [])
  //   const baseLayer = new TileLayer({
  //     source: new OSM(),
  //   })

  //   let olMap = new Map({
  //     // the map will be created using the 'map-root' ref
  //     target: mapElement.current,
  //     layers: [baseLayer],
  //     // overlays: [this.overlay],
  //     // the map view will initially show the whole world
  //     view: new View({
  //       zoom: mapZoom,
  //       center: transform(mapCenter, 'EPSG:4326', 'EPSG:3857'),
  //       constrainResolution: true,
  //     }),
  //   })

  return (
    <Grid className={classes.root}>
      <div ref={mapElement} className={classes.mapElement} />
    </Grid>
  )
}
