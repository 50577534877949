import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  isBlueRoot: {
    '& input': {
      color: '#0088ff',
      fontWeight: 'bold',
    },
    '& label': {
      color: '#0088ff !important',
    },
    '& fieldset': {
      border: '1px dashed #0088ff !important',
    },
  },
  input: {
    backgroundColor: '#fff',
    color: '#6D6D6D',
    fontSize: '1.4rem',
  },
  disabledInput: {
    backgroundColor: '#F9F9F9',
  },
  isBlueInput: {
    backgroundColor: '#d9edff',
  },
  isBlueInputAdornment: {
    backgroundColor: '#d9edff',
    '& p': {
      color: '#0088ff',
      fontWeight: 'bold',
    },
  },
  isGreenRoot: {
    '& input': {
      color: '#4bbc00',
      fontWeight: 'bold',
    },
    '& label': {
      color: '#4bbc00 !important',
    },
    '& fieldset': {
      border: '1px solid #4bbc00 !important',
    },
  },
  isGreenInput: {
    backgroundColor: '#f6fbf4',
  },
  isGreenInputAdornment: {
    backgroundColor: '#f6fbf4',
    '& p': {
      color: '#4bbc00',
      fontWeight: 'bold',
    },
  },
  isGreyRoot: {
    '& input': {
      color: '#909090',
      fontWeight: 'bold',
    },
    '& label': {
      color: '#909090 !important',
    },
    '& fieldset': {
      border: '1px solid #ddd !important',
    },
  },
  isGreyInput: {
    backgroundColor: '#f7f7f7',
  },
  isGreyInputAdornment: {
    backgroundColor: '#f7f7f7',
    '& p': {
      color: '#909090',
      fontWeight: 'bold',
    },
  },
  inputAdornment: {
    '& p': {
      fontSize: theme.spacing(1.6),
    },
  },
  helperTextContainer: {
    color: '#444',
    marginLeft: 0,
    marginRight: 0,
  },
  helperTextContent: {
    display: 'block',
    borderLeft: '2px solid #666',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(0.6, 1, 0.6, 2),
    borderTopRightRadius: theme.spacing(0.6),
    borderBottomRightRadius: theme.spacing(0.6),
  },
  helperTextDate: {
    display: 'block',
    fontSize: theme.spacing(1.6),
    marginBottom: theme.spacing(0.3),
  },
  hijiriDateTitle: {
    marginRight: theme.spacing(0.4),
  },
}))
