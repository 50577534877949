import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: theme.spacing(2.2),
  },
  sameLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridGap: theme.spacing(1.8),
  },
  label: {
    color: '#000',
    fontWeight: 'bold',
  },
  optionsContainer: {
    overflow: 'hidden',
    backgroundColor: '#eee',
    borderRadius: theme.spacing(1.5),
    height: '4.9rem',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('md')]: {
      height: '5.3rem',
    },
  },
  fullWidth: {
    width: '100%',
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
  },
  optionButton: {
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: theme.spacing(1.8),
    backgroundColor: 'white',
    padding: theme.spacing(1.4, 2.5),
    minWidth: '7rem',
  },
  activeOption: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  activeGreenOption: {
    color: '#fff',
    borderRadius: theme.spacing(1.2),
    backgroundColor: '#398f00',
  },
  disabledItem: {
    cursor: 'not-allowed',
  },
}))
