import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {},
  headerTitle: {
    fontWeight: 'bold',
    margin: 0,
    marginBottom: '1rem',
    color: theme.palette.common.black,
  },
  radioGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 12rem)',
    justifyContent: 'space-between',
  },
  radioItem: {
    backgroundColor: theme.palette.common.white,
    color: '#2C2E2E',
    flexGrow: 1,
    width: '12rem',
    height: '12rem',
    borderRadius: theme.spacing(1.2),
    padding: '1rem',
    boxShadow: '1px 1px 3px gray',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: '#2C2E2E',
  },
  option: { display: 'grid', justifyContent: 'center', fontSize: '2rem' },
  optionLabel: { textAlign: 'center', fontSize: '1.6rem' },
  iconFont: { fontSize: '4rem' },
  disabledItem: {
    cursor: 'not-allowed',
  },
}))
