import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: '24',
    p: 4,
    backgroundColor: theme.palette.common.white,
    padding: '2rem',
    borderRadius: theme.spacing(1),
  },
  iconWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },
  successIcon: {
    fontSize: '6rem',
    color: 'green',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '1rem',
  },
  subTitle: {
    textAlign: 'center',
    paddingTop: '0.3rem',
  },
  actionsWrapper: {
    display: 'grid',
    gridTemplateColumns: '50%',
    justifyContent: 'center',
    marginTop: '2rem',
    gridGap: '0.4rem',
  },
  addAnnouncmentButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  escapeButton: {
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
}))
