import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      border: '1px solid rgba(0,0,0,0.30)',
      height: '35rem',
      borderRadius: '1rem',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    stepperColor: { backgroundColor: 'inherit' },
    stepCursor: { cursor: 'pointer', fontSize: '1.4rem' },
  })
)
