import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'start',
    gridGap: '1.2rem',
  },
  label: {
    color: '#000',
    fontWeight: 'bold',
    margin: 0,
  },
  optionsContainer: {
    overflow: 'hidden',
    backgroundColor: '#fff',
    border: '1px solid silver',
    borderRadius: theme.spacing(1.5),
    padding: '0',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
  },
  optionButton: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(2.3),
    paddingBottom: theme.spacing(2.3),
    textAlign: 'center',
    fontSize: '1.4rem',
    fontFamily: 'Almarai',
    [theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  },
  buttonsWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '33.33% 33.33% 33.33%',
    justifyContent: 'center',
  },
  activeOption: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  activeGreenOption: {
    color: '#fff',
    backgroundColor: '#398f00',
  },
}))
