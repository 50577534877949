import React from 'react'

import * as Sentry from '@sentry/react'
import { Routes, Route } from 'react-router-dom'
import { FlintReduxProvider, AuthenticationProvider } from '@flint/core'
import { FlintApolloProvider } from '@flint/graphql'
import { ToastContainer } from 'react-toastify'
import { AddPropertePage } from 'pages/addProperty/AddPropertyPage'
import { JoinAsOrg } from 'pages/joinAsOrganization/joinAsOrg'

import {
  Layout,
  Home,
  PropertyDetails,
  Login,
  MyAdds,
  AccountDetails,
  PrivacyAndUsage,
  SearchResults,
  ErrorNotFound,
  BrokerRegistrationPage,
  BrokerRegAsPerson,
} from 'pages'

import 'react-toastify/dist/ReactToastify.css'
import './App.style.css'
// import { Path } from 'global'

function App() {
  return (
    <div className="App" style={{ direction: 'rtl' }}>
      <ToastContainer />
      <FlintApolloProvider>
        <FlintReduxProvider>
          <AuthenticationProvider
            publicUrls={[
              '/',
              '/privacy-policy',
              '/search-results',
              '/advertise-details/:id',
            ]}
          >
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/advertise-details/:id"
                  element={<PropertyDetails />}
                />
                <Route path="/search-results" element={<SearchResults />} />
                <Route path="/broker" element={<BrokerRegistrationPage />} />
                <Route path="/join-as-organization" element={<JoinAsOrg />} />
                <Route path="/join-as-broker" element={<BrokerRegAsPerson />} />
                <Route path="/profile" element={<AccountDetails />} />
                <Route path="/add-advertise" element={<AddPropertePage />} />
                <Route path="/my-adds" element={<MyAdds />} />
                <Route path="/privacy-policy" element={<PrivacyAndUsage />} />
                <Route path="*" element={<ErrorNotFound />} />
              </Routes>
            </Layout>
          </AuthenticationProvider>
        </FlintReduxProvider>
      </FlintApolloProvider>
    </div>
  )
}

export default Sentry.withProfiler(App)
