import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from './CardComponentStyled'

export interface CardComponentProps {
  image: string
  title: string
  label: string
  imgAlt: string
  hideLabelOnMobile?: boolean
}

export const CardComponent = ({
  image,
  title,
  label,
  imgAlt,
  hideLabelOnMobile = false,
}: CardComponentProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.imgWrapper}>
        <img src={image} alt={imgAlt} />
      </div>
      <Typography className={classes.title}>{title}</Typography>
      <Typography
        className={
          (classes.pos, hideLabelOnMobile && classes.hideLabelOnMobile)
        }
      >
        {label}
      </Typography>
    </div>
  )
}
