import React, { FC } from 'react'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { Footer, Header } from 'components'
import { useStyle } from './Layout.style'

type LayoutProps = {
  children: React.ReactNode
}
export const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Grid container className={classes.container}>
      <Header />
      <Grid item className={classes.content}>
        {children}
      </Grid>
      {!isMobileScreen && <Footer />}
    </Grid>
  )
}
