import React, { useEffect, useState } from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Carousel from 'react-material-ui-carousel'
import { ImagePlaceholder } from 'assets'
import { IGallery } from './Gallery.interface'
import { useStyle } from './Gallery.style'

export const Gallery = ({ images = [] }: IGallery) => {
  const [activeImage, setActiveImage] = useState(images[0]?.url)
  const isThereImages = images.length > 0
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  useEffect(() => {
    if (images.length > 0) {
      setActiveImage(images[0]?.url)
    }
  }, [images])
  const classes = useStyle()
  return (
    <div className={classes.root}>
      {isThereImages ? (
        <div>
          {isMobileScreen ? (
            <Carousel
              className={classes.carouselRoot}
              autoPlay={false}
              NextIcon={<NavigateBeforeIcon />}
              PrevIcon={<NavigateNextIcon />}
              navButtonsProps={{
                // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                  padding: '1rem',
                  margin: '0rem 0.5rem 0rem 3rem',
                },
              }}
              indicatorContainerProps={{
                style: {
                  position: 'absolute',
                  zIndex: 4,
                  textAlign: 'center',
                  marginTop: '-3rem', // 5
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: 'rgba(255, 255, 255, 1)', // 2
                },
              }}
              indicatorIconButtonProps={{
                style: {
                  padding: '0px',
                  margin: '0px',
                  color: 'rgba(255, 255, 255, 0.4)',
                  textAlign: 'center',
                },
              }}
            >
              {images.map(({ url }, index) => (
                <div key={index}>
                  <img
                    alt="test"
                    src={url}
                    className={classes.imageDimansion}
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <div className={classes.root}>
              <img
                className={classes.activeImage}
                src={activeImage}
                alt="property"
              />
              <div className={classes.thumbnailsContainer}>
                {images.map(({ url }, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setActiveImage(url)
                    }}
                  >
                    <img
                      className={classes.thumbnail}
                      src={url}
                      alt="property"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <img
          alt="placeholder"
          src={ImagePlaceholder}
          className={classes.imagePlaceholder}
        />
      )}
    </div>
  )
}
