import { makeStyles, Theme } from '@material-ui/core'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '10px',
    padding: theme.spacing(2, 0),
    direction: 'ltr!important' as any,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      borderRadius: 0,
    },
  },
  olPopup: {
    position: 'absolute',
    backgroundColor: 'white',
    '-webkit-filter': 'drop-shadow(0 1px 4px rgba(0,0,0,0.2))',
    filter: 'drop-shadow(0 1px 4px rgba(0,0,0,0.2))',
    padding: '15px',
    borderRadius: '10px',
    border: '1px solid #cccccc',
    bottom: '12px',
    left: '-140px',
    minWidth: '280px',
    direction: 'rtl!important' as any,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
    before: {
      top: '100%',
      border: 'solid transparent',
      content: ' ',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderTopColor: '#cccccc',
      borderWidth: '11px',
      left: '48px',
      marginLeft: '-11px',
    },
    after: {
      top: '100%',
      border: 'solid transparent',
      content: ' ',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderTopColor: 'white',
      borderWidth: '10px',
      left: '48px',
      marginLeft: '-10px',
    },
  },
  olPopupCloser: {
    display: 'none',
  },
  popupContent: {
    marginTop: '15px',
  },
  mapElementRef: {
    width: '100%',
    height: 'calc(100vh - 255px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 80px)',
    },
  },
}))

export const propertyIcon = new Style({
  image: new Icon({
    anchor: [0.5, 46],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: require('assets/images/propertyIcon.png'),
  }),
})

export const markerIcon = {
  symbol: {
    symbolType: 'image',
    /* eslint-disable */
    src: require('assets/images/markerIcon.png'),
    size: [35, 40],
    color: 'white',
    rotateWithView: false,
    offset: [0, 9],
  },
}
