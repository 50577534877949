/* global GeoJSON */

import { IAdvertiser } from 'global'

export enum AdvertiserAction {
  UPDATE = 'update',
  CREATE = 'create',
}

export interface IUserDetails {
  email: string
  firstName: string
  id: string
  lastName: string
  phone: string
  advertiser: IAdvertiser
  avatar: string
}
export interface IGeoJson {
  type: string
  crs: any
  features: any[]
}
export interface IAdvertise {
  advertiser: IAdvertiser
  id: string
  status: string
  data: any
  geometry: any
}

export interface IAdvertises {
  Count: number
  advertises: any[]
}

export interface AdvertiserDetails {
  advertiser: {
    // advertiserNumber: number

    phone: string
    email: string
  }
  firstName: string
  lastName: string
  avatar: string
}

export interface IAdvertiserState {
  userDetails: IUserDetails
  advertiser: IAdvertiser
  loader: boolean
  advertises: IAdvertises
  advertisesLoader: boolean
  selectedAdvertise: IAdvertise
  publishedAdvertises: IAdvertises
  publishedAdvertisesGeoJson: IGeoJson
  addAdvertiseJsonSchema: Record<string, any>
  addAdvertiseUiJsonSchema: Record<string, any>
  publishedAdvertisesLoader: boolean
  successMessage: string
  advertiserDetails: AdvertiserDetails
  searchResultsMobileFilter: boolean
  searchResultsMobileSearch: boolean
  myAdsMobileFilter: boolean
}

export const advertiserReducer = (): IAdvertiserState => ({
  userDetails: {} as IUserDetails,
  advertiser: {} as IAdvertiser,
  loader: false,
  advertisesLoader: true,
  advertises: {} as IAdvertises,
  selectedAdvertise: {} as IAdvertise,
  publishedAdvertises: {} as IAdvertises,
  publishedAdvertisesGeoJson: {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3857',
      },
    },
    features: [],
  },
  addAdvertiseJsonSchema: {},
  addAdvertiseUiJsonSchema: {},
  publishedAdvertisesLoader: true,
  successMessage: '',
  advertiserDetails: {} as AdvertiserDetails,
  searchResultsMobileFilter: false,
  searchResultsMobileSearch: false,
  myAdsMobileFilter: false,
})

export default advertiserReducer
