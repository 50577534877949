import React from 'react'
import { Container } from '@material-ui/core'
import { usePrivacyAndUsageStyle } from './PrivacyAndUsage.style'

export const PrivacyAndUsage = () => {
  const classes = usePrivacyAndUsageStyle()

  return (
    <Container>
      <div className={classes.root}>
        <p>
          اتفاقية خدمات منصة دال تاريخ النشر: تاريخ السريان: تاريخ أخر تعديل:
          هذه الاتفاقية بينك وبين منصة دال وتغطي شروط اتفاقية الخدمات
          (&quot;الشروط&quot;) استخدامك لـلخدمات والمنتجات التي يحتويها التطبيق
          (&quot;الخدمات&quot;)، وأنت توافق على هذه الشروط والأحكام من خلال
          إنشاء حساب أو من خلال زيارتك للتطبيق وتحميله.
        </p>
        <ol className={classes.itemsContainer}>
          <li>
            تعاريف مستخدمة في هذه الاتفاقية التطبيق: هو تطبيق دال المملوك لشركة
            التقنيات المكانية المحدودة، المقيدة في المملكة العربية السعودية
            بالسجل التجاري رقم [●]، وعنوان مقرها الرئيسي مدينة الرياض ص.ب [●]
            الرمز البريدي [●]. المستخدم: هو من أنشأ حساب في التطبيق بوصفه (بائع،
            مشتري، مؤجر، مستأجر، وسيط عقاري). الزائر: هو من يقوم بتحميل التطبيق
            وتصفحه دون أن يقوم بالتسجيل فيه. العروض: ما يتم عرضه من عقارات للبيع
            أو التأجير من قبل المستخدم. الصفقة التجارية: كل تعاقد يتم عن طريق
            التطبيق أو من خلاله.
          </li>
          <li>
            الخدمات يوفر التطبيق لمستخدميه المعلومات والوسائل اللازمة للحصول على
            عروض بيع أو ايجار عقارية مقدمة من ملاك العقارات أو وسطاء ملاك العقار
            (&quot;مقدمو العروض&quot;) عن طريق استخدامهم للتطبيق وفقاً للشروط
            والأحكام المعتمدة بعد تثبيته على الأجهزة الذكية.
          </li>
          <li>
            بيانات الخصوصية 1-3 تسري الخصوصية على جميع المستخدمين والزائرين
            الذين يستخدمون التطبيق في أي مكان بالعالم. 2-3 تحافظ منصة دال في
            كافة الأوقات على خصوصية وسرية كافة البيانات الشخصية التي يتحصل عليها
            من قبل المستخدمين، ويتعهد بعدم إفشاء هذه المعلومات لأي طرف ثالث مالم
            يكن هناك إذن مسبق أو تنفيذ لأمر أو إجراء قضائي أو لاستخدامها من قبل
            منصة دال والشركات التابعة لها لغرض إدارة الأعمال التشغيلية والتنظيم
            والتسويق. 3-3 يتم نشر معلومات اتصال المستخدم حسب ما هو موضح في صفحة
            العرض، ليتمكن الطرف الآخر من الاتصال به وإتمام العملية التجارية. 4-3
            أما المعلومات السرية المذكورة في صفحة تحديث معلوماتك، أو معلومات
            استخدام التطبيق، أو المعلومات الأخرى التي تصلنا من أي طرف ثالث، فإن
            التطبيق يجمعها بهدف التحديث والتطوير والتحسين ولتنفيذ العمليات
            الداخلية منها على سبيل المثال لا الحصر منع الاحتيال والمشاكل
            المرتبطة بالبرمجيات والتشغيل وتحليل البيانات والاختبارات والأبحاث
            ومراقبة اتجاهات الأنشطة، ويلتزم بالحفاظ على سريتها.
          </li>
          <li>
            معلومات الموقع الخاص بك 1-4 يطلب تطبيق دال الإذن لكي يقوم بتجميع
            معلومات دقيقة عن موقعك من جهازك وفقاً لنظام الأذونات المستخدم من قبل
            نظام تشغيل الهواتف الذكية. 2-4 يمكنك تعطيل هذا الإذن لاحقاً من خلال
            تغيير إعدادات جهازك، ونلفت انتباهك إلى أن هذا التعطيل سوف يحد من
            قدرتك على الاستفادة من خصائص محددة لخدمات التطبيق.
          </li>
          <li>
            الحساب الخاص بك 1-5 عند تسجيلك في منصة دال فإنك توافق على تقديم
            معلومات صحيحة وكاملة ودقيقة مع الالتزام بتحديثها بين حين لآخر، كما
            أنك توافق على تخزين منصة دال لبيانات التسجيل والاحتفاظ بها في حسابك.
            2-5 يراعى عند التسجيل عدم استخدام أسماء غير لائقة أو حروف وأرقام
            مبهمة، ولا يُسمح لأي عضو بالحصول على أكثر من عضوية واحدة بدون إبلاغ
            منصة دال بذلك. 3-5 ينبغي عدم الإفصاح عن أية معلومات خاصة بحسابك لأي
            أحد آخر فأنت وحدك المسؤول عن الحفاظ على سرية وأمن حسابك وكل ما يتم
            فيه من عمليات أو من خلاله. 4-5 للاستفادة من الخدمات المقدمة بالتطبيق
            يتعين عليك تسجيل الدخول إلى حسابك مرة كل ثلاث سنوات على الأقل. ويحق
            لـ منصة دال القيام بإلغاء أو تعليق الحسابات الغير مفعلة. 5-5 في حال
            إلغاء الحساب لن تكون قادراً على استرداد المحتوى الخاص بك. 6-5 بموجب
            هذه الاتفاقية تتعهد بإبلاغ منصة دال بأي إخلال بأمن حسابك، وتخلي منصة
            دال مسؤوليته عن أية خسائر تنشأ عن الاستخدام غير المصرح به لحسابك.
            7-5 يحق لـ منصة دال طلب أي مستند سواء كان رسمي أو غير رسمي يمكنه من
            التحقق من هويتك. 8-5 يحق لـ منصة دال تعليق الحساب إذا تبين له
            استخدامه من قبل طرف ثالث عن طريق الاحتيال، ويحق للعضو طلب استعادته
            فيما بعد. 9-5 يحق لـ منصة دال أن يضع قيوداً على استخدامك لكل أو بعض
            مميزات الحساب متى ما رأى أي تصرف أو تقصير من قبلك دون إخطار أو أدنى
            مسؤولية عليه.
          </li>
          <li>
            المسؤولية والتعويض 1-6 بموجب هذه الاتفاقية تقر بأنك شخص طبيعي قد بلغ
            السن القانونية لإبرام اتفاقية ملزمة، أو أنك شخص مفوض قانوناً لإبرام
            الصفقات نيابةً عن أي كيان اعتباري أو شخص طبيعي، كما تقر وتوافق أن
            استخدام التطبيق يكون على مسؤوليتك وحدك. 2-6 بموجب هذه الاتفاقية
            تتعهد بأن العروض المقدمة من قبلك خالية من أي نزاع أو رهن أو حقوق
            أخرى قد تمنع أو تعطل أو تقيد أو تؤثر في حق المستخدم الآخر الراغب في
            إتمام الصفقة التجارية. 3-6 بموجب هذه الاتفاقية توافق على إبراء ذمة
            منصة دال من أي التزامات أو مطالبات قد تلحق به نتيجة قيامك بأي تصرف
            من شأنه ترتيب ضرر بأي شكل من الأشكال بالتطبيق أو المستخدمين أو أي
            طرف آخر ويشمل ذلك إبراء ذمة منصة دال على سبيل المثال لا الحصر من
            مصروفات الدعاوى القضائية والتحكيمية واتعاب المحاماة وأي مصروفات أو
            تكاليف قد تترتب على منصة دال نتيجة هذا التصرف. 4-6 بموجب هذه
            الاتفاقية توافق على إبراء ذمة منصة دال من أي التزامات أو مطالبات قد
            تلحق بك نتيجة إخلالك بالالتزام بالشروط والأحكام الواردة في هذه
            الاتفاقية أو أي التزام آخر ينشأ من خلالها. 5-6 توافق على تعويض منصة
            دال عن أية خسائر أو أضرار أو تكاليف أو مصروفات تتحملها منصة دال
            نتيجة قيامك بأي تصرف ينتهك على سبيل المثال لا الحصر أية قوانين أو
            أنظمة أو أعراف أو مبادئ أو حقوق معتبرة بما في ذلك الشروط والأحكام
            الواردة في هذه الاتفاقية. 6-6 بموجب هذه الاتفاقية تتعهد بأن جميع
            البيانات والمعلومات المضافة من قبلك صحيحة وكاملة ودقيقة وأنك المسؤول
            الوحيد عما يترتب على هذه البيانات من تصرفات أو إخلال بالالتزامات،
            ويحق لـ منصة دال في جميع الأوقات التحقق من المعلومات التي يقدمها
            المستخدم كما يحق لـ منصة دال رفض العرض أو استخدام التطبيق دون إبداء
            الأسباب أو أدنى مسؤولية على منصة دال 7-6 تخلي منصة دال مسؤوليته عن
            الأضرار المترتبة على عدم صحة محتويات التطبيق والبيانات المقدمة من
            المستخدمين سواءً تضمنت هذه المحتويات معلومات أو بيانات خاطئة أو
            ناقصة أو مسيئة أو غير لائقة أو عيوب أو فيروسات أو برامج ضارة ما لم
            يكن هذا الضرر ناشئ عن سلوك عمدي أو إهمال جسيم من قبل منصة دال ، كما
            لا تعتبر محتويات بمثابة توصية أو تأييد أو كفاءة لأي من العروض
            المقدمة. 8-6 لا يمكنك الاستفادة من خدمات التطبيق إلا باستخدام وسائل
            مصرح بها، ويقع على مسؤوليتك التحقق من تثبيت التطبيق الصحيح على
            جهازك، وتخلي منصة دال مسؤوليته في حال مخالفتك لهذا الالتزام من خلال
            استخدامك لجهاز غير متوافق أو غير مصرح به أو تثبيتك لنسخة غير صحيحة
            من التطبيق. 9-6 لا تضمن منصة دال أن استخدامك للتطبيق سيتم بدون
            انقطاع أو خالياً من الأخطاء، وتوافق على أحقية منصة دال في إزالة أي
            من الخدمات بشكل مؤقت أو دائم وفي أي وقت دون إشعارك. 10-6 تمتد عدم
            مسؤولية منصة دال لتشمل المديرين والمسؤولين والموظفين فيه والشركات
            التابعة له ووكلائه وموكليه ومقاوليه وأي شخص آخر يتم الترخيص له من
            قبل منصة دال
          </li>
          <li>
            التعاقد الالكتروني 1-7 استخدامك للتطبيق يعني موافقتك على ابرام
            الاتفاقيات واجراء المعاملات وإدارة المفاوضات الكترونياً. 2-7 تقر بأن
            كل ما تقدمه من عروض ومعلومات تشكل موافقتك على الالتزام بتلك
            الاتفاقيات والمعاملات وما يترتب عليها. 3-7 تتم كافة العمليات
            التجارية في منصة دال من (بيع / شراء / تأجير) بين أطراف العملية
            التعاقدية مباشرة وبدون أدنى مسؤولية من قبل منصة دال ، ولا يُسمح
            بالعروض المبهمة غير الواضحة أو غير الكاملة أو غير التجارية.
          </li>
          <li>
            سياسة حقوق الطبع والنشر 1-8 تحترم منصة دال القوانين المنظمة لحقوق
            الطبع والنشر وتتوقع من مستخدميها احترامها والعمل بموجبها. 2-8 يحق لـ
            منصة دال إنهاء حسابات المستخدمين الذين ينتهكون على سبيل المثال لا
            الحصر قوانين حقوق الطبع والنشر أو حقوق المستخدمين أو الشروط والأحكام
            الواردة في هذه الاتفاقية. 3-8 يحق لـ منصة دال حجب و/أو إزالة أي
            محتوى كلياً أو جزئياً يرى أنه غير متوافق مع الشروط والأحكام الواردة
            في هذه الاتفاقية، ويشمل ذلك على سبيل المثال لا الحصر التدليس في
            القيمة المالية للعروض المقدمة، أو أي انتهاكات لأي قوانين أو أنظمة أو
            أعراف أو مبادئ أو حقوق معتبرة. 4-8 أنت توافق على ابلاغ منصة دال
            خطياً وبشكل فوري عن أي محتوى ينتهك خصوصية عروضك أو شروط المستخدم وأن
            تقدم معلومات كافية وصحيحة تمكن منصة دال من التقصي عن ذلك، وتلتزم
            منصة دال ببذل العناية في التحقق من أي شكوى ترده واتخاذ ما يراه
            مناسباً من إجراءات وفقاً لتقديره، علماً أن ورود الشكوى والتحقيق فيها
            لا يُعد ملزماً لـ منصة دال بحجب أو إزالة المحتوى كلياً أو جزئياً.
          </li>
          <li>
            الملكية الفكرية 1-9 تملك منصة دال والشركات التابعة له أو الشركات
            التي يتبعها كافة سندات وحقوق الملكية والتصرف والانتفاع، وتشمل هذه
            الحقوق على سبيل المثال لا الحصر حقوق الملكية الفكرية للتطبيق
            والخدمة، الاقتراحات والأفكار والتوصيات والطلبات والرسوم التصويرية
            والواجهة البينية للمستخدم والمقاطع المرئية والمحتوى التحريري والنصوص
            والبرمجيات المستخدمة لتنفيذ الخدمة وغير ذلك من المعلومات المتعلقة
            بالتطبيق والمقدمة من المستخدمين أو أي طرف آخر. 2-9 الشروط والأحكام
            الواردة في هذه الاتفاقية لا تمثل بيعاً ولا تنقل إليك أية حقوق ملكية
            سواء أكانت فكرية أو غيرها. 3-9 أنت تقر بأن الملكية الفكرية لهذا
            التطبيق تعتبر ملكية خالصة لـ منصة دال وتشمل هذه الملكية على سبيل
            المثال لا الحصر اسم التطبيق وشعاره والخدمات التي يقدمها كما أنها
            تُعد علامة تجارية لـ منصة دال والشركات التابعة له. 4-9 لم يمنح منصة
            دال أي حق أو ترخيص باستخدام التطبيق في غير الأغراض المعدة له
            والموضحة في أحكام وشروط هذه الاتفاقية لذا تتعهد بأنك لن تقوم بالآتي:
            5-9 نسخ التطبيق أو أي محتوى آخر يتعلق بـ منصة دال أو توزيعه أو
            استغلاله بأي وسيلة كانت دون الحصول على الموافقة الخطية المسبقة من
            منصة دال 6-9 تعديل التطبيق او اشتقاق أعمال بناء عليه أو انشاء
            ارتباطات او صياغة او عكس أي تطبيق على خادم آخر أو جهاز لا سلكي آخر
            او غير ذلك. 7-9 هندسة التطبيق عكسياً أو الوصول إليه من أجل: أ‌.
            تصميم و/أو إنشاء تطبيق أو خدمة منافسة. ب‌. تصميم و/أو إنشاء تطبيق
            يستخدم ذات الخدمات أو الأفكار أو المزايا أو الوظائف أو غير ذلك مما
            يعتبر ملكية خاصة لـ منصة دال ت‌. نسخ أية خدمات أو أفكار أ مزايا أو
            وظائف أو محتويات تخص التطبيق. 8-9 تُعد المخالفة لأحكام هذا البند خرق
            وانتهاك مادي لحقوق الملكية الفكرية لـ منصة دال ويحق لهذا الأخير
            مقاضاة المنتهك وفقاً لما ورد ببند القوانين المعمول بها / حل النزاعات
            من هذه الاتفاقية وطلب التعويض.
          </li>
          <li>
            سياسة الدفع 1-10 استخدام التطبيق مجاني ويحتفظ منصة دال بالحق في فرض
            رسوم جديدة على استخدام التطبيق وتحصيل أية رسوم أو مصاريف أو ضرائب أو
            تكاليف أخرى تكون مقررة عليك، كما يحق لـ منصة دال تحديد ومعالجة طرق
            السداد لضمان تقديم الخدمة، وفي هذه الحالة يجب على منصة دال اطلاعك
            على ذلك والسماح لك بالاستمرار في استخدام التطبيق أو انهائه.
          </li>
          <li>
            أحكام عامة 1-11 بمجرد استخدامك للتطبيق أو الخدمات فإنك توافق على ما
            يلي: أ‌. عدم استخدام التطبيق أو تثبيته إلا للاستخدام الحصري والشخصي
            والتجاري وللأغراض المشروعة وتتعهد بعدم إعادة بيعه للغير أو استخدامه
            من الباطن أو نقله أو التنازل عنه أو توزيعه أو الاستغلال التجاري له
            أو تمكين الغير من استخدامه بأي وسيلة كانت أو استخدامه بطرق احتيالية
            أو القيام بأي تصرف من شأنه إلحاق الضرر بأي شكل من الأشكال بالتطبيق.
            ب‌. عدم استخدام حساب يتبع لشخص آخر أو الترخيص للآخرين باستخدام حسابك
            أو التنازل عن الحساب أو تحويله لأشخاص أو كيانات قانونية أخرى. ت‌. إن
            مجرد استخدامك لحساب يتبع لشخص آخر يعني أنك توافق تماماً على جميع
            الشروط والأحكام الواردة في التطبيق وتسري هذه الشروط والأحكام على
            جميع تعاملاتك، دون إخلال بقيام المسؤولية على صاحب الحساب الأصلي. ث‌.
            عدم القيام بأي تصرف قد يتسبب بالضرر على التطبيق و/أو عمل الشبكة على
            النحو السليم بما في ذلك على سبيل المثال لا الحصر الاضرار بالخدمات
            المقدمة للمستخدمين أو أذونات التطبيق بأي وسيلة كانت. ج‌. الالتزام
            بجميع القوانين المعمول بها بمكان اقامتك أثناء استخدام التطبيق أو
            الخدمة. 2-10 تكون كافة الشروط الواردة في هذه الاتفاقية مكمله لبعضها
            البعض، وإذا اعتبرت أي مادة أو بند أو شرط من مواد أو بنود أو شروط هذه
            الاتفاقية باطلاً أو أصبح كذلك، فإن بطلان هذه المادة أو البند أو
            الشرط لا يؤثر على صحة ونفاذ باقي مواد وبنود وشروط هذه الاتفاقية،
            ويحق لـ منصة دال في هذه الحالة تصحيح البند الباطل ليتوافق مع الأنظمة
            والقوانين واللوائح والقواعد والمبادئ ذات العلاقة متى ما كان ذلك
            مناسباً. 3-10 لا يعني عدم قيام منصة دال بتنفيذ أي شرط أو حكم من شروط
            وأحكام هذه الاتفاقية تنازلاً منه عن ذلك الشرط أو الحكم.
          </li>
          <li>
            مدة الاتفاقية / الانهاء 1-12 هذه الاتفاقية غير محددة بمدة معينة،
            ويحق لك إنهاؤها في أي وقت وذلك من خلال حذف التطبيق حذفاً نهائياً أو
            اغلاق الحساب من خلال اتباع التعليمات الموضحة بالتطبيق، وبالتالي لن
            تكون قادراً على استرداد المحتوى الخاص بك فيما بعد. 2-11 يحق لـ منصة
            دال الانهاء الفوري لاستخدام التطبيق والخدمة دون الحاجة لإشعارك بذلك
            في حال إساءتك لاستخدام التطبيق أو انتهاكك لأي من الشروط والأحكام
            الواردة بالاتفاقية أو القوانين والأنظمة والأعراف والمبادئ أو حقوق
            المستخدمين وغيرهم ممن تؤثر العروض المقدمة على مصالحهم. 3-11 بعد
            الانهاء، يبقى المستخدم مسؤولاً عن جميع الالتزامات والمطالبات التي
            نشأت أو قد تنشأ عن هذه الاتفاقية أو بسببها.
          </li>
          <li>
            تعديل شروط الخدمة / الاتفاقية 1-13 يحق لـ منصة دال تحديث وتعديل
            التطبيق وأحكام الاتفاقية وفقاً لتقديره سواء بالتغيير أو الإضافة أو
            الحذف أو التعليق أو الايقاف متى ما رأى ذلك مناسباً وبدون إبداء
            الأسباب، ويتم اخطارك بأحد الطرق المنصوص عليها في هذه الاتفاقية وتعد
            هذه التحديثات سارية ومنتجة لآثارها بمجرد ارسالها، ولا يتحمل منصة دال
            أية مسؤولية تجاهك أو تجاه أي من الغير. 2-12 ننصحك بمراجعة الشروط
            والأحكام بشكلٍ دائم للاطلاع على أي تحديثات أو تغييرات قد تطرأ عليها.
          </li>
          <li>
            الاخطارات والاشعارات 1-14 يتم العلم بالاخطارات والاشعارات عن طريق أي
            من الوسائل التالية: أ‌. اخطار عام بالتطبيق. ب‌. بريد الكتروني إلى
            عنوانك البريدي المسجل في معلومات حسابك الخاص بالتطبيق. ت‌. مكاتبة
            بالبريد العادي على عنوانك المسجل في معلومات حسابك الخاص بالتطبيق.
            ث‌. الاطلاع على آخر التعديلات والتحديثات بقسم الشروط والأحكام. 2-14
            استمرارك باستخدام التطبيق بعد الاخطار يُعد موافقة منك على التغييرات،
            وفي حال عدم موافقتك على التغييرات عليك الالتزام بالتوقف عن استخدام
            التطبيق فوراً واغلاق الحساب.
          </li>
          <li>
            القوانين المعمول بها / حل النزاعات تخضع أحكام وشروط هذه الاتفاقية
            لـقوانين المملكة العربية السعودية، وهي القوانين المعتمدة في فض أي
            نزاعات أو مطالبات محلية كانت أو دولية. وإذا نشأ أي خلاف فيما يتعلق
            بتنفيذ بنود هذه الاتفاقية أو تفسيرها يتم بذل غاية واسعة لحله ودياً،
            فإن تعذر ذلك يُحال النزاع إلى محكمة [●] وهي المحكمة المختصة بتسوية
            كافة النزاعات والمطالبات والخلافات التي تنشأ عن انتهاك هذه الاتفاقية
            أو انهائها أو تنفيذها أو تفسيرها أو صحتها أو بسبب استخدام التطبيق.
          </li>
          <li>
            اللغة: تعتبر اللغة العربية هي اللغة الرسمية والمعتمدة في صياغة
            اتفاقية شروط الخدمات وهي النص الأصلي الوحيد، وقد تكون هناك نسخ
            مختلفة من هذه الشروط والأحكام مترجمةً بلغات أخرى لا يمكن الاعتماد
            عليها باستخلاص الحقوق والواجبات الواردة فيها في حال وجود أي تعارض أو
            اختلاف بين النص باللغة العربية وترجمته إلى احدى اللغات الأجنبية.
          </li>
          <li>
            اتصل بنا: إذا كانت تراودك أيه استفسارات حول الشروط والأحكام الواردة
            بهذه الاتفاقية ولمزيد من الاقتراحات والتعليقات بإمكانك مراسلتنا على
            البريد الالكتروني: [●] أو كتابياً على العنوان [●].
          </li>
        </ol>
      </div>
    </Container>
  )
}
