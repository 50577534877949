import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

import { getCountryDetails } from '../helpers/helpers'
import { useStyles } from './StyledCodeButton'
import { CodeButtonProps } from './CodeButton.interface'

export const CodeButton = ({
  selectedCountry,
  handleOpenMenu,
  handleCloseMenu,
  isMenuOpen,
}: CodeButtonProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div
      className={classes.CodeButtonWrapper}
      style={{ direction: 'ltr' }}
      onClick={(e) => (isMenuOpen ? handleCloseMenu() : handleOpenMenu(e))}
    >
      {selectedCountry &&
        getCountryDetails({ key: 'dial_code', value: selectedCountry })
          .dial_code}{' '}
      {isMenuOpen ? (
        <ArrowDropUpIcon className={classes.biggerArrows} />
      ) : (
        <ArrowDropDownIcon className={classes.biggerArrows} />
      )}
    </div>
  )
}
