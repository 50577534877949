export const confirmButtonProps = {
  style: {
    width: '100%',
  },
  children: 'تحقق و تسجيل',
}

export const resetButtonProps = {
  style: {
    width: '30%',
    color: 'black',
    backgroundColor: '#F5F5F5',
  },
  children: 'تحقق لاحقا',
}
