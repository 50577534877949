import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: '24',
    p: 4,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    '&:focus': {
      border: 'none',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: '#CECECE',
    cursor: 'pointer',
  },
  warningIconWrapper: {
    display: 'grid',
    justifyContent: 'center',
    margin: theme.spacing(3),
  },
  warningIcon: {
    color: '#FFB200',
    width: 74,
    height: 74,
  },
  profileContainer: {
    border: '1px solid #D6D6D6',
    borderRadius: '15px',
    display: 'flex',
    padding: '10px',
    marginInlineStart: '20px',
    position: 'relative',
  },
  profileIcon: {
    cursor: 'pointer',
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',

    marginInlineStart: '20px',
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 14,
    cursor: 'pointer',
    '&:last-child': {
      //  marginTop: theme.spacing(1.25),
      fontWeight: 600,
    },
  },
  ProfilerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  warningMsgStyle: { textAlign: 'center', fontSize: '20px' },
  drawerRoot: {
    '& .MuiPaper-root': {
      borderRadius: '10px 10px 0 0',
      padding: 5,
    },
  },
  drawerTitle: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 15,
    paddingBlock: 30,
  },
  actionButton: {
    cursor: 'pointer',
    marginBlockEnd: 40,
  },
}))
