import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    width: '100%',
    display: 'flex',
    direction: 'rtl',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  titleWrapper: {
    gap: theme.spacing(0.5),
    direction: 'ltr',
  },
  linkStyles: {
    color: theme.palette.common.black,
  },
  headerTitle: {
    fontWeight: 'inherit',
    margin: 0,
    color: theme.palette.common.black,
    display: 'inline-block',
  },
  checkboxAdjustments: {
    transform: 'scale(1.3)',
  },
}))
