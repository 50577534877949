import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    // maxWidth: 345,
    width: '100%',
    position: 'absolute',
    height: '100%',
    minHeight: '48px',

    top: -24,
    right: 0,
  },
  cardRoot: {
    padding: '1rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: '10px',
    marginTop: '5px',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  modifyMenuHeight: {
    height: 'auto',
    border: '1px solid #DFDFDF',
  },
  collapsContent: {
    padding: '0 0 !important' as '0',
    position: 'relative',
    zIndex: 100000000000,
    backgroundColor: '#ffffff',
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: '50px',
    cursor: 'pointer',
    '&:last-child': {
      marginTop: theme.spacing(1.25),
      fontWeight: 600,
    },
  },
}))
