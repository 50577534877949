import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  drawerRoot: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: 0,
      transition: 'height 0.3s ease-in-out',
      overflow: 'hidden',
    },
  },
  initialHeight: {
    '& .MuiDrawer-paperAnchorBottom': {
      height: 45,
    },
  },
  halfHeight: {
    '& .MuiDrawer-paperAnchorBottom': {
      height: 'calc(50vh - 80px)',
    },
  },
  fullHeight: {
    '& .MuiDrawer-paperAnchorBottom': {
      height: 'calc(100vh - 80px)',
    },
  },
  drawerHeader: {
    height: 45,
    boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.10)',
    paddingInline: theme.spacing(2, 1),
    paddingBlock: theme.spacing(0.75),
  },
  drawerTitle: { fontSize: 12, fontWeight: 700 },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  adsContainer: {
    backgroundColor: '#F2F2F2',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}))
