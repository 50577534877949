import React from 'react'

import { Typography, Container, Button } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { NoSearchResult } from 'assets'
import { useStyle } from './NoSearchAds.style'

export const NoSearchAds = () => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Container>
      <div className={classes.centerImage}>
        <NoSearchResult />
        <Typography className={classes.myads}>
          {t(
            'There are no results for your search, try changing your search terms or location'
          )}
        </Typography>
      </div>
    </Container>
  )
}
