import { IFlintConfig } from '@flint/types'
import { useLocation as router } from 'react-router-dom'
import { resources as i18lResources } from './src/global'
import { plainReducers } from './src/store/states'

export const flintConfig = (): IFlintConfig => ({
  reducers: plainReducers,
  i18l: {
    resources: i18lResources,
  },
  auth: {
    scopes: process.env.REACT_APP_AUTH_SCOPE as string,
    domain: process.env.REACT_APP_AUTH_DOMAIN as string,
    tokenPath: process.env.REACT_APP_AUTH_TOKEN_PATH as string,
    clientId: process.env.REACT_APP_AUTH_WEB_CLIENT_ID as string,
    authorizePath: process.env.REACT_APP_AUTH_AUTHORIZE_PATH as string,
    redirectUri: process.env.REACT_APP_AUTH_WEB_CALLBACK_URL as string,
    revokePath: process.env.REACT_APP_AUTH_REVOKE_PATH as string,
    accountsBaseUrl: process.env.REACT_APP_ACCOUNTS_BASE_URL as string,
  },
  layout: {
    theme: {
      palette: {
        primary: {
          main: '#662D91',
          light: '#FAF4FF',
        },
        secondary: {
          main: '#CA2D78',

          contrastText: '#fff',
        },
      },
      typography: {
        h1: {
          fontFamily: 'Almarai',
          fontSize: '20px',
          color: '#000000',
          fontWeight: 700,
          paddingBlock: '15px',
          lineHeight: '29px',
        },
        h2: {
          fontFamily: 'Almarai',
          fontSize: '16px',
          fontWeight: 700,

          lineHeight: '29px',
        },
        h3: {
          fontFamily: 'Almarai',
          fontSize: '14px',
          lineHeight: '29px',
          color: '#656565',
          fontWeight: 700,
        },
        h4: {
          fontFamily: 'Almarai',
          fontSize: '13px',
          lineHeight: '29px',
          fontWeight: 700,
        },
      },
    },
  },
  graphQl: {
    uri: process.env.REACT_APP_GRAPHQL_URL as string,
  },
  router,
})

export default flintConfig
