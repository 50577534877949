import { FC } from 'react'
import { Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import clsx from 'clsx'
import { BedIcon, BathIcon, StairsIcon, SurfaceIcon } from 'assets'
import { dateFormat, formatPrice } from 'utils/base'

import { useStyle } from './MPropertyDetailsCard.style'

export const MPropertyDetailsCard: FC<any> = ({ details = {} }) => {
  const classes = useStyle()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const dateAdded = details.date_added
    ? dateFormat(details.date_added)
    : t('unavailable')
  const apartmentDetails = [
    {
      title: 'rooms',
      value: details.number_of_rooms,
      icon: <BedIcon />,
    },
    {
      title: 'bathroom',
      value: details.bathrooms_count,
      icon: <BathIcon />,
    },
    {
      title: 'floors',
      value: details.floor_number,
      icon: <StairsIcon />,
    },
    {
      title: 'area',
      value: details.unit_area,
      icon: <SurfaceIcon />,
    },
  ]
  return (
    <Grid className={classes.root} container direction="column">
      <Grid item container className={classes.cardHeader}>
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item container alignItems="center" xs={12}>
            {details.ad_type === 'investment' ? (
              <Typography align="center" className={classes.investmentTitle}>
                {t(`${details.real_estate_type} for ${details.ad_type}`)}
              </Typography>
            ) : (
              <>
                <Typography
                  color={details.ad_type === 'sale' ? 'primary' : 'secondary'}
                  align="center"
                  className={classes.priceDetails}
                >
                  {formatPrice(details.price || 0)}
                </Typography>
                <Typography> {t(details?.price_unit)}</Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          item
          xs={6}
        >
          <Grid
            item
            className={clsx(classes.propertyType, {
              [classes.saleAd]: details.ad_type === 'sale',
              [classes.rentAd]: details.ad_type === 'rent',
              [classes.investmentAd]: details.ad_type === 'investment',
            })}
          >
            <Typography className={classes.adType} align="center" noWrap>
              {t(`${details?.ad_type}`)}
            </Typography>
          </Grid>
          <Grid item className={classes.realstateType}>
            <Typography align="center" noWrap>
              {t(details?.real_estate_type)}
              {details?.main_use && ` / ${t(details?.main_use)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Typography className={classes.addressDetails}>
          {details.address || t('No address added')}
        </Typography>
      </Grid>
      <Grid>
        <Typography className={classes.advertiseDescription}>
          {details.advertisement_text || t('No description added')}
        </Typography>
      </Grid>

      <Grid container alignItems="center" className={classes.cardBody}>
        {details.real_estate_type === 'apartment' ? (
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-around"
            item
            xs={12}
          >
            {apartmentDetails.map(({ title, value, icon }, i) => (
              <Grid
                key={i}
                className={classes.cardIconContainer}
                item
                container
                xs={3}
                alignItems="center"
              >
                <Grid className={classes.cardIcon}>{icon}</Grid>
                <Grid>
                  <Typography className={classes.iconText}>
                    {t(title)}
                  </Typography>
                  <Typography>{value || t('unavailable')}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container alignItems="flex-start" item sm={8} xs={12}>
            <Grid
              className={classes.cardIconContainer}
              item
              container
              xs={6}
              sm={3}
              alignItems="center"
            >
              <Grid className={classes.cardIcon}>
                <SurfaceIcon />,
              </Grid>
              <Grid>
                <Typography className={classes.iconText}>
                  {t('area')}
                </Typography>
                <Typography>{details.area || t('unavailable')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid className={classes.datesContainer} container alignItems="center">
        <Grid container item xs={6} justifyContent="center">
          <Typography className={classes.date}>
            {t('created at')}&nbsp;{' '}
          </Typography>
          <Typography color="secondary" className={classes.date}>
            {dateAdded}
          </Typography>
        </Grid>

        <Grid container item xs={6} justifyContent="center">
          <Typography className={classes.date}>
            {t('expire at')}&nbsp;{' '}
          </Typography>
          <Typography color="secondary" className={classes.date}>
            {t(dateFormat(details.expiration_date)) || t('unavailable')}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Typography className={classes.advertiserData}>
          {t('advertiser name')}
        </Typography>
        <Typography>{details.issuer_full_name}</Typography>
      </Grid>
    </Grid>
  )
}
