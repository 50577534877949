import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    paddingBlock: '1rem',
  },
  mobileRoot: { marginBottom: 50 },
  leftContainer: {
    border: '1px solid #DFDFDF;',
    borderRadius: '1rem',
    paddingBlock: theme.spacing(4, 2),
    alignSelf: 'flex-start',
    marginTop: 16,
  },
  rightContainer: {
    paddingInlineEnd: theme.spacing(2),
    borderRadius: '1rem',
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      paddingInlineEnd: theme.spacing(0),
    },
  },
  callButtonContainer: {
    width: '100%',

    borderTop: '1px solid #DFDFDF;',
    paddingBlock: theme.spacing(2, 0),
    paddingInline: theme.spacing(2),
    marginBlockStart: theme.spacing(2),
    '& .MuiButton-root': {
      borderRadius: '10px',
    },
  },

  avatarImage: {
    verticalAlign: 'middle',
    width: '8.4rem',
    height: '8.4rem',
    borderRadius: '50%',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#fff',
  },
  actionsButtonContainer: {},
  shareButton: {
    backgroundColor: '#636363',
    paddingBlock: 10,

    cursor: 'pointer',
    marginBlock: 15,

    fontWeight: 700,
    borderRadius: 50,
    boxShadow: '0px 8px 14px 0px rgba(0, 0, 0, 0.3)',
    '& p': {
      fontSize: 14,
      color: '#FFF',
      marginLeft: 12,
      backgroundColor: 'transparent',
    },
  },
  callButton: {
    backgroundColor: theme.palette.secondary.main,
    paddingBlock: 10,

    cursor: 'pointer',
    marginBlock: 15,

    fontWeight: 700,
    borderRadius: 50,
    boxShadow: '0px 8px 14px 0px rgba(0, 0, 0, 0.3)',
    '& p': {
      fontSize: 14,
      color: '#FFF',
      marginLeft: 12,
      backgroundColor: 'transparent',
    },
  },
  toolTip: {
    fontSize: 12,
    fontFamily: 'Almari',
  },
  pageFooter: {
    color: '#000',
    fontFamily: 'Almarai',
    fontSize: 10,
    fontWeight: 700,
    marginBlockEnd: theme.spacing(1),
  },
}))
