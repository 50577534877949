import React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Link } from '@material-ui/core'
import { useStyles } from './StyledSavedAdvertised'

export const SavedAdvertiseWidget = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <InfoOutlinedIcon className={classes.infoIconWrapper} />
      <div>
        سوف يتم حفظ إعلانك لكن بسبب
        <Link
          href="https://rega.gov.sa/"
          target="_blank"
          className={classes.anchoreTag}
        >
          شروط و قوانين الهيئة العامة للعقار
        </Link>
        لابد من وجود رقم ترخيص من اجل نشر الإعلان.
      </div>
    </div>
  )
}
