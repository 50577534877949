import React, { FC } from 'react'
import { Grid, Drawer, Typography, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from '@flint/locales'
import { SearchResultsMap, CardSkeleton, MAdvertiseCard } from 'components'

import { CloseIcon, ExpandIcon } from 'assets'
import { useStyle } from './MCardsDrawerMap.style'

export const MCardsDrawerMap: FC<any> = ({
  advertises = [],
  advertisesLoader,
  adsCount,
}) => {
  const [expand, setExpand] = React.useState('initial')
  const classes = useStyle()
  const { t } = useTranslation()
  const getLoaderSkelatons = () => {
    return (
      <Grid item container>
        {Array.from(new Array(20)).map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <CardSkeleton />
          </Grid>
        ))}
      </Grid>
    )
  }
  const handleExpandClick = () => {
    if (expand === 'initial') {
      setExpand('half')
    } else if (expand === 'half') {
      setExpand('full')
    } else {
      setExpand('initial')
    }
  }
  return (
    <Grid>
      <Grid item>
        <SearchResultsMap
          advertises={advertises}
          advertisesLoader={advertisesLoader}
        />
      </Grid>
      <Grid>
        <Drawer
          className={clsx(classes.drawerRoot, {
            [classes.initialHeight]: expand === 'initial',
            [classes.halfHeight]: expand === 'half',
            [classes.fullHeight]: expand === 'full',
          })}
          variant="persistent"
          anchor="bottom"
          open
        >
          <Grid
            className={classes.drawerHeader}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {expand !== 'initial' ? (
                <Typography className={classes.drawerTitle}>
                  {`${t('show')} ${advertises?.length} ${t(
                    'from'
                  )} ${adsCount} ${t('result')}`}
                </Typography>
              ) : (
                <Typography className={classes.drawerTitle}>
                  {`${t('have found')} “ ${adsCount} “ ${t('result')}`}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]:
                    expand === 'half' || expand === 'initial',
                })}
                onClick={handleExpandClick}
                color="inherit"
                aria-label="close drawer"
                edge="start"
              >
                <ExpandIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid className={classes.adsContainer}>
            {advertises.map(({ data, id }) => (
              <MAdvertiseCard key={id} advertise={{ ...data, id }} />
            ))}
          </Grid>
        </Drawer>
      </Grid>
    </Grid>
  )
}
