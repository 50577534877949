import { FC, useState } from 'react'
import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

import {
  SearchResultsMap,
  CardsListMap,
  SearchResultsCardsList,
  SearchResultsBar,
  MCardsDrawerMap,
} from 'components'
import { useStyle } from './SearchResults.style'

export const SearchResults: FC<any> = () => {
  const [displayMethod, setDisplayMethod] = useState('map and list')
  const { advertiser } = useSelector((state: RootState) => state)
  const { publishedAdvertises, advertisesLoader } = advertiser
  const classes = useStyle()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const adsCount = publishedAdvertises.Count
  const displayContent = () => {
    switch (displayMethod) {
      case 'map only':
        return (
          <SearchResultsMap
            advertises={publishedAdvertises.advertises}
            advertisesLoader={advertisesLoader}
          />
        )

      case 'list only':
        return (
          <SearchResultsCardsList
            viewRatio={3}
            advertises={publishedAdvertises.advertises}
            advertisesLoader={advertisesLoader}
          />
        )
      case 'map and list':
        return (
          <CardsListMap
            advertises={publishedAdvertises.advertises}
            advertisesLoader={advertisesLoader}
          />
        )
      default:
        break
    }
  }

  return (
    <Container className={classes.root}>
      <SearchResultsBar
        adsCount={adsCount}
        setDisplayMethod={setDisplayMethod}
      />
      {isMobileScreen ? (
        <MCardsDrawerMap
          advertises={publishedAdvertises.advertises}
          advertisesLoader={advertisesLoader}
          adsCount={adsCount}
        />
      ) : (
        displayContent()
      )}
    </Container>
  )
}
