import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { useTranslation } from '@flint/locales'
import Typography from '@material-ui/core/Typography'
import { useNavigate } from 'react-router-dom'
import { CardComponent } from 'components/CardComponent/CardComponent'

import JoinAsAnOrganization from '../../../../assets/images/joinAsAnOrganization.png'
import { useStyles } from './firstStepCardsStyles'

export const FirstCardComponent = (): React.ReactElement => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleJoinAsAnOrganization = () => {
    navigate('/join-as-organization')
  }
  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentWrapper}>
        <CardComponent
          image={JoinAsAnOrganization}
          title={t('join as organization')}
          label={t(
            'Adding the advertisement under the name of an establishment that has a commercial register and is allowed to advertise real estate'
          )}
          imgAlt="join as an organization"
        />
        <Button
          size="small"
          variant="contained"
          fullWidth
          className={classes.ButtonContainer}
          onClick={handleJoinAsAnOrganization}
        >
          {t('register-as-an-organization')}
        </Button>
      </CardContent>
    </Card>
  )
}
