import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    // paddingInline: 15,
    paddingBlockStart: 10,
    marginBlockEnd: '50px',
  },
  cardRoot: {
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '100%',
    // },
    flexWrap: 'wrap',
    margin: 0,
  },
}))
