import { graphQlMutate, graphQlQuery } from '@flint/graphql'
import { toast } from 'react-toastify'
import { Dispatch } from 'react'
import { GetStateFn } from 'store/reducer'

import { graphql } from 'global'
import { setAdvertiser } from './advertiser.actions'

// CREATE NEW Advertiser
const {
  UPDATE_ADVERTISE,
  UPDATE_ADVERTISER,
  CREATE_ADVERTISER,
  FETCH_ADVERTISER,
  CREATE_ADVERTISE,
  FETCH_USER,
  UPLOAD_FILES,
  FETCH_ADVERTISES,
  FETCH_PUBLISHED_ADVERTISES,
  FETCH_ADVERTISE,
  FETCH_ADD_ADVERTISE_SCHEMA,
  FETCH_ADVERTISER_DETAILS,
  FETCH_ADVERTISES_COUNT,
  Validate_Advertise,
  FETCH_DETAILED_ADVERTISE,
} = graphql

export const createAdvertiser = (advertiserForm: any) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    const {
      advertiserCommunication,
      idNumber,
      idType,
      organizationNumber,
      // advertiserNumber,
      validateAdvertiser,
    } = advertiserForm

    const advertiserData = {
      idNumber,
      idType,
      organizationNumber,
      // advertiserNumber,
    }

    try {
      const { data } = await graphQlMutate({
        mutation: CREATE_ADVERTISER as any,
        variables: {
          advertiserCommunication,
          advertiserData,
          validateAdvertiser,
        },
      })
      dispatch(setAdvertiser('advertiser', data.createAdvertiser.advertiser))
      // toast.success('You have registered in successfully')
      dispatch(
        setAdvertiser('successMessage', 'You have registered in successfully')
      )
    } catch ({ graphQLErrors, message }) {
      if (graphQLErrors[0]?.extensions) {
        const errors = graphQLErrors[0]?.extensions?.errors
        Object.values(errors).forEach((err: string[]) => {
          err.map((errMessage) => toast.error(errMessage))
        })
      } else {
        toast.error(message)
      }
    }
  }
}
export const fetchAdvertiser = () => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlQuery({
        query: FETCH_ADVERTISER as any,
      })
      dispatch(setAdvertiser('advertiser', data.advertiser))
    } catch (error) {
      console.error(error)
    }
  }
}

export const fetchUser = () => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlQuery({
        query: FETCH_USER as any,
      })
      setTimeout(() => {
        if (
          data?.userDetails?.advertiser &&
          !getState().advertiser.advertises.Count
        ) {
          dispatch(fetchAdvertisesCount())
        }
      }, 1000)

      const { userDetails } = data
      dispatch(setAdvertiser('userDetails', userDetails))
      dispatch(setAdvertiser('advertiser', userDetails?.advertiser))
    } catch (error) {
      console.error(error)
    }
  }
}

export const uploadAdvertiseFiles = (file: File) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: uploadedFile } = await graphQlMutate({
        mutation: UPLOAD_FILES as any,
        variables: { attachment: file },
      })

      resolve(uploadedFile.uploadAdvertiseImages.url)
    } catch (error) {
      toast.error('something went wrong, please try to upload again')
      reject(error)
    }
  })
}

export const createAdvertise = (formData, navigate, status, adId) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(setAdvertiser('loader', true))
    const data = JSON.stringify(formData)
    const advertiseData = { data: { data, geometry: '' }, status }

    try {
      const { data } = await graphQlMutate({
        mutation: CREATE_ADVERTISE as any,
        variables: { adId, advertiseData },
      })
      toast.success('Your advertise has been submitted successfully')
      navigate('/my-adds')
      dispatch(setAdvertiser('loader', false))
      dispatch(setAdvertiser('addAdvertiseJsonSchema', {}))
      dispatch(setAdvertiser('addAdvertiseUiJsonSchema', {}))
    } catch (error) {
      toast.error('something went wrong, please try again')
    } finally {
      dispatch(setAdvertiser('loader', false))
    }
  }
}

export const fetchAdvertises = (filters = {}, clearStore = true) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlQuery({
        query: FETCH_ADVERTISES as any,
        variables: filters,
      })
      const { userAdvertisesList } = data
      if (clearStore) {
        dispatch(setAdvertiser('advertises', userAdvertisesList))
      } else {
        dispatch(
          setAdvertiser('advertises', {
            ...getState().advertiser.advertises,
            advertises: [
              ...getState().advertiser.advertises.advertises,
              ...userAdvertisesList.advertises,
            ],
          })
        )
      }
      dispatch(setAdvertiser('advertisesLoader', false))
    } catch (error) {
      console.error(error)
    }
  }
}

export const fetchAdvertisesCount = (filters = {}) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlQuery({
        query: FETCH_ADVERTISES_COUNT as any,
        variables: filters,
      })
      const { userAdvertisesList } = data

      dispatch(
        setAdvertiser('advertises', {
          Count: userAdvertisesList?.Count || 0,
          advertises: [],
        })
      )

      dispatch(setAdvertiser('advertisesLoader', false))
    } catch (error) {
      console.error(error)
    }
  }
}

export const fetchAdvertise = (advertiseId: number) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlQuery({
        query: FETCH_ADVERTISE as any,
        variables: { advertiseId },
      })
      const { advertise } = data

      dispatch(
        setAdvertiser('selectedAdvertise', {
          ...advertise,
          data: advertise?.data,
          geometry: advertise?.data?.geometry,
        })
      )
      dispatch(setAdvertiser('publishedAdvertisesLoader', false))
    } catch (error) {
      console.error(error)
    }
  }
}

export const fetchSchemaAsync = (schemaKey: string) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      dispatch(setAdvertiser('loader', true))
      const { data } = await graphQlQuery({
        query: FETCH_ADD_ADVERTISE_SCHEMA,
        variables: { schemaKey },
      })
      const schemas = await data?.schema
      dispatch(setAdvertiser('loader', false))
      dispatch(setAdvertiser('addAdvertiseJsonSchema', schemas.jsonSchema))
      dispatch(
        setAdvertiser('addAdvertiseUiJsonSchema', schemas.webUiJsonSchema)
      )
    } catch (err) {
      console.error(err)
    }
  }
}

export const fetchAdvertiserDetails = (userId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAdvertiser('loader', true))
      const { data } = await graphQlQuery({
        query: FETCH_ADVERTISER_DETAILS,
        variables: { userId },
      })
      dispatch(setAdvertiser('advertiserDetails', data?.userDetails))
      dispatch(setAdvertiser('loader', false))
    } catch (err) {
      console.error(err)
    }
  }
}

export const fetchPublishedAdvertises = (filters = {}, clearStore = true) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    setAdvertiser('publishedAdvertises', {})
    dispatch(setAdvertiser('publishedAdvertisesGeoJson', {}))
    try {
      const { data } = await graphQlQuery({
        query: FETCH_PUBLISHED_ADVERTISES as any,
        variables: filters,
      })
      const { publishedAdvertisesList } = data
      const publishedAdvertisesFeatures = publishedAdvertisesList.advertises
        .filter((ad) => ad.geometry !== null)
        .map((validAd) => ({
          type: 'Feature',
          geometry: validAd.geometry,
          properties: { ...validAd.data },
        }))
      const geoJsonObject = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:3857',
          },
        },
        features: [...publishedAdvertisesFeatures],
      }
      if (clearStore) {
        dispatch(setAdvertiser('publishedAdvertises', publishedAdvertisesList))
        dispatch(setAdvertiser('publishedAdvertisesGeoJson', geoJsonObject))
      } else {
        dispatch(
          setAdvertiser('publishedAdvertises', {
            ...getState().advertiser.publishedAdvertises,
            advertises: [
              ...getState().advertiser.publishedAdvertises.advertises,
              ...publishedAdvertisesList.advertises,
            ],
          })
        )
        dispatch(setAdvertiser('publishedAdvertisesGeoJson', geoJsonObject))
      }

      dispatch(setAdvertiser('advertisesLoader', false))
    } catch (error) {
      console.error(error)
    }
  }
}

export const updateAdvertiser = (advertiserCommunication) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlMutate({
        mutation: UPDATE_ADVERTISER as any,
        variables: advertiserCommunication,
      })

      const { updateAdvertiser } = data
      dispatch(setAdvertiser('advertiser', updateAdvertiser.advertiser))
    } catch (error) {
      console.error(error)
    }
  }
}

export const updateAdvertise = (advertiseData) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const { data } = await graphQlMutate({
        mutation: UPDATE_ADVERTISE as any,
        variables: advertiseData,
      })

      const { updateAdvertise } = data
      toast.success('Your advertise status is deactivated')
    } catch (error) {
      console.error(error)
    }
  }
}

export const deactivateAd = (id) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    const deactivateData = { advertiseId: Number(id), status: 'DEACTIVATED' }
    const advertiseData = { advertiseData: deactivateData }
    try {
      await graphQlMutate({
        mutation: UPDATE_ADVERTISE as any,
        variables: advertiseData,
      })

      location.reload()
    } catch (error) {
      console.error(error)
    }
  }
}

export const validateAdvertiseLicense = (
  adNumber,
  setFormData,
  setVerifyLicenseFeedback,
  setAdId
) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(setAdvertiser('loader', true))
    try {
      const { data } = await graphQlMutate({
        mutation: Validate_Advertise as any,
        variables: { adNumber },
      })

      if (data.validateAdvertise?.validationResult?.isValid) {
        try {
          const { data: detailedAdvertise } = await graphQlQuery({
            query: FETCH_DETAILED_ADVERTISE as any,
            variables: {
              advertiseId: data.validateAdvertise.validationResult.adId,
            },
          })

          setAdId(data.validateAdvertise.validationResult.adId)
          setFormData(detailedAdvertise.detailedAdvertise.data.data)
          dispatch(fetchSchemaAsync('advertise'))
        } catch (error) {
          console.error(error)
          dispatch(setAdvertiser('loader', false))
        }
      } else {
        setVerifyLicenseFeedback({
          text: data.validateAdvertise.validationResult.error?.replace(':', ''),
          response: 'error',
        })
        dispatch(setAdvertiser('loader', false))
      }
    } catch (error) {
      console.error(error)
      dispatch(setAdvertiser('loader', false))
    }
  }
}
