import React, { useState, useEffect } from 'react'
import { TextWidgetProps } from '@flint/rjsf'
import { TextField } from '@material-ui/core'
import Menu from '@mui/material/Menu'
import clsx from 'clsx'
import { utils } from '@rjsf/core'

import { CodeButton } from './Components/CodeButton'
import { getCountriersDetails, getCountryDetails } from './helpers/helpers'
import { useStyles } from './PhoneInput.Styled'

export const PhoneInput = ({
  onChange,
  value,
  ...props
}: TextWidgetProps): React.ReactElement => {
  const [selectedCountry, setSelectedCountry] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentValue, setCurrentValue] = useState('')
  const { getDisplayLabel } = utils
  const { schema, uiSchema, label } = props
  const displayLabel = getDisplayLabel(schema, uiSchema)
  const classes = useStyles()

  const defaultCountry: string =
    (uiSchema['ui:defaultCountry'] as string) || 'SA'

  const countryDetails = getCountryDetails({
    key: 'code',
    value: defaultCountry,
  })

  useEffect(
    () => setSelectedCountry(countryDetails.dial_code),
    [countryDetails]
  )

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCurrentValue(e.target.value)
    onChange(`${selectedCountry}${e.target.value}`)
  }

  const handleSelectCountryCode = (dial_code: string) => {
    setSelectedCountry(dial_code)
  }

  return (
    <div className={classes.phoneInputsWrapper}>
      <h4 className={clsx(classes.headerTitle)}>
        {displayLabel ? uiSchema['ui:label'] || label || schema.title : false}
      </h4>
      <TextField
        className={classes.textFieldRedius}
        variant="outlined"
        fullWidth
        value={currentValue}
        onChange={handleInputChange}
        type="tel"
        InputProps={{
          endAdornment: (
            <span>
              <CodeButton
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
                selectedCountry={selectedCountry}
                isMenuOpen={Boolean(anchorEl)}
              />
            </span>
          ),
        }}
        {...props}
      />
      <Menu
        className={classes.menuContainer}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {getCountriersDetails({
          handleSelectCountryCode: (dial_code: string) => {
            handleSelectCountryCode(dial_code)
            handleCloseMenu()
          },
        })}
      </Menu>
    </div>
  )
}
