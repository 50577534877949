import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  containerStyles: {
    padding: 0,
    minHeight: 'calc(100vh - 80px - 45px)',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBlock: '5rem',
    color: theme.palette.common.black,
    fontSize: '2.4rem',
  },
  joinAsAdvertiser: {
    minHeight: '80vh',
    display: 'grid',
    alignItems: 'center',
  },
}))
