import { FC } from 'react'
import { DaalAdverDetailsBasicInfo } from './DaalAdverDetailsBasicInfo'
import { AddPhotoToGallery } from './AddPhotoToGallery'
import { RegaRequirementField } from './RegaRequirementField'
import { ContactInfoField } from './ContactInfoField'
import { GrayBackgroundField } from './GrayBackgroundField'

export * from './DaalAdverDetailsBasicInfo'
export * from './AddPhotoToGallery'
export * from './RegaRequirementField'
export * from './ContactInfoField'
export * from './GrayBackgroundField'

export const fields: Record<string, FC> = {
  DaalAdverDetailsBasicInfo,
  AddPhotoToGallery,
  RegaRequirementField,
  ContactInfoField,
  GrayBackgroundField,
}
