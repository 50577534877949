import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  selectContainer: {
    backgroundColor: '#ffffff',
    height: 50,
    width: '100%',

    borderRadius: '10px',
    '& > *': {
      height: '100%',
      border: 'none',
    },
  },

  selectOption: {
    fontFamily: 'Almarai',
    fontWeight: 700,
    fontSize: 16,
    color: '#000000',
    paddingBlock: '10px',
    border: '1px solid #DFDFDF',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  selectIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginInlineEnd: theme.spacing(1),
  },
  eyeIcon: {
    position: 'absolute',
    zIndex: 6,
    right: '11%',
    bottom: '77%',
    width: '34px',
    height: '32px',
    backgroundColor: '#FFEBEB',
  },
  inspectionStatus: { top: '71%', height: '35px' },
  warningIconColor: { color: 'rgba(255, 184, 0, 1)' },
  expiredStatus: {
    top: '71%',
    height: '35px',
    backgroundColor: 'rgba(210, 3, 3, 0.55)',
  },
  expiredIconColor: { color: 'rgba(255, 255, 255, 1)' },
  rejectedStatus: {
    top: '71%',
    height: '35px',
    backgroundColor: 'rgba(210, 3, 3, 0.55)',
  },
  rejectedIconColor: { color: 'rgba(255, 255, 255, 1)' },
  deactivatedStatus: { top: '71%', height: '35px', backgroundColor: 'red' },
  deactivatedIconColor: { color: 'rgba(255, 255, 255, 0.54)' },
  publishedStatus: {
    top: '71%',
    height: '35px',
    backgroundColor: 'rgba(38, 137, 48, 0.54)',
  },
  publishedIconColor: { color: 'rgba(67, 228, 83, 1)' },
  draftIconColor: { color: 'rgba(255, 255, 255, 0.54)' },
}))
